import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocationOverviewComponent} from './location-overview.component';
import {RouterModule} from '@angular/router';
import {UILibModule} from '../uilib/uilib.module';
import { LocationSummaryComponent } from './location-summary/location-summary.component';

const routes = [
  { path: '', component: LocationOverviewComponent },
  { path: ':name/:locationType', component: LocationOverviewComponent }
];


@NgModule({
  declarations: [
    LocationOverviewComponent, 
    LocationSummaryComponent,
  ],
  imports: [
    CommonModule,
    UILibModule,
    RouterModule.forChild(routes),
  ]
})
export class LocationOverviewModule { }
