import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.css']
})
export class ToolbarButtonComponent {
  @Input() buttonTitle: string;
  @Input() CSSSelected: string;
  @Input() CSSDeselected: string;
  @Input() disabled: boolean = false; // this button will not fire onClick event if it is disabled
  @Input() selected: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  public Toggle() {
    this.selected = !this.selected;
  }
  
  onButtonClick() {
    !this.disabled && this.onClick.emit();
  }
}