import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { BreadcrumbConfig } from "../app-breadcrumb/breadcrumb.config";
import { BreadcrumbService } from "../app-breadcrumb/breadcrumb.service";
import { AppConfig } from "../app.config";
import { ContentService } from "../content.service";
import { RelatedResource } from "../shared/Models/related-resource.model";
import { TopicType } from "../shared/topic-type";
import { Util } from "../shared/util";
import { StatsService } from "../stats.service";
import { Accordion } from "../uilib/accordion/accordion.model";
import { ContinuumGroup } from "../uilib/continuum-accordion/continuum.model";
import { HorizontalCard } from "../uilib/horizontal-card/horizontal-card.model";
import { MetadataService } from "../metadata.service";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "topic-overview",
  templateUrl: "./topic-overview.component.html",
  styleUrls: ["./topic-overview.component.css"],
})
export class TopicOverviewComponent implements OnInit {
  topicId: string;
  locationName: string;
  locationLabel: string;
  locationType: string;
  topic: any;
  continuumAccordions$: Observable<ContinuumGroup[]>;
  continuumAccordions: ContinuumGroup[];
  firstLettersArray: string[] = [];
  labels: string[];
  featuredStats$: Observable<HorizontalCard[]>;
  accordions$: Observable<Accordion[]>;
  relatedResources$: Observable<RelatedResource[]>;

  showFeaturedStats: boolean;
  showContinuum: boolean;
  showAtoZ: boolean;
  showAccordions: boolean;
  showRelatedResources: boolean;
  isBrowser: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private statsService: StatsService,
    private contentService: ContentService,
    private breadcrumbService: BreadcrumbService,
    private metadataService: MetadataService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = isPlatformBrowser(this.platformId);
    }
   }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.topicId = params["topicId"];
      this.locationLabel = params["label"] || 'NSW';
      this.locationName = params["name"] || null;
      this.locationType = params["locationType"] || null;

      this.statsService
        .loadTopic(this.topicId)
        .subscribe((topic) => {
          this.topic = topic;
          if (!!this.topic && !!this.topic.title) {
            this.statsService
            .loadTopicTerms(this.topicId)
            .subscribe((data: any) => {
              if (this.metadataService) {
                this.metadataService.updateMetadata({
                  title: data.topic,
                  description: data.topic,
                  keywords: [data.keys]
                });
              }
            });
          }
          let breadcrumbs = [];
          if (this.locationName && this.locationType) {
            breadcrumbs.push(BreadcrumbConfig.LOCATIONS);
            breadcrumbs.push({ name: this.locationLabel, route: `/location-overview/${this.locationName}/${this.locationType}` });
          }
          else {
            breadcrumbs.push(BreadcrumbConfig.TOPICS);
          }

          breadcrumbs.push({ name: this.topic.label });
          this.breadcrumbService.updateBreadCrumbs(breadcrumbs);
          this.configureComponents(this.topic);
        });
    });
  }

  private configureComponents(topic) {
    this.showFeaturedStats = false;
    this.showAccordions = false;
    this.showContinuum = false;
    this.showAtoZ = false;
    this.showRelatedResources = false;



    if (topic.type === TopicType.AutomatedStandard) {
      this.loadContinuum();
    } else if (topic.type === TopicType.AutomatedAToZ) {
      this.showAtoZ = true;
      this.loadTopics();

    } else if (topic.type === TopicType.CuratedStandard) {
      this.showFeaturedStats = true;
      this.showAccordions = true;
      this.showRelatedResources = true;
      this.relatedResources$ = this.statsService.loadRelatedResources(
        this.topicId
      );

      // TODO: continuum not implemented yet
      this.loadContinuum();

      this.accordions$ = this.contentService.getTopicAccordions(this.topicId);
      this.featuredStats$ = this.statsService.loadFeaturedStats(this.topicId);
    } else if (topic.type === TopicType.CuratedAToZ) {
      this.showFeaturedStats = true;
      this.showAtoZ = true;
      this.showAccordions = true;
      this.showRelatedResources = true;

      this.loadTopics();
      this.relatedResources$ = this.statsService.loadRelatedResources(
        this.topicId
      );
      this.accordions$ = this.contentService.getTopicAccordions(this.topicId);
      this.featuredStats$ = this.statsService.loadFeaturedStats(this.topicId);
    } else {
      console.warn("No configuration for topic type: " + topic.type);
    }
  }

  private loadContinuum() {
    const enableContinuum = AppConfig.settings.featureSettings.enableContinuum;
    if (enableContinuum === true) {
      this.showContinuum = true;
      this.continuumAccordions$ = this.statsService.getContinuumAccordions(
        this.topicId
      );

      this.continuumAccordions$.subscribe((continuumGroups: ContinuumGroup[]) => {
        var deathGroup = continuumGroups.filter(_ => _.label === "Death")[0];
        var deathIndicators = deathGroup.subGroups.map(_ => _.indicators);
        var flattenedDeathIndicators = [].concat(...deathIndicators);
        var subGroups = continuumGroups.map(cg => cg.subGroups);
        var flattenedSubgroups = [].concat(...subGroups);
        var nonOtherindicators = flattenedSubgroups.filter(fsg => fsg.label !== "Other").map(fsg => fsg.indicators);
        var nonOtherFlattenedIndicators = [].concat(...nonOtherindicators);

        continuumGroups.forEach((continuumGroup: ContinuumGroup) => {
          continuumGroup.subGroups.forEach((subGroup: ContinuumGroup) => {
            if (continuumGroup.label !== "Death") {
              subGroup.indicators = subGroup.indicators.filter(i => !flattenedDeathIndicators.map(di => di.name).includes(i.name));
            }

            if (subGroup.label === "Other") {
              subGroup.indicators = subGroup.indicators.filter(i => !nonOtherFlattenedIndicators.map(noi => noi.name).includes(i.name));
            }
           
          });

          continuumGroup.subGroups = continuumGroup.subGroups.filter(i => i.indicators.length > 0);
        });

        this.continuumAccordions = continuumGroups;
      });
    }
  }

  private loadTopics() {
    this.statsService.loadTopicsAtoz(this.topicId, this.locationLabel, this.locationType).subscribe((data) => {
      this.topic.items = data[0].indicators;
      this.configureAtoZ(data);
    });
  }

  private configureAtoZ(data) {
    if (this.locationType && this.locationLabel) {
      data[0].indicators.forEach(item => {
        item.useUrlDirectly = true;
        item.url = Util.createLocationBasedChartUrl(item.url, this.locationType, this.locationLabel);;
      });
    }
    this.labels = data[0].indicators.map((item) => item.label);
    this.firstLettersArray = [];
    data[0].indicators.forEach((listItem) => {
      this.firstLettersArray.push(listItem.label[0].toUpperCase());
    });
    this.firstLettersArray = Util.deduplicate(this.firstLettersArray);
    this.firstLettersArray.sort();
  }

  getItemsForLetter(letter: string) {
    let items: any[] = [];
    this.topic.items.forEach((element) => {
      if (element.label[0].toLowerCase() === letter.toLowerCase()) {
        items.push(element);
      }
    });
    return items;
  }
}
