import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { AppInsightsService } from "./services/appinsights.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "HealthStatsWeb";

  constructor(router: Router, private googleAnalyticsService: GoogleAnalyticsService, private appInsightsService: AppInsightsService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.pageEmitter(event.urlAfterRedirects);
      }
    });
  }
  ngOnInit() {}
  ngOnDestroy() {}
}
