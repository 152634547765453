export class LocationSummary {
  name: string;
  label: string;
  shortLabel: string;
  imageUrl: string;
  overview: string;

  //LGA fields
  councilUrl?: string;
  communityProfileUrl?: string

  //LHD fields
  lga?: string;

  //LHD and PHN fields
  url?: string;
}
