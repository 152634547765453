import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app-breadcrumb/breadcrumb.service';
import { AppConfig } from '../app.config';
import { SearchApiService } from '../searchapi.service';
import { StatsService } from '../stats.service';
import { LocationTopicCardResponse, PageType, ResultItem, SearchResult } from '../uilib/search-result-page/searchresultpage.model';
import { FeaturedCards } from '../uilib/featured-cards/featuredcards.model';
import { PaginatorComponent } from '../uilib/paginator/paginator.component';
import { SearchCriteria, SearchResultItem, SearchStatus } from './search.model';
import { lastValueFrom } from 'rxjs';
import { MetadataService } from '../metadata.service';


@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {
  public searchStatus = SearchStatus;
  public status: SearchStatus = SearchStatus.Idle;
  private AutomatedAToZ = 'Automated - A to Z';
  private CuratedAtoZ = 'Curated - A to Z';

  public searchCriteria: SearchCriteria = new SearchCriteria();
  public cardSearchCriteria: SearchCriteria = new SearchCriteria();
  public searchResults: SearchResult = new SearchResult();
  public featuredCards: FeaturedCards[] = [];
  public searchResultItemsMissingSubTitle: ResultItem[] = [];
  pageType = PageType;

  @ViewChild('paginator', {static: true}) paginator: PaginatorComponent;

  public constructor(
    private _breadcrumbService: BreadcrumbService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _statsService: StatsService,
    private _searchApiService: SearchApiService,
    private metadataService: MetadataService) {

    this._breadcrumbService.updateBreadCrumbs([]);

    this.searchCriteria.pageOffset = 0;
    this.searchCriteria.pageSize = AppConfig.settings.searchSettings.pageSize;

    this.cardSearchCriteria.pageOffset = 0;
    this.cardSearchCriteria.pageSize = AppConfig.settings.searchSettings.cardSize;
  }

  public async ngOnInit() {
    this
      ._activatedRoute
      .queryParams
      .subscribe(async (params) => {
        this.searchCriteria.searchTerm = params["searchTerm"] || '';
        if (this.metadataService) {
          this.metadataService.updateMetadata({
            title: `Search - ${this.searchCriteria.searchTerm}`
          });
        }
        this.reset();
        if (this.searchCriteria.searchTerm) {
          this.cardSearchCriteria.searchTerm = this.searchCriteria.searchTerm;
          this.load();
        }
      });
  }

  public async search() {
    this._router.navigate(['/search'], { queryParams: { searchTerm: this.searchCriteria.searchTerm } });
  }

  public async paginate(pageOffset: number) {
    this.searchCriteria.pageOffset = pageOffset;
    this.load();
  }

  private async load() {

    this.featuredCards = [];
    this.status = SearchStatus.Searching;
    this.searchResults = await this._searchApiService.search(this.searchCriteria);
    this.searchResults.items = this.searchResults.items.slice(this.searchCriteria.pageOffset,this.searchCriteria.pageOffset + this.searchCriteria.pageSize);

    this.searchResultItemsMissingSubTitle = this.searchResults.items
    .filter(i => i.type === this.pageType.Indicator && (!i.subTitle || i.subTitle.length === 0));

    if (this.searchResultItemsMissingSubTitle) {
      await this.searchResultItemsMissingSubTitle.forEach(async ms => {
        await this._statsService.getIndicatorMetaGroupsByName(ms.name).subscribe(async (result) => {
          if (result && result.length > 0) {
            var groupLabels = result.filter(img => img.label && img.label.length > 0).map(img => img.label);
            if (groupLabels && groupLabels.length > 0) {
              var subTitles = groupLabels.join(", ");
              this.searchResults.items.find(i => i.name === ms.name).subTitle = `By ${subTitles}`;
            }
          }
        });
      });
    }

    const cardSize = AppConfig.settings.searchSettings.cardSize;
    let cardRequest = this.searchResults.items.filter(
      (item: SearchResultItem) => {
        return (item.type === PageType.Topic &&
          (item.subType === this.AutomatedAToZ || item.subType === this.CuratedAtoZ))
          || item.type === PageType.Location;
      }).slice(0, cardSize);

    if (cardRequest.length > 0) {
      cardRequest.forEach((element, index) => {
        element.index = index;
      });

      await cardRequest.forEach(async element => {
        if (this.featuredCards.length < cardSize) {
          if (element.type == PageType.Topic) {
            await this.getTopicCard(element.name, element.text, element.index);
          }
          else if (element.type == PageType.Location) {
            const response = await lastValueFrom(this._statsService.loadLocationSummary(element.locationType, element.name));
            const locationCard = this.locationFeatureCard(response, element.locationType, element.index);
            this.featuredCards.push(locationCard);
            this.featuredCards.sort((a, b) => a.index < b.index ? -1 : (a.index > b.index ? 1 : 0));
          }
        }
      });

      // TODO Search Result show Location Topic Result set
      // const hasTopicCard = cardRequest.filter(
      //   (item: SearchResultItem) => item.type === PageType.Topic);

      // const hasLocationCard = cardRequest.filter(
      //   (item: SearchResultItem) => item.type === PageType.Location);

      // if (hasTopicCard.length > 0 && hasLocationCard.length > 0) {
      //   await this.getLocationTopicCard(cardRequest);
      // }
    }
    this.paginator.Refresh(this.searchResults.totalRecords);
    this.status = SearchStatus.SearchComplete;
  }

  private async getLocationTopicCard(cardRequest: ResultItem[]) {
    const locationTopicCardResponses = await this._statsService.getLocationTopicCard(cardRequest);
    locationTopicCardResponses.forEach(response => {
      const locationTopicCard = this.locationTopicFeatureCard(response);
      this.featuredCards.push(locationTopicCard);

    });
  }

  private locationTopicFeatureCard(locationTopicCardResponse: LocationTopicCardResponse): FeaturedCards {
    const featureCard: FeaturedCards = {
      title: 'Topic overview A - Z',
      link: '/topics',
      text: '',
      items: [{
        link: locationTopicCardResponse.link,
        text: locationTopicCardResponse.textPreview,
        title: locationTopicCardResponse.title,
        index: 0
      }],
      index: 0
    }
    return featureCard;
  }

  private async getTopicCard(name: string, text: string, index: number) {
    const response = await lastValueFrom(this._statsService.loadTopic(name));
    const topicCard = this.topicFeatureCard(response, text, index);
    this.featuredCards.push(topicCard);
    this.featuredCards.sort((a, b) => a.index < b.index ? -1 : (a.index > b.index ? 1 : 0));
  }

  private locationFeatureCard(location: any, locationType: string, index: number): FeaturedCards {
    const featureCard: FeaturedCards = {
      title: 'Location Overview A-Z',
      link: '/locations',
      text: '',
      items: [{
        link: `/location-overview/${location.name}/${locationType}`,
        text: 'Explore population health data and information',
        title: location.label,
        index: index
      }],
      index: index
    }
    return featureCard;
  }

  private topicFeatureCard(topic: any, text: string, index: number): FeaturedCards {
    const featureCard: FeaturedCards = {
      title: 'Topic Overview A-Z',
      link: '/topics',
      text: '',
      items: [{
        link: topic.overview.url,
        text: text,
        title: topic.title,
        index: index
      }],
      index: index
    }
    return featureCard;
  }

  public reset() {
    this.status = SearchStatus.Idle;
    this.searchResults = new SearchResult();
    this.featuredCards = [];
    this.searchCriteria.pageOffset = 0;
    if (this.paginator) {
      this.paginator.reset();
    }
  }
}
