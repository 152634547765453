import { VisualisationDownloadFormat, Locations } from "../../../../app/indicator/constraints";
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Util } from "src/app/shared/util";
import { IndicatorState } from "../../indicator.state";
import { TransformService } from "../transform.service";
import * as Leaflet from "leaflet";
import * as _ from "underscore";
import Mustache from 'mustache';
import ResizeObserver from "resize-observer-polyfill";
import { ColourPalette, hsl } from "./colour-palette";
import { FormatService } from "../../../../app/shared/format.service";
import { DocumentExportService } from "../../../services/documentexport.service";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-map-view",
  templateUrl: "./map-view.component.html",
  styleUrls: ["./map-view.component.css"],
})
export class MapViewComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild("canvas", {static: true}) canvas: ElementRef;
  transformService: TransformService;
  canvasWidth: number;
  canvasHeight: number;
  observer: ResizeObserver;

  @Input()
  state: IndicatorState;

  facetedPlotData: any;
  nsw: any = null;
  lhd: any = null;
  phn: any = null;
  lga: any = null;

  CLASS = "facet-layout";
  BREAKPOINT_SM = 600; // px
  BREAKPOINT_MD = 960; // px
  FACET_GAP = 24; // px
  last_width: any;
  last_height: any;
  options: any;
  element: any;

  NSW_BLUE = "#153b83"; // Light hue e9effb

  NSW_BOUNDS: [number, number][] = [
    [-28.15, 141],
    [-37.51, 153.64],
  ];

  SYDNEY_BOUNDS: [number, number][] = [
    [-33.4207, 150.489109],
    [-34.323052, 151.336299],
  ];

  MAX_BOUNDS: [number, number][] = [
    [-24.567728, 136.72723],
    [-44.602898, 162.531114],
  ];

  filteredData;
  map;
  selectedBounds = [];
  boundaryLayer;
  legend;
  boundry_controls;

  TOKEN = `pk.eyJ1IjoiamFtaWxsYXIiLCJhIjoiY2tjeTNtZzFmMDFkaTJ1bXBlanZlZTVmZyJ9.YCLQuIUK91ATBJGyObK3NA`;
  MAP_STYLE = `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${this.TOKEN}`;

  constructor(private http: HttpClient, private formatService: FormatService, private _documentExportService: DocumentExportService) {
    this.transformService = new TransformService();
    this.transformService.onStateChange(this.state);

    this.zoom_click_handler = this.zoom_click_handler.bind(this);
    this.add_zoom_controls = this.add_zoom_controls.bind(this);
    this.add_legend = this.add_legend.bind(this);
    this.add_boundry_controls = this.add_boundry_controls.bind(this);
  }

  elementObserver() {
    var ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.memoriseCanvasSize(
          this.canvas.nativeElement.clientWidth,
          this.canvas.nativeElement.clientHeight
        );
      }
    });
    ro.observe(this.canvas.nativeElement);
  }

  ngAfterViewChecked() {
    this.memoriseCanvasSize(
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight
    );
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.canvas.nativeElement);
    }
  }

  memoriseCanvasSize(width: number, height: number) {
    let canvasSizeChanged = false;
    if (width !== undefined) {
      width = Math.floor(width);
      if (width !== 0 && width !== this.canvasWidth) {
        this.canvasWidth = width;
        canvasSizeChanged = true;
      }
    }
    if (height !== undefined) {
      height = Math.floor(height);
      if (height != 0 && height !== this.canvasHeight) {
        this.canvasHeight = height;
        canvasSizeChanged = true;
      }
    }

    if (canvasSizeChanged) {
      this.onCanvasSizeChange();
    }
  }

  onCanvasSizeChange() {
    if (this.state) {
      this.render();
    }
  }

  ngOnInit() {
    this.memoriseCanvasSize(
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight
    );
    this.elementObserver();
  }

  async onStateUpdated(newState: IndicatorState) {
    this.state = newState;
    this.transformService.onStateChange(this.state); // just assign state
    this.render();
  }

  async loadGeoJson() {
    if (
      this.nsw == null ||
      this.lhd == null ||
      this.lga == null ||
      this.phn == null
    ) {
      this.nsw = await lastValueFrom(this.http.get("assets/geojson/NSW16.json"));
      this.lhd = await lastValueFrom(this.http.get("assets/geojson/lhd.json"));
      this.lga = await lastValueFrom(this.http.get("assets/geojson/lga.json"));
      this.phn = await lastValueFrom(this.http.get("assets/geojson/phn.json"));
    }
  }

  async render() {
    await this.loadGeoJson();
    //debugger
    this.build_traces();
    const faceted_data = this.state.FacetedPlotData;
    const facet = faceted_data[0];
    let max = facet.data.reduce((result, current) => {
      return Math.max(result, current[this.state.Measure[0]]);
    }, 0);
    let min = facet.data.reduce((result, current) => {
      return Math.min(result, current[this.state.Measure[0]]);
    }, Infinity);

    // TODO set height of map div????
    if (this.map == null) {
      this.map = Leaflet.map(`map`, { zoomControl: false })
        .fitBounds(this.NSW_BOUNDS)
        .setMaxBounds(this.MAX_BOUNDS);

      Leaflet.tileLayer(this.MAP_STYLE, {
        id: "mapbox/light-v9",
        zoom: 19,
        tileSize: 512,
        zoomOffset: -1,
        accessToken: this.TOKEN,
        attribution:
          "© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> <strong><a href='https://www.mapbox.com/map-feedback/' target='_blank'>Improve this map</a></strong>",
      }).addTo(this.map);

      this.add_zoom_controls(this.map);
    }

    this.map.closePopup();
    if (this.legend) {
      this.map.removeControl(this.legend);
    }
    if (max !== min) {
      this.add_legend(max, min);
    }
    this.add_boundry_controls();

    if (
      (this.state.Location !== Locations.NSW &&
        this.state.Filter[this.state.Location] !== undefined) ||
      this.state.Location === Locations.NSW
    ) {
      if (this.boundaryLayer !== undefined) {
        this.map.removeLayer(this.boundaryLayer);
        this.boundaryLayer = undefined;
      }

      this.selectedBounds = [];

      const geojson = this.getGeoJson(this.state.Location);
      this.boundaryLayer = Leaflet.geoJSON(geojson, {
        style: (feature) => {
          return this.style(feature, facet, max, min);
        },
        onEachFeature: ((feature, layer) => {
          const selected =
            this.state.Location === Locations.NSW
              ? true
              : this.is_feature_selected(feature);

          if (selected) {
            layer.on({
              click: (event) => {
                let name = event.target.feature.properties.NAME;

                if (this.state.Location !== Locations.NSW) {
                  name = `${name} ${this.state.Location.toUpperCase()}`;
                }

                const data = facet.data.find(
                  (element) => element[this.state.Location] === name
                );
                this.highlight(event, data);
              },
              mouseout: this.reset_highlight,
            });
          }
        }).bind(this),
      }).addTo(this.map);
    }
    window.dispatchEvent(new Event("resize"));
    return this;
  }

  style(feature, facet, max, min) {
    const state = this.state;
    const location = state.Location;

    if (state.Location !== Locations.NSW) {
      let name = feature.properties.NAME;
      let group_options = state.GroupOptions[location];
      name = `${name} ${state.Location.toUpperCase()}`;

      // If there is no data for this boundary
      if (group_options.indexOf(name) === -1) {
        return {
          fillColor: "none",
          color: "red",
          weight: 1,
          opacity: 0.5,
          dashArray: "7",
        };
      }
    }

    const selected =
      state.Location === Locations.NSW
        ? true
        : this.is_feature_selected(feature);

    if (selected) {
      this.update_selected_bounds(feature);
    }

    const val =
      state.Location === Locations.NSW
        ? facet.data[0][state.Measure[0]]
        : facet.data.reduce((accumulator, current) => {
            let data_name =
              current[location] === undefined
                ? Locations.NSW
                : current[location].replace(` ${location.toUpperCase()}`, "");

            let geo_name = feature.properties.NAME;

            if (data_name === geo_name) {
              accumulator = current[state.Measure[0]];
            }

            return accumulator;
          }, 0);

    let koeff = 1; // TODO can be 1 or 0 as well. What is corrent?
    let colour = "white";

    if (val != 0) {
      let valDiff = val - min;
      let maxMinDiff = max - min;
      if (maxMinDiff != 0) {
        koeff = valDiff / maxMinDiff;
      }

      const colorIndex = Math.floor(koeff * (ColourPalette.length - 1));
      const colorFromPallet = ColourPalette[colorIndex];
      colour = selected
        ? `hsl(${colorFromPallet[0]}, ${colorFromPallet[1]}%, ${colorFromPallet[2]}%`
        : "white";
    }
    return {
      fillColor: colour,
      fillOpacity: 0.4,
      weight: selected ? 1 : 0,
      opacity: selected ? 1 : 0,
    };
  }

  getGeoJson(location: string) {
    return location === Locations.NSW
      ? this.nsw
      : location === Locations.LHD
      ? this.lhd
      : location === Locations.LGA
      ? this.lga
      : this.phn;
  }

  add_zoom_controls(map) {
    let zoom_controls = Leaflet.control({ position: "topleft" });
    zoom_controls.onAdd = function (map) {
      let div = Leaflet.DomUtil.create("div", "zoom-control");
      div.innerHTML = `
            <button value="nsw">NSW</button>
            <button value="sydney">SYDNEY</button>
            <button value="selected">FIT</button>
        `.trim();

      Leaflet.DomEvent.on(
        div,
        "click",
        function (event) {
          this.zoom_click_handler.bind(this)(event, map);
        }.bind(this)
      );

      Leaflet.DomEvent.on(div, "dblclick", (event) => {
        event.stopPropagation();
      });

      return div;
    }.bind(this);
    zoom_controls.addTo(map);
  }

  add_legend(max, min) {
    const map = this.map;
    if (this.legend !== undefined) {
        map.removeControl(this.legend);
    }

    const hslString = this.getHSLColoursFromPalette(5);
    const legend = Leaflet.control({ position: "bottomright" });
    legend.onAdd = function (map) {
      const legendHtml = this.pretty(min, max, 5)
        .map((l) => `<div>${l}</div>`)
        .reverse()
        .join("");

      const div = Leaflet.DomUtil.create("div", "map-legend");
      div.innerHTML = `
            <div class="map-legend--gradient" style="background: linear-gradient(180deg, ${hslString});"></div>
            <div class="map-legend--ticks">
            ${legendHtml}
            </div>
        `.trim();

      return div;
    }.bind(this);
    legend.addTo(map);

    this.legend = legend;
  }

  /**
   * Returns a string of evenly spread hsl colours from the pallet in the format of "hsl(a1, a2, a3), hsl(b1, b2, b3)..."
   * @param numOfColours
   */
  getHSLColoursFromPalette(numOfColours: number): string {
    const partitionSize = Math.floor(ColourPalette.length / (numOfColours - 1));
    let hslList: hsl[] = [];
    for (let i = 0; i < numOfColours; i++) {
      let index = i * partitionSize;
      if (index >= ColourPalette.length) {
        index = ColourPalette.length - 1;
      }
      hslList.push(ColourPalette[index]);
    }
    return hslList
      .map((hsl) => `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`)
      .reverse()
      .join(",");
  }

  add_boundry_controls() {
    const map = this.map;
    const state = this.state;

    if (this.boundry_controls !== undefined) {
      map.removeControl(this.boundry_controls);
    }

    this.boundry_controls = new Leaflet.Control({ position: "bottomleft" });
    this.boundry_controls.onAdd = function (map) {
      const div = Leaflet.DomUtil.create("div", "boundry-control");
      div.innerHTML = `
            ${
              state.Location !== Locations.LGA
                ? '<button class="map-boundary-button" value="' +
                  Locations.LGA +
                  '">LGA</button>'
                : ""
            }
            ${
              state.Location !== Locations.LHD
                ? '<button class="map-boundary-button" value="' +
                  Locations.LHD +
                  '">LHD</button>'
                : ""
            }
            ${
              state.Location !== Locations.PHN
                ? '<button class="map-boundary-button" value="' +
                  Locations.PHN +
                  '">PHN</button>'
                : ""
            }
        `.trim();

      Leaflet.DomEvent.on(
        div,
        "click",
        function (event) {
          this.boundry_click_handler.bind(this)(event, map);
        }.bind(this)
      );

      Leaflet.DomEvent.on(div, "dblclick", (event) => {
        event.stopPropagation();
      });

      return div;
    }.bind(this);
    this.boundry_controls.addTo(map);
  }

  addRemoveCssClass(element: Element, cssCLass: string, add: boolean) {
    if (add === true) {
      element.classList.add(cssCLass);
    } else {
      element.classList.remove(cssCLass);
    }
  }

  highlight(event, data) {
    const layer = event.target;
    var filteredMeasures = this.get_filtered_measures();

   const measuresData = filteredMeasures.filter(fm => fm.name === this.state.Measure[0]);
   const measures = measuresData.filter((thing, i, arr) => arr.findIndex(t => t.name === thing.name) === i);

    let name = event.target.feature.properties.NAME;
    if (this.state.Location !== Locations.NSW) {
      name = `${name} ${this.state.Location.toUpperCase()}`;
    }

    const template = `
    <b>{{name}}</b>
    <table>
        {{#measures}}
        <tr><th>{{label}}:</th><td>{{value}}</td></tr>
        {{/measures}}
    </table>
    `.trim();

    let template_data = measures.reduce(
      (accumulator, current) => {
        if (data[current.name] !== undefined) {
          accumulator.measures.push({
            label: current.label,
            value: this.formatService.decimalFormatting(data[current.name]),
          });
        }
        return accumulator;
      },
      {
        name: "",
        measures: [],
      }
    );

    template_data.name = name;

    Leaflet.popup({
      className: "map-highlight",
      closeButton: false,
    })
      .setLatLng(event.latlng)
      .setContent(Mustache.render(template, template_data))
      .openOn(layer._map);

    layer.previous_style = _.clone(layer.options);

    layer.setStyle({
      weight: 2,
    });

    if (
      !Leaflet.Browser.ie &&
      !Leaflet.Browser.opera &&
      !Leaflet.Browser.edge
    ) {
      layer.bringToFront();
    }
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  get_filtered_measures() {
    var measures = Util.deepCopy(this.state.Meta.measures);
    measures.forEach(m => {
      if (!!m.groups && m.groups.indexOf("Period") === -1) {
        m.groups = m.groups + ",Period";
      }
      else if (!m.groups) {
        m.groups = "Period";
      }
    });


    var groups = Util.deepCopy(this.state.Groups);
    if (!!this.state.Location && !!this.state.Location.length && this.state.Location !== "NSW" && !!groups && groups.indexOf(this.state.Location) === -1) {
      groups.push(this.state.Location);
    }

    var sortedGroupsAppliedOnTheUI = this.sort_groups_alphabetically(groups);
    var groupMeasures = measures.filter(m => !!groups && this.sort_groups_alphabetically(this.split_measure_groups_safely(m.groups)) === sortedGroupsAppliedOnTheUI);
    var filteredMeasures = !!groupMeasures && !!groupMeasures.length ? groupMeasures : measures;
    return filteredMeasures;
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  sort_groups_alphabetically(groups) {
    if (!!groups) {
      return groups.sort((a, b) => a.localeCompare(b)).join();
    }
    return "";
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  split_measure_groups_safely(measureGroups) {
    if (!!measureGroups) {
      if (measureGroups.indexOf(",") !== -1) {
        return measureGroups.split(",");
      }
      else {
        return [measureGroups];
      }
    }
    return [];
  }

  reset_highlight(event) {
    const layer = event.target;
    if (layer.previous_style !== undefined) {
      layer.setStyle(layer.previous_style);
    }
  }

  zoom_click_handler(event, map) {
    if (event.target.value !== undefined) {
      const bounds =
        event.target.value === Locations.NSW.toLowerCase()
          ? this.NSW_BOUNDS
          : event.target.value === "sydney"
          ? this.SYDNEY_BOUNDS
          : event.target.value === "selected"
          ? this.selectedBounds
          : undefined;

      if (bounds !== undefined && bounds.length > 0) {
        map.fitBounds(bounds);
      }
    }
  }

  boundry_click_handler(event, map) {
    if (map.boundry_overlay !== undefined) {
      map.removeLayer(map.boundry_overlay);
    }

    //$('.boundry-control button.selected').removeClass('selected');
    const elements = document.getElementsByClassName("map-boundary-button");
    for (var i = 0; i < elements.length; i++) {
      this.addRemoveCssClass(elements[i], "selected", false);
    }

    if (event.target.value !== map.selected_overlay) {
      event.target.classList.add("selected");
      map.selected_overlay = event.target.value;

      let geojson = this.getGeoJson(map.selected_overlay);

      map.boundry_overlay = Leaflet.geoJson(geojson, {
        style: {
          fillColor: "none",
          weight: 1,
          opacity: 1,
          color: "#4C4F55",
          dashArray: "4",
        },
      }).addTo(map);
    } else {
      map.selected_overlay = null;
    }
  }

  is_feature_selected(feature) {
    if (
      feature === undefined ||
      feature.properties === undefined ||
      feature.properties.NAME === undefined
    ) {
      return false;
    }

    const state = this.state;
    const location = state.Location;
    const filter = state.Filter[location];

    return filter.reduce((accumulator, current) => {
      return (accumulator =
        accumulator || current.indexOf(feature.properties.NAME) === 0);
    }, false);
  }

  // Update the the 'selected_bounds' property to include the extent of the feature geometry
  update_selected_bounds(feature) {
    const LAT_GEOJSON = 1;
    const LNG_GEOJSON = 0;

    const LAT_MAP = 0;
    const LNG_MAP = 1;

    const TOP_LEFT = 0;
    const BOTTOM_RIGHT = 1;

    // Reduce the multi-dimensional coordinates to a single coordiinate array
    const coordinates = [].concat(...feature.geometry.coordinates);

    // If the selected bound is empty
    if (this.selectedBounds === undefined || this.selectedBounds.length === 0) {
      // Get the first c orodinate value
      const first = coordinates[0];

      // Initialis the selected bounds to the first value
      this.selectedBounds = [
        [first[LAT_GEOJSON], first[LNG_GEOJSON]],
        [first[LAT_GEOJSON], first[LNG_GEOJSON]],
      ];
    }

    // For each coordinate
    coordinates.forEach((current) => {
      // If coordinate lat greater than the top left lat
      if (current[LAT_GEOJSON] > this.selectedBounds[TOP_LEFT][LAT_MAP]) {
        this.selectedBounds[TOP_LEFT][LAT_MAP] = current[LAT_GEOJSON];
      }

      // If coordinate lat less than the bottom right lat
      if (current[LAT_GEOJSON] < this.selectedBounds[BOTTOM_RIGHT][LAT_MAP]) {
        this.selectedBounds[BOTTOM_RIGHT][LAT_MAP] = current[LAT_GEOJSON];
      }

      // If coordinate lng less than the top left lng
      if (current[LNG_GEOJSON] < this.selectedBounds[TOP_LEFT][LNG_MAP]) {
        this.selectedBounds[TOP_LEFT][LNG_MAP] = current[LNG_GEOJSON];
      }

      // If coordinate lng greater than the bottom right lng
      if (current[LNG_GEOJSON] > this.selectedBounds[BOTTOM_RIGHT][LNG_MAP]) {
        this.selectedBounds[BOTTOM_RIGHT][LNG_MAP] = current[LNG_GEOJSON];
      }
    });
  }

  build_traces() {
    let facet_ids = this.transformService.getFacetIds();
    let faceted_data = [];

    let filtered_data = this.state.FilteredData;
    if (filtered_data === undefined) {
      return;
    }

    filtered_data.forEach((record) => {
      let facet_id = this.transformService.getFacetId(record);
      let facet_title = this.transformService.getFacetTitle(record);

      facet_id = facet_id === "" ? "-" : facet_id;

      let facet = faceted_data.find((element) => element.facet_id === facet_id);
      if (facet === undefined) {
        facet = {
          facet_id,
          facet_title,
          data: [],
        };
        faceted_data.push(facet);
      }

      facet.data.push(record);
    });

    this.state.FacetedPlotData = faceted_data;
  }

  set_grid_sizes(facet_count, calculate_column_widths) {
    let grid_template_columns = "100%";

    if (facet_count > 1) {
      const columns = this.get_columns(facet_count);

      if (columns > 1) {
        if (this.calculate_column_widths !== undefined) {
          let [
            primary_width,
            secondary_width,
          ] = this.calculate_column_widths.bind(this)(columns);
          grid_template_columns = `${primary_width}px repeat(${
            columns - 1
          }, ${secondary_width}px)`;
        } else {
          grid_template_columns = `repeat(${columns}, 1fr)`;
        }
      }
    }

    // Set the grid-template-columns value
    var el = document.getElementById(this.CLASS);
    if (el !== null) {
      el.classList.add("grid-template-columns");
      el.classList.add("grid_template_columns");
    }
  }

  get_columns(facet_count) {
    let grid_width = this.get_grid_width();

    return grid_width < this.BREAKPOINT_SM
      ? 1
      : grid_width < this.BREAKPOINT_MD
      ? Math.min(2, facet_count)
      : Math.min(3, facet_count);
  }

  get_grid_width() {
    // TODO: need to revisit
    return document.getElementsByClassName(this.CLASS)[0].clientWidth;
  }

  get_avaliable_width(columns) {
    // Calculate the total horizontal space required for column gaps
    let column_space = (columns - 1) * this.FACET_GAP;
    return this.get_grid_width() - column_space;
  }

  calculate_column_widths(columns) {
    let avaliable_space = this.get_avaliable_width(columns);
    return [avaliable_space / columns, avaliable_space / columns];
  }

  set_options(options) {
    this.options = JSON.parse(JSON.stringify(options));
  }

  /**
   * Modified from https://gist.github.com/Frencil/aab561687cdd2b0de04a
   *
   * Generate a "pretty" set of ticks (multiples of 1, 2, or 5 on the same order of magnitude for the range)
   * Based on R's "pretty" function:
   * https://github.com/wch/r-source/blob/b156e3a711967f58131e23c1b1dc1ea90e2f0c43/src/appl/pretty.c
   * Arguments:
   *  min (required) :: Minimum value in range for the ticks to cover
   *  max (required) :: Maximum value in range for the ticks to cover
   *  n     (optional) :: A "target" number of ticks; will not necessarily be the number of ticks you get (default: 5)
   *  internal_only (optional) :: Boolean for whether to only return ticks inside the provided range (default: false)
   */
  pretty(
    min: number,
    max: number,
    n: number,
    internal_only: boolean = false
  ): number[] {
    if (typeof n == "undefined" || isNaN(n)) {
      n = 5;
    }

    const min_n = n / 3;
    const shrink_sml = 0.75;
    const high_u_bias = 1.5;
    const u5_bias = 0.5 + 1.5 * high_u_bias;
    const d = Math.abs(min - max);
    let c = d / n;
    if (Math.log(d) / Math.LN10 < -2) {
      c = (Math.max(Math.abs(d)) * shrink_sml) / min_n;
    }

    const base = Math.pow(10, Math.floor(Math.log(c) / Math.LN10));
    let base_toFixed = 0;
    if (base < 1) {
      base_toFixed = Math.abs(Math.round(Math.log(base) / Math.LN10));
    }

    let unit = base;
    if (2 * base - c < high_u_bias * (c - unit)) {
      unit = 2 * base;
      if (5 * base - c < u5_bias * (c - unit)) {
        unit = 5 * base;
        if (10 * base - c < high_u_bias * (c - unit)) {
          unit = 10 * base;
        }
      }
    }

    let ticks: number[] = [];
    let i;
    if (min <= unit) {
      i = 0;
    } else {
      i = Math.floor(min / unit) * unit;
      i = parseFloat(i.toFixed(base_toFixed));
    }
    while (i < max) {
      ticks.push(i);
      i += unit;
      if (base_toFixed > 0) {
        i = parseFloat(i.toFixed(base_toFixed));
      }
    }
    ticks.push(i);

    if (internal_only) {
      if (ticks[0] < min) {
        ticks = ticks.slice(1);
      }
      if (ticks[ticks.length - 1] > max) {
        ticks.pop();
      }
    }

    return ticks;
  }

  public exportAs(visualisationDownloadFormat: VisualisationDownloadFormat, fileName: string): any {
    const elementSelectors = ['.indicator-chart--title', '.indicator-chart--subtitle', '#map', '.source-container'];
    if (visualisationDownloadFormat == VisualisationDownloadFormat.PNG) {
      this._documentExportService.ExportAsPng(elementSelectors, { FileName: fileName, OnDocumentCloned: this.AdjustMapDisortionInExport });
    }
  }

  private AdjustMapDisortionInExport(clonedDocument: Document): void {
    let svg = clonedDocument.querySelector<HTMLElement>('svg.leaflet-zoom-animated');
    const matrixValues = svg.style.transform.match(/matrix.*\((.+)\)/)[1].split(', ');
    svg.style.transform = `none`;
    svg.style.left = `${matrixValues[4]}px`;
    svg.style.top = `${matrixValues[5]}px`;
  }
}
