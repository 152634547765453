import { Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { BreadcrumbService } from "./breadcrumb.service";
import { Breadcrumb } from "./breadcrumb";
import { Subscription } from "rxjs";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./app-breadcrumb.component.html",
  styleUrls: [
    "./app-breadcrumb.component.css",
    "../app-header/app-header.component.css",
  ], //shares css with header
})
export class AppBreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbs: Breadcrumb[] = [];
  subscription: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription = this.breadcrumbService.breadcrumbs$.subscribe(
      (b) => (this.breadcrumbs = b)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
