import { Injectable } from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { Util } from '../shared/util';

@Injectable()
export class PlotlyServiceExt extends PlotlyService {
  private readonly _config: any;

  public constructor() {
    super();
    this._config = {
      characterWidth: 8,
      font: {
        family: "Montserrat, Arial, sans-serif",
        color: "black",
        graphSize: 24,
        sourceSize: 14
      },
      graph: {
        height: 1200,
        width: 2000
      },
      source: {
        heading: '<b>Source:</b>',
        lineBreak: '<br>',
        lineHeight: 40,
        horizontalPadding: 0,
        height: 800,
        position: {
          x: 0,
          y: -0.08
        },
        minBottomMargin: 150,
        maxBottomMargin: 500
      }
    };
  }

  public async export(graphDivId: string, title: string, legends: [], source: string, downloadOption: any) {

    const graph: any = this.getInstanceByDivId(graphDivId);
    downloadOption.width = this._config.graph.width;
    downloadOption.height = this._config.graph.height;

    if (legends && legends.length > 0) {
      for (let index in graph.data) {
        let legend = '';
        if (legends[index].legendgroup != '-') {
          legend = `${legends[index].text[0][0]} (${legends[index].legendgroup})`;
        }
        else {
          legend = `${legends[index].text[0][0]}`;
        }
        graph.data[index].name = legend;
        graph.data[index].showlegend = true;
      }
    }

    if (source) {
      source = this.prepareSource(source);
    }
    const oldBottomMargin = graph.layout.margin.b;

    graph.layout = this.prepareLayout(graph.layout, title, source, downloadOption);
    const plotly = await this.getPlotly();
    plotly.downloadImage(graph, downloadOption);

    graph.layout.margin.b = oldBottomMargin;
    if (legends && legends.length > 0) {
      for (let index in graph.data) {
        graph.data[index].showlegend = false;
      }
    }
  }

  private prepareSource(source: string): string {
    const maxCharacters = this._config.graph.width / this._config.characterWidth;
    return `${this._config.source.heading} ${this._config.source.lineBreak} ${Util.wrapContent(source.replace(/\s+/g, ' ').trim(), maxCharacters)}`;
  }

  private prepareLayout(layout: any, title: string, source: string, downloadOption: any): any {
    const totalWraps = Util.wordCount(source, this._config.source.lineBreak);
    let bottomMargin = (totalWraps + 1) * this._config.source.lineHeight;
    if (bottomMargin < this._config.source.minBottomMargin) {
      bottomMargin = this._config.source.minBottomMargin;
    }
    else if (bottomMargin > this._config.source.maxBottomMargin) {
      downloadOption.height = this._config.graph.height + bottomMargin;
    }



    const sourceContentWidth = downloadOption.Width - this._config.source.horizontalPadding;
    layout.title = {
      text: title,
      font: {
        family: this._config.font.family,
        color: this._config.font.color,
        size: this._config.font.graphSize
      }
    };
    layout.annotations = [];
    layout.margin.b = bottomMargin;
    if (source) {
      layout.annotations.push({
        x: this._config.source.position.x,
        y: this._config.source.position.y,
        xref: 'paper',
        yref: 'paper',
        xanchor: 'left',
        yanchor: 'top',
        align: 'left',
        valign: 'top',
        text: source,
        width: sourceContentWidth,
        showarrow: false,
        font: {
          family: this._config.font.family,
          color: this._config.font.color,
          size: this._config.font.sourceSize
        },
      });
    }
    return layout;
  }
}

