import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthstatsplusComponent } from './healthstatsplus.component';
import { RouterModule } from '@angular/router';
import {UILibModule} from '../uilib/uilib.module';
import {PublicationComponent} from '../publication/publication.component';


const routes = [
  { path: '', component: HealthstatsplusComponent },
  { path: ':resourceType', component: PublicationComponent }
];

@NgModule({
  declarations: [
    HealthstatsplusComponent,
    PublicationComponent,
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        UILibModule,
    ]
})
export class HealthstatsplusModule { }
