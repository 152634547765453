import { Component, Input, OnInit } from '@angular/core';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() title: string;
  @Input() tag: string;
  @Input() image: string;
  @Input() date: string;
  @Input() text: string;
  @Input() link: string;
  @Input() objectFit: string = 'contain';

  constructor(private googleAnalyticsService: GoogleAnalyticsService, private appInsightsService: AppInsightsService) { }

  ngOnInit() {
  }

  onAccessResource(): void{
    this.googleAnalyticsService.pageEmitter('Snapshot');
    this.googleAnalyticsService.eventEmitter('Click_' + this.link, 'Snapshot', 'Click', this.title);
    this.appInsightsService.logPageView(`Snapshot - ${this.title}`, this.link);
  }
}
