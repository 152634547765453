import { Injectable } from "@angular/core";
import { AppConfig } from "./app.config";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { WebPage } from "./webpage.model";

@Injectable({
  providedIn: "root",
})
export class AppService {
  apiContentURL: string = AppConfig.settings.server.apiContent;
  apiStatsURL: string = AppConfig.settings.server.apiStats;
  allowedActions: string[];

  constructor(public httpClient: HttpClient) {}

  // content related methods
  public get(id: number): Observable<WebPage> {
    return this.httpClient.get<WebPage>(`${this.apiContentURL}/webpage/${id}`);
  }

  public getByUri(uri: string): Observable<WebPage> {
    return this.httpClient.get<WebPage>(
      `${this.apiContentURL}/webpage/${uri}/uri`
    );
  }
}
