import { Component, Input } from '@angular/core';
import { SearchResult } from './searchresultpage.model';

@Component({
  selector: 'app-search-result-page',
  templateUrl: './searchresultpage.component.html',
  styleUrls: ['./searchresultpage.component.css']
})
export class SearchResultPageComponent {
  @Input() searchResults: SearchResult;

  constructor() { }
}
