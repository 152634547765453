import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { WebPage } from "./webpage.model";
import { AppConfig } from "./app.config";
import { Accordion } from "./uilib/accordion/accordion.model";
import { Glossary } from "./glossary/glossary.model";

@Injectable({
  providedIn: "root",
})
export class ContentService {
  apiContentURL: string = AppConfig.settings.server.apiContent;

  allowedActions: string[];

  constructor(public httpClient: HttpClient) {}

  public get(id: number): Observable<WebPage> {
    return this.httpClient.get<WebPage>(`${this.apiContentURL}/webpage/${id}`);
  }

  public getByName(name: string): Observable<WebPage> {
    return this.httpClient.get<WebPage>(
      `${this.apiContentURL}/webpage/${name}/content`
    );
  }

  getTopicAccordions(topic: string): Observable<Accordion[]> {
    return this.httpClient.get<Accordion[]>(
      `${this.apiContentURL}/content/statstopic/${topic}`
    );
  }

  getIndicatorText(indicator: string): Observable<Accordion[]> {
    return this.httpClient.get<Accordion[]>(
      `${this.apiContentURL}/content/indicator/${indicator}/text`
    );
  }

  getGlossary(
    searchTerm: string,
    exactMatch?: boolean
  ): Observable<Glossary[]> {
    let params: HttpParams = new HttpParams();
    params = params.set("search", searchTerm);
    if (exactMatch) {
      params = params.set("exactMatch", "true");
    }
    return this.httpClient.get<Glossary[]>(
      `${this.apiContentURL}/content/glossary`,
      { params: params }
    );
  }
}
