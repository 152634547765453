import { PageType } from "../uilib/search-result-page/searchresultpage.model";

export enum SearchStatus {
  Idle,
  Searching,
  SearchComplete
}

export class SearchCriteria {
  public pageSize: number;
  public pageOffset: number;
  public searchTerm: number;
}

export class SearchResultItem {
  public name: string;
  public title: string;
  public subTitle: string;
  public text: string;
  public link: string;
  public type: PageType;
  public locationType: string;
  public subType: string;
  public shortLabel: string;
  public label: string;
  public index: number;
}

export class PageSearchResult {
  public items: SearchResultItem[];
  totalRecords: number;
}
