import {Locations, LocationsArrayExceptNSW, ViewType} from 'src/app/indicator/constraints';
import {Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {IndicatorState} from 'src/app/indicator/indicator.state';
import {Util} from 'src/app/shared/util';
import {DialogComponent} from '../dialog/dialog.component';

@Component({
  selector: "app-sortable-checkbox-menu",
  templateUrl: "./sortable-checkbox-menu.component.html",
  styleUrls: ["./sortable-checkbox-menu.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SortableCheckboxMenuComponent implements OnInit {
  @ViewChild("onboardingDialog", {static: true}) onboardingDialog: DialogComponent;

  titleLabel = "Compare:";
  title: string;
  items: string[] = [];
  selectedItems: string[] = [];
  showTip: boolean = false;
  menuIsVisible: boolean = false;
  onboardTimer: any;

  @Output() itemsSelected: EventEmitter<string[]> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {}

  onStateUpdated(state: IndicatorState) {
    this.selectedItems = Util.copy(state.Compare);
    this.items = this.getCompareOptions(state);
    this.triggerOnboarding(state);
    this.updateTitle();
  }

  // Compare options will contain groups as well as Locations except for NSW
  getCompareOptions(state: IndicatorState): string[] {
    let validOptions: string[] = [];

    if (state.Location !== Locations.NSW) {
      validOptions.push(state.Location);
    }

    validOptions.push(...state.Groups);

    let sortedOptions: string[] = [];
    if (this.items.length > 0) {
      // keep existing order of items
      sortedOptions.push(...this.items);
    } else {
      // sort items according to order of selected items
      sortedOptions.push(...this.selectedItems);
    }

    // Filter out options that are no longer valid
    sortedOptions = sortedOptions.filter(item => validOptions.includes(item));

    // remaining items will be appended last
    validOptions.forEach(item => !sortedOptions.includes(item) && sortedOptions.push(item));

    // Remove locations if NSW
    if (state.Location === Locations.NSW) {
      sortedOptions = sortedOptions.filter(option => !LocationsArrayExceptNSW.includes(option));
    }

    return sortedOptions;
  }

  triggerOnboarding(state: IndicatorState) {
    const onboarded = localStorage.getItem("onboarding.compare");

    if (!onboarded && !this.showTip && Object.entries(state.Filter).length > 1) {
      for (let [key, filters] of Object.entries(state.Filter)) {
        if (filters && filters.length > 1) {
          this.onboardTimer = setTimeout(() => {
            this.onboardingDialog.open();
          }, 2000);
          return;
        }
      }
    }
  }

  deslugify(item: string) {
    return Util.deslugify(item);
  }

  updateTitle() {
    const sorted = this.items.filter((x) => this.selectedItems.indexOf(x) >= 0);
    const deslugified = sorted
      .reduce((result, current) => {
        result.push(Util.deslugify(current));
        return result;
      }, [])
      .join(", ");
    this.title = deslugified.trim();
    if (this.title == "") {
      this.title = "None selected";
    }
    return sorted;
  }

  ActionChanges() {
    // const sorted = this.items.filter((x) => this.selectedItems.indexOf(x) >= 0);
    // this.title = sorted.join(', ');
    const sorted = this.updateTitle();
    this.itemsSelected.emit(sorted);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.ActionChanges();
    this.menuIsVisible = this.showTip;
  }

  checkIfSelected(item: string) {
    return this.selectedItems.includes(item);
  }

  onToggleButtonClick(event: any) {
    if (this.onboardTimer) {
      clearTimeout(this.onboardTimer);
    }

    if (!this.showTip) {
      this.menuIsVisible = !this.menuIsVisible;
    }
  }

  onItemClicked(event: Event, id: any) {
    event.preventDefault();
    if (this.selectedItems.includes(id)) {
      this.selectedItems = Util.remove(this.selectedItems, id);
    } else {
      this.selectedItems.push(id);
    }
    // TODO notify parent
    this.menuIsVisible = this.showTip;
    this.ActionChanges();
  }

  tryItHandler() {
    this.onboardingDialog.close();
    this.menuIsVisible = true;
    this.showTip = true;
  }

  gotItHandler() {
    localStorage.setItem("onboarding.compare", "true");
    this.onboardingDialog && this.onboardingDialog.close();
    this.menuIsVisible = false;
    this.showTip = false;
  }

}
