import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppConfig } from "./app.config";
import { Observable, lastValueFrom } from "rxjs";
import { ContinuumGroup } from "./uilib/continuum-accordion/continuum.model";
import { HorizontalCard } from "./uilib/horizontal-card/horizontal-card.model";
import { RelatedResource } from "./shared/Models/related-resource.model";
import { HsResourceType } from "./healthstatsplus/healthstatsplus-resource";
import { LocationTopicCardResponse, ResultItem } from "./uilib/search-result-page/searchresultpage.model";

@Injectable({
  providedIn: "root",
})
export class StatsService {
  apiStatsURL: string = AppConfig.settings.server.apiStats;

  constructor(public httpClient: HttpClient) {}

  public getIndicatorMetaGroupsByName(indicatorName: string) {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/statsindicator/${indicatorName}/meta/group`
    );
  }

  public getIndicatorMeta(indicatorName: string) {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/statsindicator/${indicatorName}/meta`
    );
  }

  public getIndicatortTerms(indicatorName: string) {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/statsindicator/${indicatorName}/terms`
    );
  }

  public getMetaForMultipleIndicators(indicatorNames: string[]) {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/statsindicator/meta?indicatorNames=${indicatorNames.join(',')}`
    );
  }

  public getIndicatorLocationGroupsData(
    indicatorName: string,
    location: string,
    groups: string,
    view: string
  ) {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/statsindicator/${indicatorName}/${location}/${view}?groups=${groups}`
    );
  }

  public loadTopics(locationName?: string, locationType?: string) {
    let params: HttpParams = new HttpParams();
    params = !locationName ? params : params.set("location", locationName);
    params = !locationType ? params : params.set("locationType", locationType);
    return this.httpClient.get<any>(`${this.apiStatsURL}/topic`, {
      params: params,
    });
  }

  public loadTopicsAtoz(topic: string, location: string, locationType: string) {
    let queryParams = '';
    if (location && locationType) {
      queryParams += `?location=${location}&locationType=${locationType}`;
    }

    return this.httpClient.get<any>(`${this.apiStatsURL}/topic/${topic}/atoz${queryParams}`);
  }

  public loadLocations() {
    return this.httpClient.get<any>(`${this.apiStatsURL}/location`);
  }

  public loadLocationSummary(locationType: string, name: string) {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/location/${locationType}/${name}`
    );
  }

  public getContinuumAccordions(topic: string): Observable<ContinuumGroup[]> {
    return this.httpClient.get<ContinuumGroup[]>(
      `${this.apiStatsURL}/topic/${topic}/continuum`
    );
  }

  public loadTopic(topic: string) {
    return this.httpClient.get<ContinuumGroup[]>(
      `${this.apiStatsURL}/topic/${topic}`
    );
  }

  public loadTopicTerms(topic: string) {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/topic/${topic}/terms`
    );
  }

  public loadFeaturedStats(topic: string): Observable<HorizontalCard[]> {
    return this.httpClient.get<HorizontalCard[]>(
      `${this.apiStatsURL}/topic/${topic}/featured-statistic`
    );
  }

  public loadRelatedResources(topic: string): Observable<RelatedResource[]> {
    return this.httpClient.get<RelatedResource[]>(
      `${this.apiStatsURL}/topic/${topic}/resource`
    );
  }

  public loadCarousel(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiStatsURL}/site/carousel`);
  }

  public loadHomeMainLinks(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiStatsURL}/site/main-links`);
  }

  public loadResourceTopLinks(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/topic/resource/toplinks`
    );
  }
  public loadFeatureTopLinks(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.apiStatsURL}/topic/feature/toplinks`
    );
  }

  public getLocationTopicCard(data: ResultItem[]): Promise<LocationTopicCardResponse[]> {
    return lastValueFrom(this.httpClient.post<any>(
      `${this.apiStatsURL}/location/topic/searchcard`, data
    ));
  }

  public loadPublicationResource(
    resourceType: HsResourceType
  ): Observable<RelatedResource[]> {
    return this.httpClient.get<RelatedResource[]>(
      `${this.apiStatsURL}/site/resources/${resourceType}`
    );
  }
}
