export type HsResourceType = 'Snapshot'
  | 'DataReport'
  | 'HealthStatsMethod'
  | 'DataResource'
  | 'ArchivedDataReport';

type HsResourceMap = {
  [key in HsResourceType]: {
    title: string;
    cardDescription: string;
    pageDescription: string;
  };
};

export const HsResource: HsResourceMap = {
  Snapshot: {
    title: 'Snapshots',
    cardDescription: 'Brief publications about key topics and policies in NSW',
    pageDescription: 'Read about policies and key statistics on priority health topics.',
  },
  DataReport: {
    title: 'Data reports',
    cardDescription: 'Detailed analytical reports on key topics',
    pageDescription: 'Read about insights from detailed data reports.',
  },
  HealthStatsMethod: {
    title: 'Methods',
    cardDescription: 'Information about statistical methods and data sources used in HealthStats NSW',
    pageDescription: 'Read general and technical information about methods and data.',
  },
  DataResource: {
    title: 'Data resources',
    cardDescription: 'Find extra data resources that may help you with your analyses',
    pageDescription: 'Use extra data resources for your analyses.',
  },
  ArchivedDataReport: {
    title: 'Archived data reports',
    cardDescription: 'Publications that are no longer current, or have been superseded',
    pageDescription: 'Read historical population health reports.',
  },
};

