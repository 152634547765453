import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { PageComponent } from "./page.component";

const routes = [{ path: "", component: PageComponent }];

@NgModule({
  declarations: [PageComponent],
  imports: [CommonModule, RouterModule.forChild(routes)],
})
export class PageModule {}
