// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nsw-footer p {
  max-width: 100%;
}
.owner {
    font-size:.875rem;
}
.owner-link {
    font-weight:bold;
}

.owner a:hover {
  background-color: rgba(0, 133, 179, .2);
  outline: 2px solid rgba(0, 133, 179, .2)
}
`, "",{"version":3,"sources":["webpack://./src/app/app-footer/app-footer.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;;AAEA;EACE,uCAAuC;EACvC;AACF","sourcesContent":[".nsw-footer p {\r\n  max-width: 100%;\r\n}\r\n.owner {\r\n    font-size:.875rem;\r\n}\r\n.owner-link {\r\n    font-weight:bold;\r\n}\r\n\r\n.owner a:hover {\r\n  background-color: rgba(0, 133, 179, .2);\r\n  outline: 2px solid rgba(0, 133, 179, .2)\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
