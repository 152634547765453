// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  display: grid;
  grid-template-columns: var(--cols-1);
  gap: calc(2 * var(--xs-gutter));
}

.related-resources.card-container {
  margin-bottom: 3rem;
}

@media (min-width: 576px) {
  .card-container {
    grid-template-columns: var(--cols-2);
    gap: calc(2 * var(--sm-gutter));
  }
}

@media (min-width: 768px) {
  .card-container {
    grid-template-columns: var(--cols-3);
    gap: calc(2 * var(--md-gutter));
  }
}

@media (min-width: 992px) {
  .card-container {
    grid-template-columns: var(--cols-4);
    gap: calc(2 * var(--lg-gutter));
  }
}

@media (min-width: 1200px) {
  .card-container {
    grid-template-columns: var(--cols-4);
    gap: calc(2 * var(--xl-gutter));
  }
}

.link-list {
  margin-bottom: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/app/topic-overview/topic-overview.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oCAAoC;EACpC,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,oCAAoC;IACpC,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,oCAAoC;IACpC,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,oCAAoC;IACpC,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,oCAAoC;IACpC,+BAA+B;EACjC;AACF;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".card-container {\r\n  display: grid;\r\n  grid-template-columns: var(--cols-1);\r\n  gap: calc(2 * var(--xs-gutter));\r\n}\r\n\r\n.related-resources.card-container {\r\n  margin-bottom: 3rem;\r\n}\r\n\r\n@media (min-width: 576px) {\r\n  .card-container {\r\n    grid-template-columns: var(--cols-2);\r\n    gap: calc(2 * var(--sm-gutter));\r\n  }\r\n}\r\n\r\n@media (min-width: 768px) {\r\n  .card-container {\r\n    grid-template-columns: var(--cols-3);\r\n    gap: calc(2 * var(--md-gutter));\r\n  }\r\n}\r\n\r\n@media (min-width: 992px) {\r\n  .card-container {\r\n    grid-template-columns: var(--cols-4);\r\n    gap: calc(2 * var(--lg-gutter));\r\n  }\r\n}\r\n\r\n@media (min-width: 1200px) {\r\n  .card-container {\r\n    grid-template-columns: var(--cols-4);\r\n    gap: calc(2 * var(--xl-gutter));\r\n  }\r\n}\r\n\r\n.link-list {\r\n  margin-bottom: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
