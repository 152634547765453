import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-main",
  templateUrl: "./app.main.component.html",
})
export class AppMainComponent implements OnInit {
  isHomePage: boolean = false;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isHomePage = event.url === "/home";
      });
  }

  ngOnInit() {}
}
