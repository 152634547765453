import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AppMainComponent} from '../app.main.component';
import { Util } from '../shared/util';

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['../app-header/app-header.component.css', './app-footer.component.css'] //shares css with header
})
export class AppFooterComponent implements OnInit, AfterViewInit {
    currentYear: number = Util.getCurrentYear();
    
    constructor(public app: AppMainComponent) 
    { }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }
  
}
