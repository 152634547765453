export class Chart {
  Id: string;
  Layout: any;
  Data: any;
  Height: number;

  get ChartHeightStr(): string {
    return this.Height + "px";
  }

  get Style(): any {
    return { position: "relative", height: this.Height + "px" };
  }

  constructor(layout: any, data: any, height: number) {
    this.Layout = layout;
    this.Data = data;
    this.Height = height;
  }
}
