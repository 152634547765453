// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#table-view table {
  /* border: 1px solid grey; */
  /* font-size: 16px; */
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

#table-view th {
  text-align: left;
  font-size: 1.1em;
  font-weight: 600;
}

#table-view thead tr th {
  border-bottom: 2px solid grey;
}

#table-view table .right {
  text-align: right;
}
#table-view table th,
#table-view table td {
  padding: 4px;
}

#table-view td.alt {
  background-color: #f4f4f7;
}
#table-view {
  padding: 10px;
}

.blank-row {
  height: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/app/indicator/indicatorViews/table-view/table-view.component.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,qBAAqB;EACrB,WAAW;EACX,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;AACnB;AACA;;EAEE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb","sourcesContent":["#table-view table {\r\n  /* border: 1px solid grey; */\r\n  /* font-size: 16px; */\r\n  width: 100%;\r\n  border-spacing: 0;\r\n  border-collapse: separate;\r\n}\r\n\r\n#table-view th {\r\n  text-align: left;\r\n  font-size: 1.1em;\r\n  font-weight: 600;\r\n}\r\n\r\n#table-view thead tr th {\r\n  border-bottom: 2px solid grey;\r\n}\r\n\r\n#table-view table .right {\r\n  text-align: right;\r\n}\r\n#table-view table th,\r\n#table-view table td {\r\n  padding: 4px;\r\n}\r\n\r\n#table-view td.alt {\r\n  background-color: #f4f4f7;\r\n}\r\n#table-view {\r\n  padding: 10px;\r\n}\r\n\r\n.blank-row {\r\n  height: 2em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
