import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {
  @Input() checked: boolean = false;
  @Output() checkedChange = new EventEmitter<boolean>();
  @Input() disabled: boolean = false;
  @Input() dashed: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  toggle(checked: boolean) {
    this.checked = checked;
    this.checkedChange.emit(this.checked);
  }


}
