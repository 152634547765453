import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import { Util } from '../shared/util';

export interface ExportOption {
  FileName: string;
  OnDocumentCloned?: Function;
  ExtraHeight?: number;
}

export enum ExportFormat {
  PNG = '.png'
}

@Injectable()
export class DocumentExportService {

  public constructor() {
  }
  
  public async ExportAsPng(elementSelectors: string[], option: ExportOption): Promise<void> {
    const canvas = await this.ConvertToCanvas(elementSelectors, option.OnDocumentCloned, option.ExtraHeight || 0);
    canvas
      .toBlob(blob => {
        Util.saveAs(blob, `${option.FileName}${ExportFormat.PNG}`);
      });
  }

  private async ConvertToCanvas(elementSelectors: string[], onDocumentCloned: Function, extraHeight: number = 0): Promise<HTMLCanvasElement> {
    let [height, width] = this.CalculateTotalHeightAndWidth(elementSelectors, extraHeight);
    const exportContentDiv = this.CreateExportableContainer(height, width);
    this.AttachToMainDocument(exportContentDiv);

    const canvas = await html2canvas(exportContentDiv, {
      allowTaint: true,
      useCORS: true,
      height: height,
      width: width,
      removeContainer: true,
      onclone: (clonedDocument) => {
        this.DettachFromMainDocument(exportContentDiv);
        this.PrepareExportableContent(exportContentDiv.id, elementSelectors, clonedDocument);

        if (onDocumentCloned) {
          onDocumentCloned(clonedDocument);
        }
      }
    });
    return canvas;
  }

  private CalculateTotalHeightAndWidth(elementSelectors: string[], extraHeight: number = 0): [number, number] {
    const bufferHeight: number = 100;
    let height: number = bufferHeight + extraHeight, width: number = 0;
    elementSelectors
      .forEach(key => {
        const element = document.querySelector<HTMLElement>(key);
        height += element.clientHeight;
        if (element.clientWidth > width) {
          width = element.clientWidth;
        }
      });
    return [height, width];
  }

  private CreateExportableContainer(height: number, width: number): HTMLElement {    
    const rootElement = document.createElement('div');
    rootElement.id = 'exportableContainer';
    rootElement.style.height = `${height}px`;
    rootElement.style.width = `${width}px`;
    return rootElement;
  }

  private PrepareExportableContent(exportableContainerId: string, elementSelectors: string[], clonedDocument: Document): void {
    const exportableContainer = clonedDocument.getElementById(exportableContainerId);
    elementSelectors
      .forEach(key => {
        const element = clonedDocument.querySelector<HTMLElement>(key);
        element.style.display = `block`;
        element.style.position = `relative`;
        element.style.height = `${element.clientHeight}px`;
        element.style.overflow = `hidden`;
        element.style.minWidth = `100%`;
        element.style.padding = `0`;
        exportableContainer.appendChild(element);
    });
  }

  private AttachToMainDocument(element: HTMLElement) {
    document.body.appendChild(element);
  }

  private DettachFromMainDocument(element: HTMLElement) {
    document.body.removeChild(element);
  }
}
