import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList} from '@angular/core';
import {CheckboxComponent} from '../checkbox/checkbox.component';
import {Subscription} from 'rxjs';
import {OnDestroy } from '@angular/core';

@Component({
  selector: 'checkbox-accordion',
  templateUrl: './checkbox-accordion.component.html',
  styleUrls: ['./checkbox-accordion.component.css', '../checkbox/checkbox.component.css']
})
export class CheckboxAccordionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() label: string;

  @ContentChildren( CheckboxComponent )
  checkboxes: QueryList<CheckboxComponent>;
  checked: boolean = false;
  dashed: boolean = false;
  subscriptions: Subscription[] = [];
  isOpen: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => this.updateView());
    this.checkboxes.forEach(c => {
      let subscription = c.checkedChange.subscribe(change => this.updateView());
      this.subscriptions.push(subscription);
    })
  }

  toggleCheckbox(checked: boolean) {
    this.checked = checked;

    this.checkboxes.forEach(c => c.toggle(checked));
  }

  toggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  updateView() {
    let [count, total] = this.getCount();
    if (count === total) {
      this.checked = true;
      this.dashed = false;
    } else if (count === 0) {
      this.checked = false;
      this.dashed = false;
    } else {
      this.checked = false;
      this.dashed = true;
    }
  }

  getCount(): [number, number] {
    let total = 0;
    let count = 0;
    this.checkboxes.forEach(c => {
      if (c.checked) {
        count++;
      }
      total++;
    })
    return [count, total];
  }

  getCountDisplay() {
    let [count, total] = this.getCount();
    return `(${count}/${total})`;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
