// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav.nsw-breadcrumb {
  margin: 24px 0;
}

.notification {
  padding: 5px 16px;
  background-color: #d7153a;
  color: white;
  font-family: FiraSans-Regular, sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  margin: 4px 0;
  margin-bottom: 8px;
  margin-top: -20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/app-breadcrumb/app-breadcrumb.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,yCAAyC;EACzC,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":["nav.nsw-breadcrumb {\r\n  margin: 24px 0;\r\n}\r\n\r\n.notification {\r\n  padding: 5px 16px;\r\n  background-color: #d7153a;\r\n  color: white;\r\n  font-family: FiraSans-Regular, sans-serif;\r\n  font-size: 20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  width: 100%;\r\n  border-radius: 4px;\r\n  margin: 4px 0;\r\n  margin-bottom: 8px;\r\n  margin-top: -20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
