import {Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector:'app-redirect',
    template:''
})

export class RedirectComponent implements OnInit {
    constructor(private router: Router) { }

    ngOnInit() {
        const url = this.router.url;
        if(url && url.includes('#')) {
            const newUrl = url.replace('#/','');
            this.router.navigateByUrl(newUrl);
        } else {
            this.router.navigateByUrl("/home");
        }
    }
}