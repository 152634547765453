// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-summary {
  display: flex;
  margin-bottom: 3rem;
}

.location-summary > * {
  max-width: calc(100% / 2 - 8px);
}

.location-summary > *:first-child {
  margin-right: 16px;
}

.location-summary a{
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/location-overview/location-summary/location-summary.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".location-summary {\r\n  display: flex;\r\n  margin-bottom: 3rem;\r\n}\r\n\r\n.location-summary > * {\r\n  max-width: calc(100% / 2 - 8px);\r\n}\r\n\r\n.location-summary > *:first-child {\r\n  margin-right: 16px;\r\n}\r\n\r\n.location-summary a{\r\n  display: flex;\r\n  align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
