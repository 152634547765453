import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  @ViewChildren("menuLink") menuLinks: QueryList<any>;
  
  showSearchButton: boolean = true;
  showSearchInput: boolean = false;
  isMenuOpen: boolean = false;
  searchTerm: string = '';

  constructor(private _router: Router) { }
  
  toggleSearchBar() {
    this.searchTerm = '';
    this.showSearchButton = !this.showSearchButton;
    this.showSearchInput = !this.showSearchInput;
  }
  
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    
    // remove focus on link upon routing
    this.menuLinks.toArray().forEach(l => {
      l.nativeElement.blur();
    });
  }

  ngOnInit() {
  }

  search() {
    if (this.searchTerm) {
      this._router.navigateByUrl(`/search?searchTerm=${this.searchTerm}`);
      this.toggleSearchBar();
    }
  }
}
