// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .link-list {
  margin-bottom: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/app/location-overview/location-overview.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB","sourcesContent":["::ng-deep .link-list {\r\n  margin-bottom: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
