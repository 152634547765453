import { Component, Input, Inject, PLATFORM_ID, } from '@angular/core';
import { AppInsightsService } from 'src/app/services/appinsights.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent {
  @Input() items: CarouselResource[];
  isBrowser: boolean = false;
  constructor(private googleAnalyticsService: GoogleAnalyticsService,
    private appInsightsService: AppInsightsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = isPlatformBrowser(this.platformId);
    }
  }

  onCarouselItemClick(item: CarouselResource): void {
    this.googleAnalyticsService.pageEmitter('Carousel');
    this.googleAnalyticsService.eventEmitter('Click_' + item.redirectUrl, 'Carousel', 'Click', item.label);
    this.appInsightsService.logPageView(`Carousel - ${item.label}`, item.redirectUrl);
  }
}
export interface CarouselResource {
  label: string;
  description: string;
  imageUrl: string;
  backgroundImageUrl: string;
  redirectUrl: string;
}
