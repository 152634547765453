import {Component, OnInit} from '@angular/core';
import {ContentService} from '../content.service';
import {Glossary} from './glossary.model';
import {Subject} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import { MetadataService } from '../metadata.service';

@Component({
  selector: 'glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent implements OnInit {
  searchTerm: string;
  searchTermSubject: Subject<string> = new Subject();
  glossaryMap: Map<string, Glossary[]> = new Map<string, Glossary[]>();
  glossaryKeys: string[];
  glossaries: Glossary[] = [];
  exactMatch: boolean = false;

  constructor(private contentService: ContentService,
              private route: ActivatedRoute,
              private router: Router,
              private metadataService: MetadataService) { }

  ngOnInit() {
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: "Glossary",
      });
    }
    this.searchTermSubject.pipe(
      debounceTime(250),
      switchMap(searchTerm => this.contentService.getGlossary(searchTerm, this.exactMatch))
    ).subscribe(glossaries => {
      this.parseData(glossaries);

      let queryParams = {searchTerm: this.searchTerm};
      if (this.exactMatch) {
        queryParams['exactMatch'] = 'true';
      }
      this.router.navigate(['.'], {relativeTo: this.route, queryParams: queryParams});
    });

    const params = this.route.snapshot.queryParams;
    if (!!params['searchTerm']) {
        this.onSearchChange(params['searchTerm'], params['exactMatch'] === 'true');
    } else {
      // initialise search
      this.onSearchChange('');
    }
  }

  parseData(glossaries: Glossary[]) {
    this.glossaries = glossaries;
    this.glossaryMap = new Map<string, Glossary[]>();
    for (let glossary of glossaries) {
      const firstLetter = glossary.title[0].toUpperCase();
      const glossaryList = this.glossaryMap.get(firstLetter) || [];
      glossaryList.push(glossary);
      this.glossaryMap.set(firstLetter, glossaryList);
    }
    this.glossaryKeys = [...this.glossaryMap.keys()];
  }

  onSearchChange(searchTerm: string, exactMatch?: boolean) {
    this.exactMatch = !!exactMatch;
    this.searchTerm = searchTerm;
    this.searchTermSubject.next(searchTerm);
  }

  getGlossaries(letter: string): Glossary[] {
    return this.glossaryMap.get(letter.toUpperCase());
  }

}
