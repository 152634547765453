import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarButtonComponent } from './toolbar-button/toolbar-button.component';
import { GroupContainerComponent } from './chart-viewer-filter/group-container/group-container.component';
import { ChartViewerFilterComponent } from './chart-viewer-filter/chart-viewer-filter.component';
import { LocationContainerComponent } from './chart-viewer-filter/location-container/location-container.component';
import { LiteAccordionComponent } from './lite-accordion/lite-accordion.component';
import { LiteButtonMenuComponent } from './lite-button-menu/lite-button-menu.component';
import { RadioButtonMenuComponent } from './radio-button-menu/radio-button-menu.component';
import { SortableCheckboxMenuComponent } from './sortable-checkbox-menu/sortable-checkbox-menu.component';
import { PeriodContainerComponent } from './chart-viewer-filter/period-container/period-container.component';
//import { BrowserModule } from '@angular/platform-browser';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HybridDropdownComponent } from './hybrid-dropdown/hybrid-dropdown.component';
import { AlphaSelectorComponent } from './alpha-selector/alpha-selector.component';
import { ListSetComponent } from './list-set/list-set.component';
import { LinkListComponent } from './link-list/link-list.component';
import { LinkListItemComponent } from './link-list-item/link-list-item.component';
import { CardComponent } from './card/card.component';
import { AccordionComponent } from './accordion/accordion.component';
import { ContinuumAccordionComponent } from './continuum-accordion/continuum-accordion.component';
import { DialogComponent } from './dialog/dialog.component';
import { HorizontalCardComponent } from './horizontal-card/horizontal-card.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CheckboxAccordionComponent } from './checkbox-accordion/checkbox-accordion.component';
import { SelectButtonComponent } from './select-button/select-button.component';
import {RouterModule} from '@angular/router';
import { CustomHrefDirective } from './custom-href.directive';
import { PaginatorComponent } from './paginator/paginator.component';
import { FeaturedCardsComponent } from './featured-cards/featuredcards.component';
import { SearchResultPageComponent } from './search-result-page/searchresultpage.component';
import { NotificationComponent } from './notification/notification.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselModule } from 'primeng/carousel';

@NgModule({
  declarations: [ToolbarButtonComponent,
    ChartViewerFilterComponent,
    GroupContainerComponent,
    LocationContainerComponent,
    LiteAccordionComponent,
    LiteButtonMenuComponent,
    RadioButtonMenuComponent,
    HybridDropdownComponent,
    SortableCheckboxMenuComponent,
    PeriodContainerComponent,
    HybridDropdownComponent,
    AlphaSelectorComponent,
    ListSetComponent,
    LinkListComponent,
    LinkListItemComponent,
    CardComponent,
    AccordionComponent,
    ContinuumAccordionComponent,
    DialogComponent,
    HorizontalCardComponent,
    SafeHtmlPipe,
    CheckboxComponent,
    CheckboxAccordionComponent,
    SelectButtonComponent,
    CustomHrefDirective,
    PaginatorComponent,
    FeaturedCardsComponent,
    SearchResultPageComponent,
    NotificationComponent,
    CarouselComponent
  ],
  exports: [ToolbarButtonComponent,
    ChartViewerFilterComponent,
    GroupContainerComponent,
    LocationContainerComponent,
    RadioButtonMenuComponent,
    HybridDropdownComponent,
    SortableCheckboxMenuComponent,
    AlphaSelectorComponent,
    ListSetComponent,
    LinkListComponent,
    LinkListItemComponent,
    CardComponent,
    AccordionComponent,
    ContinuumAccordionComponent,
    DialogComponent,
    HorizontalCardComponent,
    SafeHtmlPipe,
    CheckboxComponent,
    CheckboxAccordionComponent,
    SelectButtonComponent,
    CustomHrefDirective,
    PaginatorComponent,
    FeaturedCardsComponent,
    SearchResultPageComponent,
    NotificationComponent,
    //BrowserAnimationsModule
    CarouselComponent
  ],
  imports: [
    CommonModule,
    //BrowserModule,
    DragDropModule,
    RouterModule,
    //BrowserAnimationsModule
    CarouselModule
  ]
})
export class UILibModule { }
