// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-button {
  position: relative;
  display: inline-block;
}

.select-button--button > * {
  vertical-align: middle;
}

.select-button--button > i {
  position: relative;
  left: 4px;
  margin-left: 10px;
}

.select-button--list {
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms;
  transition: visibility 300ms;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  padding: 12px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);
  z-index: 1000;
  text-align: initial;
}

.select-button--list label {
  display: block;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  cursor: pointer;
  line-height: 48px;
  white-space: nowrap;
}

.select-button--list label:hover {
  background-color: #CCE7F0;
}

.select-button--list.open {
  visibility: visible;
  opacity: 1;
  transition: opacity 300ms;
}


`, "",{"version":3,"sources":["webpack://./src/app/uilib/select-button/select-button.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,4BAA4B;EAC5B,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,6CAA6C;EAC7C,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":[".select-button {\r\n  position: relative;\r\n  display: inline-block;\r\n}\r\n\r\n.select-button--button > * {\r\n  vertical-align: middle;\r\n}\r\n\r\n.select-button--button > i {\r\n  position: relative;\r\n  left: 4px;\r\n  margin-left: 10px;\r\n}\r\n\r\n.select-button--list {\r\n  visibility: hidden;\r\n  opacity: 0;\r\n  transition: opacity 300ms;\r\n  transition: visibility 300ms;\r\n  position: absolute;\r\n  top: 60px;\r\n  left: 0;\r\n  width: 100%;\r\n  padding: 12px;\r\n  background-color: white;\r\n  border-radius: 4px;\r\n  box-shadow: 0 4px 8px 0 rgba(74, 74, 74, 0.3);\r\n  z-index: 1000;\r\n  text-align: initial;\r\n}\r\n\r\n.select-button--list label {\r\n  display: block;\r\n  padding-left: 12px;\r\n  padding-right: 12px;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n  line-height: 48px;\r\n  white-space: nowrap;\r\n}\r\n\r\n.select-button--list label:hover {\r\n  background-color: #CCE7F0;\r\n}\r\n\r\n.select-button--list.open {\r\n  visibility: visible;\r\n  opacity: 1;\r\n  transition: opacity 300ms;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
