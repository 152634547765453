import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "./app.config";
import { Observable, lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SiteService {

  private _apiBase: string = AppConfig.settings.server.apiStats;

  constructor(public httpClient: HttpClient) {
  }

  public getOrSetShortPath(name: string, base64Path: string): Promise<string> {
    return lastValueFrom(this.httpClient.post<string>(`${this._apiBase}/site/shortpath`, { name, base64Path }));
  }

  public getLongPath(shortUrlId: string): Promise<string> {
    return lastValueFrom(this.httpClient.get<string>(`${this._apiBase}/site/longpath/${shortUrlId}`, { responseType: 'text' as 'json' }));
  }
}
