import { DocumentExportService } from "../../services/documentexport.service";
import { PlotlyServiceExt } from "../../services/plotly-service-extension";
import { VisualisationDownloadFormat } from "../constraints";
import { Chart } from "./chartData";
import { FacetLayout } from "./faceted-layout";

export class PlotlyGraphView extends FacetLayout  {
  private downloadOption: any;

  constructor(private _documentExportService: DocumentExportService, private _plotlyService: PlotlyServiceExt) {
    super();
    this.downloadOption = {
      height: 573,
      width: 1046
    };
  }

  protected exportAsPNG(fileName: string): any {
    const elementSelectors = ['.indicator-chart--title', '.indicator-chart--subtitle', '.chart-viewer--display', '.source-container'];

    let chartScrollHeight = 0;
    let chartElement = document.querySelector<HTMLElement>('.trend-view-canvas');
    if (!chartElement || chartElement.style.display == 'none') {
      chartElement = document.querySelector<HTMLElement>('.snapshot-view-canvas');
    }

    if (chartElement && chartElement.scrollHeight && chartElement.scrollHeight > 0) {
      chartScrollHeight = chartElement.scrollHeight - 300;
    }
    this._documentExportService.ExportAsPng(elementSelectors, { FileName: fileName, OnDocumentCloned: this.AdjustLegendView, ExtraHeight: chartScrollHeight });
  }

  protected async exportAsSVG(graphDivId: string, graphTitle: string, legends: [], source: string, fileName: string) {
    this.downloadOption.format = VisualisationDownloadFormat.SVG;
    this.downloadOption.filename = fileName;
    await this._plotlyService.export(graphDivId, graphTitle, legends, source, this.downloadOption);
  }

  private AdjustLegendView(clonedDocument: Document): void {
    const chart = clonedDocument.querySelector<HTMLElement>('.chart-viewer--display');

    let chartElement = clonedDocument.querySelector<HTMLElement>('.trend-view-canvas');
    let legend = clonedDocument.querySelector<HTMLElement>('.trend-view-legend-container');
    if (!chartElement || chartElement.style.display == 'none') {
      chartElement = clonedDocument.querySelector<HTMLElement>('.snapshot-view-canvas');
      chart.style.height = `${chart.clientHeight + 100}px`;
      legend = clonedDocument.querySelector<HTMLElement>('.snapshot-view-legend-container');
    }
    else {
      chart.style.height = `${chart.clientHeight + 40}px`;
    }

    let chartCanvas = chartElement.querySelector<HTMLElement>('.chart-viewer--canvas');
    if (chartCanvas && chartCanvas.scrollHeight && chartCanvas.scrollHeight > chartCanvas.clientHeight) {
      const chartHeight = chartCanvas.clientHeight + chartCanvas.scrollHeight - 300;
      chart.style.height = chartCanvas.style.minHeight = `${chartHeight}px`;
    }

    legend.style.overflow = 'hidden';
    legend.style.flexWrap = 'wrap';
    const legendGroupList = legend.querySelector<HTMLElement>('.legend--group--list');
    if (legendGroupList) {
      legendGroupList.style.gridTemplateRows = 'auto auto auto';
    }
    const liteAccordianButton = legend.querySelector<HTMLElement>('#legendToggleBtn');
    liteAccordianButton.style.display = 'none';

    const legendContentWrapper = legend.querySelector<HTMLElement>('.legend-content-wrapper');
    legendContentWrapper.style.display = 'flex';
    const legendMask = legend.querySelector<HTMLElement>('.chart-viewer--legend--mask');
    if (legendMask) {
      legendMask.style.display = 'none';
    }
  }

  protected CreateChartTitle(title: string, subTitle: string, chart: Chart): string {
    const subPlotTitle = chart.Layout && chart.Layout.title && chart.Layout.title.text ? ' - (' + chart.Layout.title.text + ')' : '';
    return `${title} ${subTitle || ''}${subPlotTitle}`;
  }
}
