import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { IndicatorState, StatsIndicatorMeasure } from '../../../../src/app/indicator/indicator.state';
import { Util } from "../../../../src/app/shared/util";
import { ViewType } from "../../../../src/app/indicator/constraints";

@Component({
  selector: "app-hybrid-dropdown",
  templateUrl: "./hybrid-dropdown.component.html",
  styleUrls: ["./hybrid-dropdown.component.css"],
})
export class HybridDropdownComponent implements OnInit {
  @Input() titleLabel = "Measure:";
  @Output() itemsSelected: EventEmitter<string[]> = new EventEmitter();

  title: string;
  items: string[] = [];
  selectedItems: string[] = [];
  measures: StatsIndicatorMeasure[];
  controlModeCheckboxes = true;
  controlIsVisible: boolean;
  stateLocalCopy: IndicatorState;

  constructor() {
    this.controlIsVisible = false;
  }

  ngOnInit() {}

  onToggleButtonClick(event: any) {
    this.controlIsVisible = !this.controlIsVisible;
  }

  getItemDescription(name: string) {
    var filteredMeasures = this.get_filtered_measures();
    const item = filteredMeasures.find((x) => {
      return x.name === name;
    });
    return !!item ? item.description : '';
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  get_filtered_measures() {
    var measures = Util.deepCopy(this.stateLocalCopy.Meta.measures);
    var groups = Util.deepCopy(this.stateLocalCopy.Groups);
    if (!!this.stateLocalCopy.Location && !!this.stateLocalCopy.Location.length && this.stateLocalCopy.Location !== "NSW" && !!groups && groups.indexOf(this.stateLocalCopy.Location) === -1) {
      groups.push(this.stateLocalCopy.Location);
    }

    var sortedGroupsAppliedOnTheUI = this.sort_groups_alphabetically(groups);
    var groupMeasures = measures.filter(m => !!groups && this.sort_groups_alphabetically(this.split_measure_groups_safely(m.groups)) === sortedGroupsAppliedOnTheUI);
    var filteredMeasures = !!groupMeasures && !!groupMeasures.length ? groupMeasures : measures;
    return filteredMeasures;
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  sort_groups_alphabetically(groups) {
    if (!!groups) {
      return groups.sort((a, b) => a.localeCompare(b)).join();
    }
    return "";
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  split_measure_groups_safely(measureGroups) {
    if (!!measureGroups) {
      if (measureGroups.indexOf(",") !== -1) {
        return measureGroups.split(",");
      }
      else {
        return [measureGroups];
      }
    }
    return [];
  }

  onStateUpdated(state: IndicatorState) {
    this.stateLocalCopy = JSON.parse(JSON.stringify(state))
    if (this.stateLocalCopy.View !== ViewType.Trend) {
      this.stateLocalCopy.Meta.measures.forEach(m => {
        if (!!m.groups && m.groups.indexOf("Period") === -1) {
          m.groups = m.groups + ",Period";
        }
        else if (!m.groups) {
          m.groups = "Period";
        }
      });
    }
    else {
      if (this.stateLocalCopy.Groups.indexOf("Period") === -1) {
        this.stateLocalCopy.Meta.measures.forEach(m => {
          if (!!m.groups && m.groups.indexOf("Period") >= 0) {
            m.groups = m.groups.replace(",Period", "").replace("Period", "");
          }
        });
      }
    }

    this.measures = this.get_filtered_measures();
    this.selectedItems = Util.copy(state.Measure);
    this.controlModeCheckboxes = state.View === ViewType.Table;
    this.items = this.getMeasureOptions(state);
    this.updateTitle();
  }

  getMeasureOptions(state: IndicatorState): string[] {
    // don't include confidence intervals in measure dropdown
    var filteredMeasures = this.get_filtered_measures();
    const validMeasures = filteredMeasures.filter(m => m.ci === false);
    const measureNames = validMeasures.map(m => m.name);
    let options = [];
    if(state.Data.length > 0){
        Object.keys(state.Data[0]).forEach(measure => {
          if (measureNames.includes(measure)) {
            options.push(measure);
          }
        });
    }

    this.selectedItems = this.selectedItems.filter((item: string) =>
      options.indexOf(item) >= 0
    );

    this.items = this.items.filter((item: string) =>
      options.indexOf(item) >= 0
    );

    if (this.controlModeCheckboxes) {
      let sortedOptions = [];

      if (this.items.length > 0) {
        // keep existing order of items
        sortedOptions.push(...this.items);
      } else {
        // sort items according to order of selected items
        sortedOptions.push(...this.selectedItems);
      }

      // remaining items will be appended last
      options.forEach(item => !sortedOptions.includes(item) && sortedOptions.push(item));
      options = sortedOptions;
    }

    return options;
  }

  radioButtonState(item: string) {
    if (
      typeof this.selectedItems !== "undefined" &&
      this.selectedItems.indexOf(item) !== -1
    ) {
      return "ui-button ui-widget ui-checkboxradio-radio-label ui-checkboxradio-checked ui-state-active ui-checkboxradio-label ui-controlgroup-item";
    } else {
      return "ui-button ui-widget ui-checkboxradio-radio-label ui-checkboxradio-label ui-controlgroup-item";
    }
  }

  onItemClicked(event: Event, id: any) {
    event.preventDefault();
    if (this.controlModeCheckboxes) {
      if (this.selectedItems.includes(id)) {
        this.selectedItems = Util.remove(this.selectedItems, id);
      } else {
        this.selectedItems.push(id);
      }
    } else {
      this.selectedItems = [];
      this.selectedItems.push(id);
    }
    this.controlIsVisible = false;
    this.ActionChanges();
  }

  checkIfSelected(item: string) {
    return this.selectedItems.includes(item);
  }

  ActionChanges() {
    const sorted = this.updateTitle();
    this.itemsSelected.emit(sorted);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.ActionChanges();
    this.controlIsVisible = false;
  }

  updateTitle() {
    const sorted = this.items.filter((x) => this.selectedItems.indexOf(x) >= 0);
    const deslugified = sorted
      .reduce((result, current) => {
        result.push(this.getItemDescription(current));
        return result;
      }, [])
      .join(", ");
    this.title = deslugified.trim();
    if (this.title == "") {
      this.title = "None selected";
    }
    return sorted;
  }
}
