import {
  Locations,
  LocationsArray,
  ViewType,
  ViewTypesArray,
} from "src/app/indicator/constraints";
import { HttpParams } from "@angular/common/http";
import { StatsService } from "../stats.service";
import {
  ConfidenceInterval,
  IndicatorState,
  StatsIndicatorLocationPresentation,
  StatsIndicatorMeasure,
} from "./indicator.state";
import { Location } from "@angular/common";
import { Util } from "../shared/util";
import { FacetsLib } from "./facets-lib";
import { ActivatedRoute, Router } from "@angular/router";
import { MeasureService } from "./../services/measure.service";
import { lastValueFrom } from "rxjs";

export class StateComparison {
  changed = false;
  locationSubdivisionChanged = false;
  groupsChanged = false;
  filterChanged = false;
  compareChanged = false;
  confidenceChanged = false;
  measureChanged = false;
  viewChanged = false;
  nameChanged = false;

  constructor(state1: IndicatorState, state2: IndicatorState) {
    if (state1.Location !== state2.Location) {
      this.locationSubdivisionChanged = true;
    }
    // exclude periods from the both groups before comparison
    const state1groups = Util.remove(state1.Groups, "Period");
    const state2groups = Util.remove(state2.Groups, "Period");

    if (!Util.arraysEqual(state1groups, state2groups)) {
      this.groupsChanged = true;
    }
    if (!Util.equals(state1.Measure, state2.Measure)) {
      this.measureChanged = true;
    }
    if (!Util.equals(state1.Compare, state2.Compare)) {
      this.compareChanged = true;
    }
    if (state1.Confidence !== state2.Confidence) {
      this.confidenceChanged = true;
    }
    if (state1.View !== state2.View) {
      this.viewChanged = true;
    }
    if (state1.Name !== state2.Name) {
      this.nameChanged = true;
    }

    // compare filters
    const state1FilterKeys = Object.keys(state1.Filter);
    const state2FilterKeys = Object.keys(state2.Filter);
    if (!Util.arraysEqual(state1FilterKeys, state2FilterKeys)) {
      this.filterChanged = true;
    } else {
      for (let i = 0; i < state1FilterKeys.length; i++) {
        const key = state1FilterKeys[i];
        const key1Items = state1.Filter[key];
        const key2Items = state2.Filter[key];
        if (!Util.arraysEqual(key1Items, key2Items)) {
          this.filterChanged = true;
          break;
        }
      }
    }

    this.changed =
      this.locationSubdivisionChanged ||
      this.groupsChanged ||
      this.measureChanged ||
      this.compareChanged ||
      this.filterChanged ||
      this.viewChanged ||
      this.confidenceChanged;
  }

  get Changed() {
    //debugger;
    return this.changed;
  }
  get LocationSubdivisionChanged() {
    return this.locationSubdivisionChanged;
  }
  get GroupsChanged() {
    return this.groupsChanged;
  }
  get CompareChanged() {
    return this.compareChanged;
  }
  get ConfidenceChanged() {
    return this.confidenceChanged;
  }
  get MeasureChanged() {
    return this.measureChanged;
  }
  get FilterChanged() {
    return this.filterChanged;
  }
  get ViewChanged() {
    return this.viewChanged;
  }
  get NameChanged() {
    return this.nameChanged;
  }
}

export class StateManager {
  static SILENT = { replace: true, suppress: true };

  statsService: StatsService;
  private state: IndicatorState;
  parameters: HttpParams;
  location: Location;
  measureService: MeasureService;

  public onStateUpdated?: (state: IndicatorState) => void;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    statsService: StatsService,
    location: Location
  ) {
    this.statsService = statsService;
    this.location = location;
    this.state = new IndicatorState();
    this.measureService = new MeasureService();
  }

  async updateState(params: any = undefined) {
    let httpParams: HttpParams = new HttpParams();

    if (params === undefined) {
      params = window.location.search;
    }

    httpParams = new HttpParams({ fromString: params });
    const stateFromUrl = this.convertHttpParamsToState(httpParams);
    const comp = new StateComparison(this.state, stateFromUrl);

    if (this.state.Meta === undefined || comp.NameChanged) {
      await this.loadGlobalMeta(stateFromUrl.Name);
    }
    if (!this.validateAndFixParameters(stateFromUrl)) {
      this.navigateToState(stateFromUrl);
      await this.updateState();
      return;
    }
    this.updateStateFromAnotherState(stateFromUrl, this.state);
    if (
      comp.LocationSubdivisionChanged ||
      comp.GroupsChanged ||
      comp.NameChanged ||
      comp.viewChanged
    ) {
      await this.reloadData();
    }
    this.update_group_options();
    this.filterData();
    this.state.ChartTitle = this.buildTitle();
    this.state.ConfidenceIntervals = this.buildConfidenceIntervalMap(
      this.state
    );
    this.onStateUpdated(this.state);
    this.navigateToState(stateFromUrl);
  }

  async updateStateFromUrl(params: any) {
    const httpParams = new HttpParams({ fromObject: params });
    await this.updateState(httpParams.toString());
  }

  createState(params: any, meta: any): IndicatorState {
    this.state.Meta = meta;
    const httpParams = new HttpParams({ fromObject: params });
    const stateFromUrl = this.convertHttpParamsToState(httpParams);
    this.validateAndFixParameters(stateFromUrl);
    stateFromUrl.ChartTitle = this.buildTitle();
    return stateFromUrl;
  }

  // build map of ConfidenceIntervals where the key is the name of the measure that the confidence intervals belong to
  buildConfidenceIntervalMap(state: IndicatorState): {
    [name: string]: ConfidenceInterval;
  } {
    let measures: StatsIndicatorMeasure[] =
      this.measureService.get_filtered_measures(state);
    let ciMap = {};

    // Remove duplicate measures
    measures = measures.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.ci === value.ci &&
            t.name === value.name &&
            t.groups === value.groups
        )
    );

    // confidence interval measures will always come after its parent measure
    // first interval will always be lower, second will be upper
    for (let i = 0; i < measures.length; i++) {
      let m = measures[i];
      if (!m.ci && ciMap[m.name] == null) {
        // add to ciMap if following 2 measures are CI
        let lowerCi = measures[i + 1];
        let upperCi = measures[i + 2];
        if (!!lowerCi && lowerCi.ci && !!upperCi && upperCi.ci) {
          const ci = new ConfidenceInterval();
          ci.lower = lowerCi;
          ci.upper = upperCi;
          ciMap[m.name] = ci;
        }
      }
    }
    return ciMap;
  }

  buildTitle() {
    let title = this.state.Compare.reduce(
      (accumulator, current) => {
        // console.log('accumulator, current',accumulator, current);

        // If first, and therefore the primary group
        if (accumulator.title === "") {
          // If no filter for this group, therfore single value
          if (this.state.Filter[current] === undefined) {
            accumulator.title = `${Util.deslugify(current)}`;
          } else if (this.state.Filter[current].length === 1) {
            accumulator.title = this.state.Filter[current][0];
          } else {
            Object.assign(accumulator, {
              title: `by ${Util.deslugify(current)}`,
              by: true,
            });
          }
        } else {
          // console.log('not primary')

          // If is nsw (special case as has no filter), or has filter with single value
          if (
            current === Locations.NSW ||
            (this.state.Filter[current] !== undefined &&
              this.state.Filter[current].length === 1)
          ) {
            // console.log('single value')

            if (accumulator.for) {
              Object.assign(accumulator, {
                title: `${FacetsLib.replace_prefix(
                  "and",
                  accumulator.title
                )} and `,
                and: true,
              });
            } else {
              Object.assign(accumulator, {
                title: `${accumulator.title} for `,
                for: true,
                by: false,
                and: false,
              });
            }

            accumulator.title =
              current === Locations.NSW
                ? `${accumulator.title}NSW`
                : `${accumulator.title}${this.state.Filter[current][0]}`;
          } else if (this.state.Filter[current] === undefined) {
            // console.log('no filter')

            // If the title has a 'for' in it
            if (accumulator.for) {
              Object.assign(accumulator, {
                title: `${accumulator.title} and ${Util.deslugify(current)}`,
                and: true,
              });
            } else {
              Object.assign(accumulator, {
                title: `${accumulator.title} for ${Util.deslugify(current)}`,
                for: true,
                by: false,
                and: false,
              });
            }
          } else {
            // console.log('multi value')

            if (accumulator.by) {
              Object.assign(accumulator, {
                title: `${FacetsLib.replace_prefix(
                  "and",
                  accumulator.title
                )} and `,
                and: true,
              });
            } else {
              Object.assign(accumulator, {
                title: `${accumulator.title} by `,
                for: false,
                by: true,
                and: false,
              });
            }
            accumulator.title = `${accumulator.title}${Util.deslugify(
              current
            )}`;
          }
        }

        return accumulator;
      },
      {
        title: "",
        by: false,
        for: false,
        and: false,
      }
    ).title;

    return title;
  }

  async updateStateFromIndicator(stateFromIndicator: IndicatorState) {
    const params = this.convertStateToHttpParams(stateFromIndicator);
    await this.updateState(params.toString());
  }

  validateAndFixParameters(stateFromUrl: IndicatorState) {
    if (!this.validateQueryString(stateFromUrl)) {
      return false;
    }
    if (!this.validate_groups(stateFromUrl)) {
      return false;
    }
    if (!this.validate_filter(stateFromUrl)) {
      return false;
    }
    if (!this.validate_compare(stateFromUrl)) {
      return false;
    }
    return true;
  }

  validateQueryString(stateFromUrl: IndicatorState): boolean {
    // first time we load the indicator
    if (!stateFromUrl.Location || !stateFromUrl.View) {
      for (
        let lIndex = 0;
        lIndex < this.state.Meta.locations.length;
        lIndex++
      ) {
        const location = this.convertToValueType(
          this.state.Meta.locations[lIndex]
        );

        if (!location || !location.presentations) {
          return false;
        }

        const defaultPresentation = location.presentations.find(
          (presentation) => presentation.defaultView !== undefined
        );

        if (!!defaultPresentation) {
          const view = defaultPresentation.views.find(
            (view) => view.name === defaultPresentation.defaultView
          );
          if (!stateFromUrl.Location) stateFromUrl.Location = location.name;

          if (!stateFromUrl.View)
            stateFromUrl.View = ViewType[defaultPresentation.defaultView];

          stateFromUrl.Groups = defaultPresentation.groups;
          stateFromUrl.Measure = [view.defaultMeasure];
          stateFromUrl.Compare = defaultPresentation.groups;

          return false;
        }
      }

      // there is no default view so we use first one
      if (this.state.Meta.locations.length > 0) {
        const location = this.state.Meta.locations[0];
        const presentation = this.convertToValueType(location.presentations[0]);
        if (presentation) {
          const view = presentation.views[0];
          stateFromUrl.Measure = [view.defaultMeasure];
          stateFromUrl.Groups = this.convertToValueType(presentation.groups);
          stateFromUrl.Compare = presentation.groups;

          if (!stateFromUrl.View) stateFromUrl.View = ViewType[view.name];
        }

        if (!stateFromUrl.Location) stateFromUrl.Location = location.name;
      }

      return false;
    }

    // Wrong location
    if (!LocationsArray.includes(stateFromUrl.Location)) {
      delete stateFromUrl.Location;
      return false;
    }

    const presentations = this.convertToValueType(
      this.state.Meta.locations.find(
        (location) => location.name === stateFromUrl.Location
      ).presentations
    );

    if (stateFromUrl.Location !== Locations.NSW) {
      if (stateFromUrl.Compare.length !== 0) {
        LocationsArray.forEach((current) => {
          if (
            current !== stateFromUrl.Location &&
            stateFromUrl.Compare.includes(current)
          ) {
            stateFromUrl.Compare.splice(
              stateFromUrl.Compare.indexOf(current),
              1
            );
            return false;
          }
        });
      }
    }

    // Wrong view
    if (!ViewTypesArray.includes(stateFromUrl.View)) {
      stateFromUrl.View = this.FindDefaultView(presentations);
      return false;
    } else if (stateFromUrl.View === ViewType.Trend) {
      if (stateFromUrl.Groups.includes("Period")) {
        stateFromUrl.Groups.splice(stateFromUrl.Groups.indexOf("Period"), 1);
        stateFromUrl.Compare.splice(stateFromUrl.Compare.indexOf("Period"), 1);
        // Igor - complains on Filter.Period
        // if (stateFromUrl.Filter.hasOwnProperty('Period')) {
        //     delete stateFromUrl.Filter.Period;
        // }
        return false;
      }
    } else {
      // BarHorizontal, Map and Table

      // If the 'group' property does not contain the 'period' group
      if (!stateFromUrl.Groups.includes("Period")) {
        // Add the 'period' group to the 'groups' property at the beginning of the list
        // This way it will show up at the top of the series filtering
        stateFromUrl.Groups.unshift("Period");

        // Add the 'period' group to the 'compare' property ar the end of the list
        // This way it will be the least significant grouping
        stateFromUrl.Compare.push("Period");

        return false;
      }
    }

    if (stateFromUrl.Measure.length !== 0) {
      // If not table view and there are more than 1 measures selected
      if (
        stateFromUrl.View !== ViewType.Table &&
        stateFromUrl.Measure.length > 1
      ) {
        // Set measure to the first measure
        stateFromUrl.Measure = [stateFromUrl.Measure[0]];
        return false;
      }
    }

    return true;
  }

  // Validate that groups are valid, updates URL state to valid state if not
  // Returns true if state was NOT modified, false if modified
  validate_groups(stateFromUrl: IndicatorState): boolean {
    let valid = true;

    const presentations = this.convertToValueType(
      this.state.Meta.locations.find(
        (location) => location.name === stateFromUrl.Location
      ).presentations
    );

    if (stateFromUrl.Groups.length !== 0) {
      // Get the allowed groups for this location type

      let location_type_groups = presentations.map(
        (presentation) => presentation.groups
      );
      stateFromUrl.Groups.forEach((group) => {
        if (group !== "Period") {
          const allowed = location_type_groups.reduce(
            (accumulator: string[][], current) => {
              if (current.includes(group)) {
                accumulator.push(current);
              }
              return accumulator;
            },
            []
          );

          if (allowed.length === 0) {
            Util.remove(stateFromUrl.Groups, group);
            Util.remove(stateFromUrl.Compare, group);
            if (stateFromUrl.Filter.hasOwnProperty("group")) {
              delete stateFromUrl.Filter[group];
            }
            valid = false;
          } else {
            location_type_groups = allowed;
          }
        }
      });
    }

    const currentPresentation = presentations.find((presentation) => {
      let urlGroups = stateFromUrl.Groups;
      if (stateFromUrl.View !== ViewType.Trend) {
        urlGroups = stateFromUrl.Groups.filter((group) => group !== "Period");
      }
      return Util.equals(presentation.groups.sort(), urlGroups.sort());
    });

    if (!currentPresentation) {
      const defaultPresentation = presentations.find(
        (presentation) => presentation.defaultView !== undefined
      );

      if (!defaultPresentation) {
        stateFromUrl.Groups = this.convertToValueType(presentations[0].groups);
      } else {
        stateFromUrl.Groups = this.convertToValueType(
          defaultPresentation.groups
        );
      }

      return false;
    } else {
      // add default filters
      const currentView = currentPresentation.views.find(
        (view) => view.name === stateFromUrl.View
      );

      if (!!currentView && !!currentView.groups) {
        currentView.groups.forEach((group) => {
          // add default filter values if none exist
          const isPeriodFilterForTrendView =
            stateFromUrl.View === ViewType.Trend && group.name === "Period";
          if (!stateFromUrl.Filter[group.name] && !isPeriodFilterForTrendView) {
            stateFromUrl.Filter[group.name] = group.defaultValues;
            valid = false;
          }
        });
      } else {
        stateFromUrl.View = this.FindDefaultView(presentations);
        return false;
      }
    }

    return valid;
  }

  FindDefaultView(
    presentations: StatsIndicatorLocationPresentation[]
  ): ViewType {
    const defaultPresentation = presentations.find(
      (presentation) => presentation.defaultView !== undefined
    );
    if (!!defaultPresentation) {
      return ViewType[defaultPresentation.defaultView];
    }

    // there is no default view so we can use the first view as default
    return ViewType[presentations[0].views[0].name];
  }

  // TODO implement this later
  validate_filter(stateFromUrl: IndicatorState): boolean {
    let valid = true;

    if (stateFromUrl.Filter !== undefined) {
      const filterKeys = Object.keys(stateFromUrl.Filter);
      filterKeys.forEach((key) => {
        if (
          !(stateFromUrl.Groups.includes(key) || stateFromUrl.Location === key)
        ) {
          delete stateFromUrl.Filter[key];
        }
      });

      // leave just on value in filter for every group
      if (stateFromUrl.View === ViewType.Map) {
        if (stateFromUrl.Groups) {
          stateFromUrl.Groups.forEach((group) => {
            let values = stateFromUrl.Filter[group];
            if (values) {
              if (values.length > 1) {
                values = [values[0]];
                stateFromUrl.Filter[group] = values;
                valid = false;
              }
            }
          });
        }
      }
    }

    return valid;
  }

  // ---- Check that the compare location matches the currently selected location ----
  // Protects against e.g.: location=phn&compare=nsw (occures after location change)
  validate_compare(stateFromUrl: IndicatorState): boolean {
    let valid = true;

    if (stateFromUrl.Compare.length !== 0) {
      let compare = Util.copy(stateFromUrl.Compare);
      if (stateFromUrl.Location === Locations.NSW) {
        compare = Util.remove(compare, stateFromUrl.Location);
      }

      const location_types: string[] = [
        Locations.NSW,
        Locations.PHN,
        Locations.LHD,
        Locations.LGA,
      ];
      // Remove the current location
      location_types.splice(location_types.indexOf(stateFromUrl.Location), 1);

      // For each compare value
      compare.forEach((current) => {
        if (location_types.includes(current)) {
          compare[compare.indexOf(current)] = stateFromUrl.Location;
        }

        if (current === Locations.NSW) {
          // Remove the location from the compare as it is implicit in the groups
          compare = Util.remove(compare, stateFromUrl.Location);
        }

        // remove compare if it is not in the groups and it is not a location
        if (
          stateFromUrl.Groups.find((group) => group == current) === undefined &&
          LocationsArray.find((location) => location == current) === undefined
        ) {
          compare = Util.remove(compare, current);
        }
      });

      if (stateFromUrl.View === ViewType.Map) {
        // Remove the location from the compare as it is implicit in the groups
        // This is a fix for crazy dance issue
        let compareCopy = Util.copy(compare);
        compareCopy = Util.remove(compareCopy, stateFromUrl.Location);
        if (!Util.arraysEqual(stateFromUrl.Groups, compareCopy)) {
          // Add the location to the front of the groups
          stateFromUrl.Groups.unshift(stateFromUrl.Location);
          // Set compare to the groups with location
          compare = Util.copy(stateFromUrl.Groups);
        }
      }

      // Ensure there are no duplicates in the compare
      compare = Util.deduplicate(compare);

      if (!Util.arraysEqual(stateFromUrl.Compare, compare)) {
        stateFromUrl.Compare = compare;
        valid = false;
      }
    }

    return valid;
  }

  navigateToState(state: IndicatorState) {
    const params = this.convertStateToHttpParams(state);
    const url = params.toString();
    this.location.replaceState(`${window.location.pathname}`, url);
    history.pushState({}, "indicator", `indicator?${url}`);
    history.replaceState(null, "indicator", `indicator?${url}`);
  }

  async reloadData() {
    const groups = Util.remove(this.state.Groups, "Period");
    const data = await lastValueFrom(
      this.statsService.getIndicatorLocationGroupsData(
        this.state.Name,
        this.state.Location,
        Util.joinArray(groups),
        this.state.View
      )
    );
    if (data) {
      this.state.Data = data;
    }
  }

  // @todo - this method is incomplete
  convertHttpParamsToState(params: HttpParams): IndicatorState {
    const indicatorState = new IndicatorState();
    if (params.has("location")) {
      indicatorState.Location = params.get("location").trim();
    }
    if (params.has("confidence")) {
      indicatorState.Confidence = params.get("confidence").trim() === "true";
    }
    if (params.has("view")) {
      indicatorState.View = ViewType[params.get("view").trim()];
    }
    if (params.has("name")) {
      indicatorState.Name = params.get("name").trim();
    }
    if (params.has("measure")) {
      indicatorState.Measure = this.JoinAndExcludeDobuleUps(
        params.getAll("measure")
      );
    }
    if (params.has("groups")) {
      if (params.get("groups") === "") {
        indicatorState.Groups = [];
      } else {
        indicatorState.Groups = this.JoinAndExcludeDobuleUps(
          params.getAll("groups")
        );
      }
    }
    if (params.has("compare")) {
      indicatorState.Compare = this.JoinAndExcludeDobuleUps(
        params.getAll("compare")
      );
    }
    if (params.has("filter")) {
      params.getAll("filter").forEach((filter) => {
        const filterArray = filter.split(",");
        const decoded = Util.copyAndDecode(filterArray);
        const key = decoded[0];
        decoded.splice(0, 1);
        indicatorState.Filter[key] = decoded;
      });
    }
    return indicatorState;
  }

  JoinAndExcludeDobuleUps(items: string[]): string[] {
    let result = [];
    if (items) {
      items.forEach((item) => {
        const arr = item.split(",");
        let trimmed = [];
        arr.forEach((itm) => {
          trimmed.push(itm.trim());
        });
        result = result.concat(trimmed);
      });

      result = Util.deduplicate(result);
    }
    return result;
  }

  updateStateFromAnotherState(from: IndicatorState, to: IndicatorState) {
    to.Location = from.Location;
    to.View = from.View;
    to.Name = from.Name;
    to.Measure = from.Measure;
    to.Groups = from.Groups;
    to.Confidence = from.Confidence;
    to.Compare = from.Compare;
    to.Filter = from.Filter;
    to.IndicatorId = from.IndicatorId;
  }

  convertStateToHttpParams(state: IndicatorState): HttpParams {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("name", state.Name);

    if (state.Location !== undefined) {
      httpParams = httpParams.set("location", state.Location);
    }

    if (state.View !== undefined) {
      httpParams = httpParams.set("view", state.View);
    }

    if (state.Measure !== undefined) {
      const measure = state.Measure.join();
      if (measure) {
        httpParams = httpParams.set("measure", measure);
      }
    }

    if (state.Confidence) {
      httpParams = httpParams.set("confidence", "true");
    }

    if (state.Groups !== undefined) {
      const groups = state.Groups.join();
      httpParams = httpParams.append("groups", groups);
    }

    if (state.Compare !== undefined) {
      const compare = state.Compare.join();
      if (compare) {
        httpParams = httpParams.append("compare", compare);
      }
    }

    if (state.Filter !== undefined) {
      Object.keys(state.Filter).forEach((key) => {
        const filter = state.Filter[key];
        //const filterCopy = Util.copy(filter);
        const filterCopy = Util.copyAndEncode(filter);
        filterCopy.unshift(key);
        httpParams = httpParams.append("filter", filterCopy);
      });
    }
    return httpParams;
  }

  formatFilter(filters: string[]) {
    const object = {};

    filters.forEach((current) => {
      const split = current.split(",");
      if (split.length > 1) {
        object[split[0]] = split.slice(1);
      }
    });

    return object;
  }

  async loadGlobalMeta(indicatorName: string) {
    const meta = await lastValueFrom(
      this.statsService.getIndicatorMeta(indicatorName)
    );
    if (meta) {
      this.state.Meta = meta;
    }
  }

  update_group_options() {
    const data = this.state.Data.slice(0);
    this.state.GroupOptions = this.collate_group_options(data);
    this.state.Locations = this.state.GroupOptions[this.state.Location];
    this.state.AllowedGroups = this.buildAllowedGroupsList();
  }

  /*  Filter the data, that is remove all the series data that is not
    selected for display reducing the size of the working data. */
  filterData() {
    const state = this.state;
    let filtered_data = [];

    if (state.GroupOptions !== undefined) {
      if (state.Filter) {
        // check if none of the filter options is available in the group options
        // then add the first group option to the filter
        Object.keys(state.GroupOptions).forEach((key) => {
          if (
            !!state.GroupOptions[key] &&
            !!state.Filter[key] &&
            state.GroupOptions[key].every(
              (g: any) => !state.Filter[key].includes(g)
            )
          ) {
            state.Filter[key].push(state.GroupOptions[key][0]);
          }
        });

        filtered_data = state.Data.reduce((result, record) => {
          let isIncluded = true;
          Object.keys(state.Filter).forEach((key) => {
            isIncluded =
              isIncluded && state.Filter[key].includes(String(record[key]));
          });

          if (isIncluded) {
            result.push(record);
          }
          return result;
        }, []);
      }
    }
    this.state.FilteredData = filtered_data;
  }

  /*  Returns an object with a key for each 'group' that contains an
    array of all possible values as contained in the data.
    Useful for populating list of avaliable options. */
  collate_group_options = (json) => {
    let groupsOptions: any = new Object();

    function merge(object, key, record) {
      if (!object.hasOwnProperty(key)) {
        object[key] = [];
      }
      if (
        record.hasOwnProperty(key) &&
        object[key].indexOf(String(record[key])) === -1
      ) {
        object[key].push(String(record[key]));
      }
    }

    const state = this.state;
    json.forEach((record) => {
      // Create a list of options for the location field
      merge(groupsOptions, state.Location, record);

      // Create a list of options for each group field
      state.Groups.forEach((group) => {
        merge(groupsOptions, group, record);
      });
    });

    for (const property in groupsOptions) {
      const metaGroup = this.state.Meta.groups.find(
        (group) => group.name === property
      );
    }

    return groupsOptions;
  };

  // Returns an array of groups that can be be added for the current view type (trend, snapshot, map, table).
  // Does not include currently selected groups.
  buildAllowedGroupsList(): string[] {
    // Copy the current groups
    let current_groups: string[] = this.state.Groups.slice(0);

    // If the 'group' parameter contains the 'period' property
    if (current_groups.indexOf("Period") !== -1) {
      // Remove the 'period' from the current groups list ('period' is a special case that users cannot add/remove)
      current_groups.splice(current_groups.indexOf("Period"), 1);
    }

    const currentView = this.state.View;
    const presentations = this.state.Meta.locations.find(
      (location) => location.name === this.state.Location
    ).presentations;
    const presentationsThatContainCurrentView = presentations.filter((p) => {
      const views: string[] = p.views.map((v) => v.name);
      let currentViewFound = false;
      views.forEach((v) => {
        if (
          (v === ViewType.Trend && currentView === ViewType.Trend) ||
          (v === ViewType.BarHorizontal &&
            currentView === ViewType.BarHorizontal) ||
          (v === ViewType.Map && currentView === ViewType.Map) ||
          (v === ViewType.Table && currentView === ViewType.Table)
        ) {
          currentViewFound = true;
        }
      });
      return currentViewFound;
    });
    let groups: string[][] = presentationsThatContainCurrentView.map(
      (viewSet) => viewSet.groups
    );
    let allowed_groups: string[] = groups.reduce(
      (accumulator: string[], current: string[]) => {
        let count = 0;

        for (let index = 0; index < current_groups.length; index++) {
          count += current.indexOf(current_groups[index]) !== -1 ? 1 : 0;
        }

        if (count === current_groups.length) {
          accumulator = accumulator.concat(current);
        }

        return accumulator;
      },
      []
    );

    // deduplicate
    // allowed_groups = Util.deduplicate(allowed_groups);
    allowed_groups = allowed_groups.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });

    // Remove groups that are allowed buyt have already been added
    allowed_groups = allowed_groups.reduce((accumulator, element) => {
      if (current_groups.indexOf(element) === -1) {
        accumulator.push(element);
      }
      return accumulator;
    }, []);

    return Array.from(allowed_groups);
  }

  private convertToValueType(value: any): any {
    return JSON.parse(JSON.stringify(value));
  }
}
