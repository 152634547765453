import { Injectable } from '@angular/core';
import { Util } from "../shared/util";

@Injectable({
  providedIn: 'root'
})
export class MeasureService {

 public get_filtered_measures(state : any) {
    var measures = Util.deepCopy(state.Meta.measures);
    var groups = Util.deepCopy(state.Groups);

    if(groups.indexOf("Period") !== -1)
    {
      measures.forEach(m => {
        if (!!m.groups && m.groups.indexOf("Period") === -1) {
          m.groups = m.groups + ",Period";
        }
        else if (!m.groups) {
          m.groups = "Period";
        }
      });
    }


    if (!!state.Location && !!state.Location.length && state.Location !== "NSW" && !!groups && groups.indexOf(state.Location) === -1) {
      groups.push(state.Location);
    }

    var sortedGroupsAppliedOnTheUI = this.sort_groups_alphabetically(groups);
    var groupMeasures = measures.filter(m => !!groups && this.sort_groups_alphabetically(this.split_measure_groups_safely(m.groups)) === sortedGroupsAppliedOnTheUI);
    var filteredMeasures = !!groupMeasures && !!groupMeasures.length ? groupMeasures : measures;
    return filteredMeasures;
  }

  sort_groups_alphabetically(groups) {
    if (!!groups) {
      return groups.sort((a, b) => a.localeCompare(b)).join();
    }
    return "";
  }

  split_measure_groups_safely(measureGroups) {
    if (!!measureGroups) {
      if (measureGroups.indexOf(",") !== -1) {
        return measureGroups.split(",");
      }
      else {
        return [measureGroups];
      }
    }
    return [];
  }
}
