// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: calc(2 * var(--xs-gutter));
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .card-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: calc(2 * var(--sm-gutter));
  }
}

@media (min-width: 768px) {
  .card-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: calc(2 * var(--md-gutter));
  }
}

@media (min-width: 992px) {
  .card-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: calc(2 * var(--lg-gutter));
  }
}

@media (min-width: 1200px) {
  .card-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: calc(2 * var(--xl-gutter));
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/publication/publication.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gDAAgD;EAChD,+BAA+B;EAC/B,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,gDAAgD;IAChD,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,gDAAgD;IAChD,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,gDAAgD;IAChD,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,gDAAgD;IAChD,+BAA+B;EACjC;AACF","sourcesContent":[".card-container {\r\n  display: grid;\r\n  grid-template-columns: repeat(1, minmax(0, 1fr));\r\n  gap: calc(2 * var(--xs-gutter));\r\n  margin-top: 2rem;\r\n  margin-bottom: 2rem;\r\n}\r\n\r\n@media (min-width: 576px) {\r\n  .card-container {\r\n    grid-template-columns: repeat(2, minmax(0, 1fr));\r\n    gap: calc(2 * var(--sm-gutter));\r\n  }\r\n}\r\n\r\n@media (min-width: 768px) {\r\n  .card-container {\r\n    grid-template-columns: repeat(3, minmax(0, 1fr));\r\n    gap: calc(2 * var(--md-gutter));\r\n  }\r\n}\r\n\r\n@media (min-width: 992px) {\r\n  .card-container {\r\n    grid-template-columns: repeat(4, minmax(0, 1fr));\r\n    gap: calc(2 * var(--lg-gutter));\r\n  }\r\n}\r\n\r\n@media (min-width: 1200px) {\r\n  .card-container {\r\n    grid-template-columns: repeat(4, minmax(0, 1fr));\r\n    gap: calc(2 * var(--xl-gutter));\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
