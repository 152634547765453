// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

#spinner {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(241, 239, 239, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.spinner-border {
  z-index: 999;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 3rem;
  height: 3rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  font-size: 24px;
  animation: spinner-border 1.75s linear infinite;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/loader/loader.component.css"],"names":[],"mappings":"AAAA;EACE;IAEE,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe,EAAE,mCAAmC;EACpD,aAAa,EAAE,sBAAsB;EACrC,WAAW,EAAE,sCAAsC;EACnD,YAAY,EAAE,uCAAuC;EACrD,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,0CAA0C,EAAE,kCAAkC;EAC9E,UAAU,EAAE,oFAAoF;EAChG,eAAe,EAAE,2BAA2B;AAC9C;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,iCAAiC;EACjC,+BAA+B;EAC/B,kBAAkB;EAClB,eAAe;EAEf,+CAA+C;AACjD","sourcesContent":["@keyframes spinner-border {\r\n  to {\r\n    -webkit-transform: rotate(360deg);\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n#spinner {\r\n  position: fixed; /* Sit on top of the page content */\r\n  display: none; /* Hidden by default */\r\n  width: 100%; /* Full width (cover the whole page) */\r\n  height: 100%; /* Full height (cover the whole page) */\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background-color: rgba(241, 239, 239, 0.5); /* Black background with opacity */\r\n  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */\r\n  cursor: pointer; /* Add a pointer on hover */\r\n}\r\n\r\n.spinner-border {\r\n  z-index: 999;\r\n  display: inline-block;\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  margin-top: -50px;\r\n  margin-left: -50px;\r\n  width: 3rem;\r\n  height: 3rem;\r\n  vertical-align: text-bottom;\r\n  border: 0.25em solid currentColor;\r\n  border-right-color: transparent;\r\n  border-radius: 50%;\r\n  font-size: 24px;\r\n  -webkit-animation: spinner-border 1.75s linear infinite;\r\n  animation: spinner-border 1.75s linear infinite;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
