import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor() { }

  downloadCSV(csv, filename) {
    let csvFile: Blob;
    let downloadLink;

    csv = "\ufeff" + csv; //Forcing UTF-8 character set

    // CSV file
    csvFile = new Blob([csv], {type: "text/csv"});

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
  }

  exportTableToCSV(html) {
    let doc = new DOMParser().parseFromString(html, "text/xml");
    let csv = [];
    let rows = doc.querySelectorAll("tr");

    for (let i = 0; i < rows.length; i++) {
      let row = [], cols = rows[i].querySelectorAll("td, th");

      for (let j = 0; j < cols.length; j++) {
        row.push(`"${cols[j].innerHTML}"`);
      }

      csv.push(row.join(","));
    }

    return csv.join("\n");
  }

}
