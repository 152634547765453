import { Injectable } from '@angular/core';
import * as _ from "underscore";
import { DecimalPipe } from "@angular/common";
import { Util } from './util';

@Injectable({
    providedIn: 'root'
})
export class FormatService {

    constructor(private decimalPipe: DecimalPipe) {
    }

    public decimalFormatting(data: string): string {
        let formattedValue = data;

        if (Util.checkIfInteger(data)) {
            formattedValue = this.decimalPipe.transform(
                data,
                "1.0-0"
            );
        } else {
            formattedValue = this.decimalPipe.transform(
                data,
                "1.1-1"
            );
        }
        return formattedValue;
    }

}
