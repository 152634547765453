// 4 groups of 4 hsl colours
export const PALETTE = [[
    [230.232558139534, 50, 72.5686274509804],
    [225.176470588235, 43.5897435897435, 38.235294117647],
    [114.193548387096, 44.927536231884, 72.9411764705882],
    [130.220183486238, 100, 31.3725490196078],
], [
    [28.2178217821782, 96.1904761904761, 58.8235294117647],
    [22.5773195876288, 100, 38.0392156862745],
    [307.2, 68.8073394495412, 78.6274509803921],
    [300.666666666666, 100, 17.6470588235294],
], [
    [182.608695652173, 60.1340206185566, 50.9803921568627],
    [188, 100, 23.5294117647058],
    [351.683168316831, 100, 80.1960784313725],
    [348.529411764705, 81.6, 49.0196078431372],
], [
    [45, 100, 70],
    [65.4520547945205, 100, 42.9411764705882],
    [0, 0, 38.8235294117647],
    [0, 0, 14.5098039215686],
]];

// This palette essentially makes each colour a group of their own to spread out the colours more evenly and to reduce chance of duplicates
export const PALETTE2 = [
  [[230.232558139534, 50, 72.5686274509804]],
  [[225.176470588235, 43.5897435897435, 38.235294117647]],
  [[114.193548387096, 44.927536231884, 72.9411764705882]],
  [[130.220183486238, 100, 31.3725490196078]],
  [[28.2178217821782, 96.1904761904761, 58.8235294117647]],
  [[22.5773195876288, 100, 38.0392156862745]],
  [[307.2, 68.8073394495412, 78.6274509803921]],
  [[300.666666666666, 100, 17.6470588235294]],
  [[182.608695652173, 60.1340206185566, 50.9803921568627]],
  [[188, 100, 23.5294117647058]],
  [[351.683168316831, 100, 80.1960784313725]],
  [[348.529411764705, 81.6, 49.0196078431372]],
  [[45, 100, 70]],
  [[65.4520547945205, 100, 42.9411764705882]],
  [[0, 0, 38.8235294117647]],
  [[0, 0, 14.5098039215686]],
];

export class FacetLayout  {

    CLASS = 'facet-layout';

    BREAKPOINT_SM = 600;      // px
    BREAKPOINT_MD = 960;      // px
    FACET_GAP = 24;           // px
    last_width;
    last_height;
    element;


    // @todo remove the need for the element (parent) to be passed, detect additiion to dom then apply listener

    constructor() {
        // this.element = $(options.element);
    }


    set_grid_sizes50x50(facet_count) {
        // console.log('facet_count',facet_count);
        let grid_template_columns_style = '100%';

        if (facet_count > 1) {
            const columns = this.get_columns(facet_count);
            if (columns > 1) {
                let [primary_width, secondary_width] = (this.calculate_column_widths50x50.bind(this))(2);
                grid_template_columns_style = `${primary_width}px repeat(1, ${secondary_width}px)`;
            }
        }

        return grid_template_columns_style;

        // console.log('grid-template-columns', grid_template_columns);

        // Set the grid-template-columns value
        // $(`.${this.CLASS}`).css( 'grid-template-columns', grid_template_columns);
    }

    calculate_column_widths50x50(columns) {
        // console.log('calculate_column_widths B');
		let avaliable_space = this.get_avaliable_width(columns);

		return [
			avaliable_space / columns,
			avaliable_space / columns,
		];
	}



    set_grid_sizes(facet_count) {
        // console.log('facet_count',facet_count);
        let grid_template_columns_style = '100%';

        if (facet_count > 1) {
            let columns = this.get_columns(facet_count);
      
            if (columns > 1) {
              if (this.calculate_column_widths !== undefined) {
                let [
                  primary_width,
                  secondary_width,
                ] = this.calculate_column_widths.bind(this)(columns);
                grid_template_columns_style = `${primary_width}px repeat(${
                  columns - 1
                }, ${secondary_width}px)`;
              } else {
                grid_template_columns_style = `repeat(${columns}, 1fr)`;
              }
            }
          }
      
        return grid_template_columns_style;

        // console.log('grid-template-columns', grid_template_columns);

        // Set the grid-template-columns value
        // $(`.${this.CLASS}`).css( 'grid-template-columns', grid_template_columns);
    }

    get_columns(facet_count) {
        let grid_width = this.get_grid_width();

        return grid_width < this.BREAKPOINT_SM ?
            1 :
            grid_width < this.BREAKPOINT_MD ?
                Math.min(2, facet_count) :
                Math.min(3, facet_count)
        ;
    }

    get_grid_width(): number {
        return 0;
    }

    get_avaliable_width(columns) :any{
        // console.log('get_avaliable_width B');
        // Calculate the total horizontal space required for column gaps
        let column_space = (columns - 1) * this.FACET_GAP;

        return this.get_grid_width() - column_space;
    }

	calculate_column_widths(columns) {
        // console.log('calculate_column_widths B');
		let avaliable_space = this.get_avaliable_width(columns);

		return [
			avaliable_space / columns,
			avaliable_space / columns,
		];
	}

    // add_resize_listener(element, listener) {
    //     let resize_observer = new ResizeObserver(entries => {
    //         if (entries.length && (
    //                 entries[0].contentRect.width !== this.last_width ||
    //                 entries[0].contentRect.height !== this.last_height
    //             )
    //         ) {
    //             listener();
    //             this.last_width = entries[0].contentRect.width;
    //             this.last_height = entries[0].contentRect.height;
    //         }
    //     });

    //     resize_observer.observe(element[0]);
    // }


}
