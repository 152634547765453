// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.checkbox-accordian--toggle {
  display: inline-block;
  transform: rotate(0);
  transition: transform 300ms;
}

.checkbox-accordian.open .checkbox-accordian--toggle {
  transform: rotate(180deg);
  transition: transform 300ms;
}

.checkbox-accordian {
  margin: 16px 0;
}

.checkbox-accordian:first-child {
  margin-top: 0;
}

.checkbox-accordian:last-child {
  margin-bottom: 0;
}

.checkbox-accordian > * {
  vertical-align: middle;
}

.checkbox-accordian > label.checkbox {
  display: inline-block;
  margin: 0;
}

.checkbox-accordian--toggle {
  height: 20px;
  width: 20px;
}

.checkbox-accordian--toggle i {
  font-size: 20px;
}

.checkbox-accordian--body label.checkbox {
  display: block;
  margin: 16px 0;
}

.checkbox-accordian--body {
  margin: 0 0 0 32px;
}
`, "",{"version":3,"sources":["webpack://./src/app/uilib/checkbox-accordion/checkbox-accordion.component.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["\r\n.checkbox-accordian--toggle {\r\n  display: inline-block;\r\n  transform: rotate(0);\r\n  transition: transform 300ms;\r\n}\r\n\r\n.checkbox-accordian.open .checkbox-accordian--toggle {\r\n  transform: rotate(180deg);\r\n  transition: transform 300ms;\r\n}\r\n\r\n.checkbox-accordian {\r\n  margin: 16px 0;\r\n}\r\n\r\n.checkbox-accordian:first-child {\r\n  margin-top: 0;\r\n}\r\n\r\n.checkbox-accordian:last-child {\r\n  margin-bottom: 0;\r\n}\r\n\r\n.checkbox-accordian > * {\r\n  vertical-align: middle;\r\n}\r\n\r\n.checkbox-accordian > label.checkbox {\r\n  display: inline-block;\r\n  margin: 0;\r\n}\r\n\r\n.checkbox-accordian--toggle {\r\n  height: 20px;\r\n  width: 20px;\r\n}\r\n\r\n.checkbox-accordian--toggle i {\r\n  font-size: 20px;\r\n}\r\n\r\n.checkbox-accordian--body label.checkbox {\r\n  display: block;\r\n  margin: 16px 0;\r\n}\r\n\r\n.checkbox-accordian--body {\r\n  margin: 0 0 0 32px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
