import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from '../app-breadcrumb/breadcrumb.service';
import {RelatedResource} from '../shared/Models/related-resource.model';
import {HsResource} from './healthstatsplus-resource';
import { MetadataService } from '../metadata.service';

@Component({
  selector: 'app-healthstatsplus',
  templateUrl: './healthstatsplus.component.html',
  styleUrls: ['./healthstatsplus.component.css']
})
export class HealthstatsplusComponent implements OnInit {
  resources: RelatedResource[] = [
    {
      title: HsResource.Snapshot.title,
      url: `/healthstatsplus/Snapshot`,
      text: HsResource.Snapshot.cardDescription,
    },
    {
      title: HsResource.DataReport.title,
      url: `/healthstatsplus/DataReport`,
      text: HsResource.DataReport.cardDescription,
    },
    {
      title: HsResource.HealthStatsMethod.title,
      url: `/healthstatsplus/HealthStatsMethod`,
      text: HsResource.HealthStatsMethod.cardDescription,
    },
    {
      title: HsResource.DataResource.title,
      url: `/healthstatsplus/DataResource`,
      text: HsResource.DataResource.cardDescription,
    },
    {
      title: HsResource.ArchivedDataReport.title,
      url: `/healthstatsplus/ArchivedDataReport`,
      text: HsResource.ArchivedDataReport.cardDescription,
    },
  ];
  title: string;

  constructor(private breadcrumbService: BreadcrumbService,
    private metadataService: MetadataService) {
      this.title = "HealthStats PLUS";
  }

  ngOnInit() {
    this.breadcrumbService.updateBreadCrumbs([]);
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: this.title,
        description: 'Inform your analysis by browsing existing reports and resources'
      });
    }
  }
}
