import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewChecked,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";
import { Util } from "src/app/shared/util";
import { IndicatorColourBank } from "../../colour-bank";
import { FacetsLib } from "../../facets-lib";
import { IndicatorState } from "../../indicator.state";
import { LegendComponent } from "../../legend/legend.component";
import { Chart } from "../chartData";
import ResizeObserver from "resize-observer-polyfill";
import { VisualisationDownloadFormat } from "../../constraints";
import { DocumentExportService } from "../../../services/documentexport.service";
import { FacetLayout } from "../faceted-layout";
import { PlotlyGraphView } from "../plotly-graph-view";
import { PlotlyServiceExt } from "../../../services/plotly-service-extension";
import {MeasureService} from "../../../services/measure.service";

const enum ChartDrawingStageEnum {
  Created,
  Initialised,
  StateUpdated,
  DrawLegend,
  DrawingFirstSetOfCharts,
  DrawingSecondSetOfCharts,
}

const YAXIS_HEADROOM = 1.05; // factor
const Y_AXIS_OFFSET = 64; // px
const MIN_HEIGHT = 200; // Minimum height of chart
const CHARTVIEW_DISPLAY_PADDING = 16; // px

@Component({
  selector: "(app-trend-view)",
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: "./trend-view.component.html",
  styleUrls: ["./trend-view.component.css"],
})
export class TrendViewComponent extends PlotlyGraphView
  implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild("canvas", {static: true}) canvas: ElementRef;
  @ViewChild(LegendComponent, {static: true})
  private legend: LegendComponent;

  state: IndicatorState;
  facetedPlotData: any;
  allCharts: Chart[];
  charts: Chart[];
  config: any;
  elementRef: ElementRef;
  facetLayoutStyle: string;
  canvasWidth: number;
  canvasHeight: number;
  observer: ResizeObserver;
  chartDrawingStage: ChartDrawingStageEnum = ChartDrawingStageEnum.Created;
  showLegend: boolean = true;
  measureService : MeasureService;

  constructor(element: ElementRef, private cdRef: ChangeDetectorRef, documentExportService: DocumentExportService, plotlyServiceExt: PlotlyServiceExt) {
    super(documentExportService, plotlyServiceExt);
    this.elementRef = element;
    this.measureService = new MeasureService();
  }

  public exportAs(visualisationDownloadFormat: VisualisationDownloadFormat, source: string, fileName: string): any {
    if (visualisationDownloadFormat == VisualisationDownloadFormat.PNG) {
      super.exportAsPNG(fileName)
    }
    else {
      this.allCharts.forEach(chart => {
        const title = super.CreateChartTitle(this.state.Meta.title, this.state.ChartTitle, chart);
        super.exportAsSVG(chart.Id, title, !!this.facetedPlotData[0] ? this.facetedPlotData[0].traces : null, source, `${fileName}-${chart.Id}`);
      });
    }
  }

  memoriseCanvasSize(width: number, height: number) {
    if (width !== undefined && height !== undefined) {
      width = Math.floor(width);
      height = Math.floor(height);
      if (
        width !== 0 &&
        height != 0 &&
        (width !== this.canvasWidth || height !== this.canvasHeight)
      ) {
        this.canvasWidth = width;
        this.canvasHeight = height;
        this.onCanvasSizeChange();
      }
    }
  }

  ngOnInit() {
    this.memoriseCanvasSize(
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight
    );

    this.facetLayoutStyle = "100%";
    this.chartDrawingStage = ChartDrawingStageEnum.Initialised;
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.canvas.nativeElement);
    }
  }

  ngAfterViewChecked() {
    this.memoriseCanvasSize(
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight
    );
  }

  get_grid_width(): number {
    return this.canvasWidth - CHARTVIEW_DISPLAY_PADDING * 2;
  }

  get_grid_heght(): number {
    return this.canvasHeight;
  }

  onCanvasSizeChange() {
    if (this.chartDrawingStage !== ChartDrawingStageEnum.Created) {
      this.drawFirstSetOfCharts();
    }
  }

  onStateUpdated(newState: IndicatorState) {
    this.chartDrawingStage = ChartDrawingStageEnum.StateUpdated;
    this.state = newState;
    this.drawLegend();
    this.drawFirstSetOfCharts();
  }

  drawFirstSetOfCharts() {
    if (!!this.facetedPlotData) {
      this.allCharts = [];
      this.chartDrawingStage = ChartDrawingStageEnum.DrawingFirstSetOfCharts;
      let facet_count = this.facetedPlotData.length;
      this.config = this.get_config();

      // Get the layouts, primary layout is used for plots at the start of each row, secondary layout is for subsequent plots
      let [layout_primary, layout_secondary] = this.get_plot_layouts();

      this.facetLayoutStyle = this.set_grid_sizes(facet_count);
      const numberOfColumns = this.get_columns(facet_count);

      const rows = Math.ceil(facet_count / numberOfColumns);
      const total_inter_row_gap = (rows - 1) * 2 * this.FACET_GAP;

      // The greater of the min height, or the canvas height (less margins)
      // divided by the number of rows.
      const height = Math.max(
        MIN_HEIGHT,
        (this.canvasHeight -
          CHARTVIEW_DISPLAY_PADDING * 2 -
          total_inter_row_gap) /
        rows
      );

      this.facetedPlotData.forEach((current, index) => {
        const layout =
          index % numberOfColumns === 0
            ? Util.deepCopy(layout_primary)
            : Util.deepCopy(layout_secondary);

        if (this.facetedPlotData.length > 1) {
          //layout.title = this.facetedPlotData[index].facet_title;
          layout.title = {
            //text: this.makeTwoLines(this.facetedPlotData[index].facet_title),
            text: !!this.facetedPlotData[index] ? this.facetedPlotData[index].facet_title : '',
            xanchor: "auto",
            yanchor: "bottom",
            x: 0,
            xref: "paper",
            yref: "paper",
            font: {
              size: 15,
            },
          };
        }
        const chart = new Chart(
          layout,
          !!this.facetedPlotData[index] ? this.facetedPlotData[index].traces : null,
          height
        );
        chart.Id = `trend-view-chart-${index + 1}`;
        this.allCharts.push(chart);
      });

      // draw
      const tmpCharts: Chart[] = [];
      this.charts = [];
      for (let chart of this.allCharts) {
        tmpCharts.push(chart);
      }
      this.charts = tmpCharts;
      this.cdRef.detectChanges();
    }
  }

  drawLegend() {
    this.buildFacetedPlotData();

    if (!!this.facetedPlotData) {
      this.showLegend =
        Array.isArray(this.facetedPlotData) &&
        this.facetedPlotData.length > 0 &&
        !!this.facetedPlotData[0] &&
        !!this.facetedPlotData[0].traces &&
        this.facetedPlotData[0].traces.length > 1;

      if (this.legend && !!this.facetedPlotData[0]) {
        this.legend.onStateUpdated(this.facetedPlotData[0].traces);
      }

      this.chartDrawingStage = ChartDrawingStageEnum.DrawLegend;
    }
  }

  buildFacetedPlotData() {
    let plot_data = [];
    let faceted_plot_data = [];
    // Init the colour bank which provides the grouped colours to apply to traces
    let colour_bank = new IndicatorColourBank();

    // Init variable used to track which colours are assigned to which traces
    let trace_colours = {};

    this.state.FilteredData.forEach((record) => {
      let trace_id = this.get_trace_id(record);
      let facet_id = FacetsLib.get_facet_id(record, this.state);
      let facet_title = FacetsLib.get_facet_title(record, this.state);

      let facet = faceted_plot_data.find(
        (element) => element.facet_id === facet_id
      );

      if (facet === undefined) {
        facet = {
          facet_id,
          facet_title,
          traces: [],
        };
        faceted_plot_data.push(facet);
      }

      // Get any existing trace to
      let trace = plot_data.find((element) => element.id === trace_id);

      // If there was no trace for this data record then create one
      var filteredMeasures = this.measureService.get_filtered_measures(this.state);
      let measure = filteredMeasures.find(
        (x) => x.name == this.state.Measure[0]
      );
      const yValue = record[this.state.Measure[0]];
      let decimalPoints = Util.checkIfInteger(yValue) === true ? '0' : '1';

      let hoverTemplate = "<b>%{text[0]}</b><br>" + measure.description + ": %{y:." + decimalPoints + "f}<br>";
      const confidenceInterval = this.state.ConfidenceIntervals[measure.name];

      if (trace === undefined) {
        trace = {
          x: [],
          y: [],
          mode: "lines+markers",
          text: [],
        };

        trace.id = trace_id;
        trace.showlegend = false;
        trace.facet_id = facet_id;
        plot_data.push(trace);
        facet.traces.push(trace);
      }

      // ---- Add Legend ----
      let legend_group = this.get_legend_group(record);
      let legend_name = this.get_legend_name(record);
      let legend_id = [legend_group, legend_name].join("--");

      // Add the data point
      trace.x.push(record.Period);
      trace.y.push(record[this.state.Measure[0]]);
      let lowerCiValue, upperCiValue;
      if (!!confidenceInterval) {
        lowerCiValue = record[confidenceInterval.lower.name];
        upperCiValue = record[confidenceInterval.upper.name];

        if (this.state.Confidence) {
          const lowerCiDecimalPoints = Util.checkIfInteger(lowerCiValue) === true ? '0' : '1';
          const upperCiDecimalPoints = Util.checkIfInteger(upperCiValue) === true ? '0' : '1';

          hoverTemplate += `${confidenceInterval.lower.description}: %{text[1]:.` + lowerCiDecimalPoints + `f}`;
          hoverTemplate += `<br>`;
          hoverTemplate += `${confidenceInterval.upper.description}: %{text[2]:.` + upperCiDecimalPoints + `f}`;
        }
      }
      trace.text.push([legend_name, lowerCiValue, upperCiValue]);

      trace.hovertemplate = hoverTemplate;

      let colour = trace_colours[legend_id];
      if (colour === undefined) {
        colour = colour_bank.take_colour_data(legend_group);
        trace_colours[legend_id] = colour;
      }

      //trace.name = legend_name;
      trace.name = "";
      trace.legendgroup = legend_group;
      trace.marker = { color: colour_bank.format_as_hsl(colour) };

      // If showing confidence intervals
      if (this.state.Confidence && !!confidenceInterval) {
        let upperLimitTrace = plot_data.find(
          (element) => element.id === trace_id + "-upper-limit-trace"
        );
        if (upperLimitTrace === undefined) {
          upperLimitTrace = {
            x: [],
            y: [],
            fill: "tonexty",
            fillcolor: colour_bank.format_as_hsla(colour, 0.1),
            line: { color: "transparent" },
            mode: "lines",
            //hovertemplate: ''
            hoverinfo: "skip",
          };
          upperLimitTrace.id = trace_id + "-upper-limit-trace";
          upperLimitTrace.showlegend = false;
          upperLimitTrace.facet_id = facet_id;
          upperLimitTrace.name = "Confidence";
          plot_data.push(upperLimitTrace);
          facet.traces.push(upperLimitTrace);
        }
        upperLimitTrace.x.push(record.Period);
        upperLimitTrace.y.push(upperCiValue);

        let lowerLimitTrace = plot_data.find(
          (element) => element.id === trace_id + "-lower-limit-trace"
        );
        if (lowerLimitTrace === undefined) {
          lowerLimitTrace = {
            x: [],
            y: [],
            fill: "tonexty",
            fillcolor: colour_bank.format_as_hsla(colour, 0.3),
            line: { color: "transparent" },
            mode: "lines",
            hoverinfo: "skip",
          };
          lowerLimitTrace.id = trace_id + "-lower-limit-trace";
          lowerLimitTrace.showlegend = false;
          lowerLimitTrace.facet_id = facet_id;
          lowerLimitTrace.name = "Confidence";
          plot_data.push(lowerLimitTrace);
          facet.traces.push(lowerLimitTrace);
        }
        lowerLimitTrace.x.push(record.Period);
        lowerLimitTrace.y.push(lowerCiValue);
      }
    });
    this.facetedPlotData = faceted_plot_data;
  }

  calculate_column_widths(columns) {
    let avaliable_space = this.get_avaliable_width(columns);
    return [
      Math.round(
        avaliable_space / columns + Y_AXIS_OFFSET - Y_AXIS_OFFSET / columns
      ),
      Math.round(avaliable_space / columns - Y_AXIS_OFFSET / columns),
    ];
  }

  get_config() {
    return {
      responsive: true,
      modeBarButtonsToRemove: [
        "toImage",
        "pan2d",
        "select2d",
        "lasso2d",
        "zoomIn2d",
        "zoomOut2d",
        "autoScale2d",
        "hoverClosestCartesian",
        "hoverCompareCartesian",
      ],
      displaylogo: false,
    };
  }

  get_plot_layouts() {
    const state = this.state;
    const max = this.max_yaxis_value() * YAXIS_HEADROOM;

    const layout1 = {
      margin: { l: 64, r: 0, t: 64, pad: 4 },
      xaxis: {
        type: "category",
        fixedrange: true,
      },
      yaxis: {
        autorange: false,
        range: [0, max],
        fixedrange: true,
      },
      hovermode: "closest",
    };

    let layout_primary = JSON.parse(JSON.stringify(layout1));

    if (state.Meta.measures !== undefined) {
      var filteredMeasures = this.measureService.get_filtered_measures(this.state);
      layout_primary.yaxis.title = {
        text: filteredMeasures.find(
          (element) => element.name === state.Measure[0]
        ).description,
        standoff: 8,
      };
    }
    const layout2 = {
      margin: { l: 64, r: 0, t: 64, pad: 4 },
      xaxis: {
        type: "category",
        fixedrange: true,
      },
      yaxis: {
        fixedrange: true,
        autorange: false,
        range: [0, max],
      },
      hovermode: "closest",
    };

    let layout_secondary = JSON.parse(JSON.stringify(layout2));
    layout_secondary.yaxis.showticklabels = false;
    layout_secondary.margin.l = 0;

    return [layout_primary, layout_secondary];
  }

  max_yaxis_value() {
    const faceted_plot_data = this.facetedPlotData;

    return faceted_plot_data.reduce((accumulator, facet) => {
      return Math.max(
        accumulator,
        facet.traces.reduce((accumulator, trace) => {
          return Math.max(accumulator, ...trace.y);
        }, 0)
      );
    }, 0);
  }

  get_trace_id(record) {
    const state = this.state;

    let trace_id = [state.Location, ...state.Groups]
      .reduce(
        (result, group) => {
          result.push(record[group]);
          return result;
        },
        [record[state.Location]]
      )
      .join("--");

    trace_id = Util.slugify(trace_id);

    return trace_id;
  }

  get_legend_name(record) {
    const compare = this.state.Compare;

    let primary_compare = compare.slice(0).shift();

    let legend_name = compare
      .reduce((accumulator, current) => {
        if (compare.length === 1 || current !== primary_compare) {
          accumulator.push(record[current]);
        }
        return accumulator;
      }, [])
      .join(" - ");

    if (legend_name === "") {
      legend_name = "NSW";
    }
    return legend_name;
  }

  get_legend_group(record) {
    const compare = this.state.Compare;

    let primary_compare = compare.slice(0).shift();
    let legend_group = compare.length > 1 ? record[primary_compare] : "-";
    return legend_group;
  }
}
