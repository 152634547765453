import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((val) => {
      let spinner = document.getElementById('spinner');
      if (spinner !== null){
        spinner.style.display = val === true ? 'block' : 'none';
      }
    });
  }
}
