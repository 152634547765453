import { ViewType } from "src/app/indicator/constraints";
export class StatsIndicator {
  name: string;
  title: string;
  releaseDate?: string;
  locations: StatsIndicatorLocation[];
  groups: StatsIndicatorGroup[];
  measures: StatsIndicatorMeasure[];
}

export class StatsIndicatorLocation {
  name: string;
  label: string;
  aggregates: string;
  presentations: StatsIndicatorLocationPresentation[];
}

export class StatsIndicatorLocationPresentation {
  groups: string[];
  views: StatsIndicatorLocationPresentationView[];
  defaultView?: ViewType;
}

export class StatsIndicatorLocationPresentationView {
  name: string;
  parameters: {};
  defaultMeasure: string;
  groups: DefaultGroupValues[];
}

export class DefaultGroupValues {
  name: string;
  defaultValues: string[];
}

export class StatsIndicatorGroup {
  name: string;
  label: string;
  aggregates: string;
  values: string[]; // FixSort
}

export class StatsIndicatorMeasure {
  name: string;
  label: string;
  description: string;
  ci: boolean;
  groups: string;
}

export class ConfidenceInterval {
  lower: StatsIndicatorMeasure;
  upper: StatsIndicatorMeasure;
}

export class IndicatorState {
  Name: string;
  Location: string;
  View: ViewType;
  Groups: string[];

  Filter: object;
  ChartTitle: string;
  Source: string;
  Locations: string[];
  GroupOptions: {};
  AllowedGroups: string[];

  // facets
  FacetedPlotData: any;

  Measure: string[];
  Compare: string[];
  Confidence: boolean;
  Meta: StatsIndicator;
  Data: any;
  FilteredData: any[];
  IndicatorId: string;
  ConfidenceIntervals: { [name: string]: ConfidenceInterval };

  // TODO add filtered data and data for faceted chart
  constructor() {
    this.ChartTitle = "";
    this.View = null;
    this.Location = null;
    this.Locations = [];
    this.Groups = [];
    this.GroupOptions = {};
    this.AllowedGroups = [];
    this.Filter = {};
    this.Measure = [];
    this.Compare = [];
  }
}
