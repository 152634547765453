// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  padding: 5px 16px;
  background-color: #d7153a;
  color: white;
  font-family: FiraSans-Regular, sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  margin: 4px auto;
  margin-bottom: 8px;
}

  .notification span {
    display: flex;
    align-items: center;
  }

  .notification svg {
    margin-right: 8px;
  }

  .notification a {
    font-size: 20px;
    color: white;
    text-decoration: none;
    border-bottom: 1px solid var(--text-color);
    ;
  }

    .notification a:hover {
      position: relative;
      border-bottom: 2px solid white;
      top: 1px;
    }

@media (min-width: 992px) {
  .nsw-container {
    max-width: 1400px;
    padding-left: var(--xl-margin);
    padding-right: var(--xl-margin);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/uilib/notification/notification.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,yCAAyC;EACzC,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;EAEE;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,qBAAqB;IACrB,0CAA0C;;EAE5C;;IAEE;MACE,kBAAkB;MAClB,8BAA8B;MAC9B,QAAQ;IACV;;AAEJ;EACE;IACE,iBAAiB;IACjB,8BAA8B;IAC9B,+BAA+B;EACjC;AACF","sourcesContent":[".notification {\r\n  padding: 5px 16px;\r\n  background-color: #d7153a;\r\n  color: white;\r\n  font-family: FiraSans-Regular, sans-serif;\r\n  font-size: 20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  width: 100%;\r\n  border-radius: 4px;\r\n  margin: 4px auto;\r\n  margin-bottom: 8px;\r\n}\r\n\r\n  .notification span {\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n  .notification svg {\r\n    margin-right: 8px;\r\n  }\r\n\r\n  .notification a {\r\n    font-size: 20px;\r\n    color: white;\r\n    text-decoration: none;\r\n    border-bottom: 1px solid var(--text-color);\r\n    ;\r\n  }\r\n\r\n    .notification a:hover {\r\n      position: relative;\r\n      border-bottom: 2px solid white;\r\n      top: 1px;\r\n    }\r\n\r\n@media (min-width: 992px) {\r\n  .nsw-container {\r\n    max-width: 1400px;\r\n    padding-left: var(--xl-margin);\r\n    padding-right: var(--xl-margin);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
