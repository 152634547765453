import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { LoaderService } from "../services/loader.service";
import { ToastrService } from "ngx-toastr";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(
    public loaderService: LoaderService,
    private router: Router,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = req.url;
    const isBrowser = isPlatformBrowser(this.platformId);
    if (!isBrowser) console.log(`Server load interceptor request url: ${url}. `);
    this.loaderService.registerRequestUrl(url);
    return next.handle(req).pipe(
      catchError((error) => {
        if (isBrowser) {
          if (
            error.status === 404 &&
            ((this.router.url.indexOf("/indicator?name=") > -1)||
            (this.router.url.indexOf("/topic-overview") > -1))
          ) {
            this.router.navigateByUrl("/notfound");
          } else {
            this.showError("Page loading failed - please refresh the page");
          }
        }
        return throwError(() => error.message);
      }),
      finalize(() => this.loaderService.requestCompleted(url))
    );
  }

  showError(message: string) {
    const config = {
      tapToDismiss: true,
      newestOnTop: true,
      timeOut: 5000,
      extendedTimeOut: 1000,
      progressBar: true,
    };

    this.toastr.error(message, "", config);
  }
}
