import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SiteService } from "../../site.service";
import { RedirectComponent } from "./redirect.component";

const routes = [
  { path: '', component: RedirectComponent }
];

@NgModule({
  declarations: [
    RedirectComponent
  ],
  imports: [
    RouterModule.forChild(routes)
  ],
  providers: [
    SiteService
  ]
})
export class RedirectModule {}
