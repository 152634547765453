// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.title-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: calc(1.5 * 1rem) 0; */
}

/* .title-action > * {
  padding: 0;
  margin: 0;
} */

.title-action button {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.title-action button:active {
  color: #D8D8D8;
}

::ng-deep .link-list {
  margin-bottom: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/app/topics/topics.component.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;;;GAGG;;AAEH;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["\r\n.title-action {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  /* margin: calc(1.5 * 1rem) 0; */\r\n}\r\n\r\n/* .title-action > * {\r\n  padding: 0;\r\n  margin: 0;\r\n} */\r\n\r\n.title-action button {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-top: auto;\r\n  margin-bottom: 1rem;\r\n  width: fit-content;\r\n  border: none;\r\n  background-color: transparent;\r\n  cursor: pointer;\r\n}\r\n\r\n.title-action button:active {\r\n  color: #D8D8D8;\r\n}\r\n\r\n::ng-deep .link-list {\r\n  margin-bottom: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
