// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lite-accordian {
    position: relative;
    width: 100%;
    min-height: 48px;
    /* border: 1px solid lightgrey; */
}

.lite-accordian > * {
    /* background-color: lightskyblue; */
}

.lite-accordian--title {
    font-weight: 600;
    display: inline-block;
    line-height: 48px;
    vertical-align: top;
    font-size: 1.25em;
}
.lite-accordian--button {
    position: absolute;
    display: inline-block;
    right: 0;
    width: 48px;
    height: 48px;
    /* background-color: lightseagreen; */
    cursor: pointer;

    line-height: 48px;
    text-align: center;
    
    font-family: 'health-stats-icons' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    transform: rotate(180deg);
}

.lite-accordian--button:before {
    content: "\\e901";
    font-size: 32px;
}

.lite-accordian--body {
    /* display: none; */
}`, "",{"version":3,"sources":["webpack://./src/app/uilib/lite-accordion/lite-accordion.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,iCAAiC;AACrC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,qCAAqC;IACrC,eAAe;;IAEf,iBAAiB;IACjB,kBAAkB;;IAElB,4CAA4C;IAC5C,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;IACpB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".lite-accordian {\r\n    position: relative;\r\n    width: 100%;\r\n    min-height: 48px;\r\n    /* border: 1px solid lightgrey; */\r\n}\r\n\r\n.lite-accordian > * {\r\n    /* background-color: lightskyblue; */\r\n}\r\n\r\n.lite-accordian--title {\r\n    font-weight: 600;\r\n    display: inline-block;\r\n    line-height: 48px;\r\n    vertical-align: top;\r\n    font-size: 1.25em;\r\n}\r\n.lite-accordian--button {\r\n    position: absolute;\r\n    display: inline-block;\r\n    right: 0;\r\n    width: 48px;\r\n    height: 48px;\r\n    /* background-color: lightseagreen; */\r\n    cursor: pointer;\r\n\r\n    line-height: 48px;\r\n    text-align: center;\r\n    \r\n    font-family: 'health-stats-icons' !important;\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    font-variant: normal;\r\n    text-transform: none;\r\n    transform: rotate(180deg);\r\n}\r\n\r\n.lite-accordian--button:before {\r\n    content: \"\\e901\";\r\n    font-size: 32px;\r\n}\r\n\r\n.lite-accordian--body {\r\n    /* display: none; */\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
