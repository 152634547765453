// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alphabet {
  padding-top: 1em;
  padding-bottom: 0;
  margin-bottom: 0;
}

.glossary-title {
  padding-top: 0.75em;
  padding-bottom: 0;
  margin-bottom: 0;
}

label {
  position: relative;
}

.search-input {
  background: #f4f4f7;
  margin-left: auto;
  margin-right: auto;
  height: 3.125rem;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  transform: translateY(-50%);
  
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  fill: #002664;
}

@media (min-width: 991px) {
  .search-input {
    max-width: 600px;
    margin-top: 1em;
    margin-left: calc((100% - 600px)/2);
  }
}

.no-match {
  font-weight: bold;
  margin-top: 4em;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/glossary/glossary.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,cAAc;EACd,2BAA2B;;EAE3B,qBAAqB;EACrB,aAAa;EACb,cAAc;EACd,aAAa;AACf;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;IACf,mCAAmC;EACrC;AACF;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".alphabet {\r\n  padding-top: 1em;\r\n  padding-bottom: 0;\r\n  margin-bottom: 0;\r\n}\r\n\r\n.glossary-title {\r\n  padding-top: 0.75em;\r\n  padding-bottom: 0;\r\n  margin-bottom: 0;\r\n}\r\n\r\nlabel {\r\n  position: relative;\r\n}\r\n\r\n.search-input {\r\n  background: #f4f4f7;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n  height: 3.125rem;\r\n}\r\n\r\n.search-icon {\r\n  position: absolute;\r\n  top: 50%;\r\n  right: 0.75rem;\r\n  transform: translateY(-50%);\r\n  \r\n  display: inline-block;\r\n  width: 1.5rem;\r\n  height: 1.5rem;\r\n  fill: #002664;\r\n}\r\n\r\n@media (min-width: 991px) {\r\n  .search-input {\r\n    max-width: 600px;\r\n    margin-top: 1em;\r\n    margin-left: calc((100% - 600px)/2);\r\n  }\r\n}\r\n\r\n.no-match {\r\n  font-weight: bold;\r\n  margin-top: 4em;\r\n  text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
