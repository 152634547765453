import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Breadcrumb} from './breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService implements OnInit {
  private breadCrumbsSource: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumbs$: Observable<Breadcrumb[]> = this.breadCrumbsSource.asObservable();

  constructor() {
  }

  ngOnInit(): void {
  }

  updateBreadCrumbs(breadCrumbs: Breadcrumb[]) {
    this.breadCrumbsSource.next(breadCrumbs);
  }

}
