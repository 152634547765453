import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IndicatorComponent } from "./indicator.component";
import { RouterModule } from "@angular/router";
import { TrendViewComponent } from "./indicatorViews/trend-view/trend-view.component";
import { SnapshotViewComponent } from "./indicatorViews/snapshot-view/snapshot-view.component";
import { MapViewComponent } from "./indicatorViews/map-view/map-view.component";
import { TableViewComponent } from "./indicatorViews/table-view/table-view.component";
import { UILibModule } from "../uilib/uilib.module";
import { PlotlyModule, PlotlyViaWindowModule } from "angular-plotly.js";
import { LegendComponent } from "./legend/legend.component";
import { FormsModule } from "@angular/forms";
import * as PlotlyJS from "plotly.js/dist/plotly.min.js";
import { PlotlyServiceExt } from "../services/plotly-service-extension";
import { DocumentExportService } from "../services/documentexport.service";

const routes = [
  { path: '', component: IndicatorComponent }
];

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    IndicatorComponent,
    TrendViewComponent,
    SnapshotViewComponent,
    MapViewComponent,
    TableViewComponent,
    LegendComponent,
  ],
  imports: [
    CommonModule,
    PlotlyViaWindowModule,
    UILibModule,
    FormsModule,
    RouterModule.forChild(routes),
    PlotlyModule
  ],
  providers: [
    PlotlyServiceExt,
    DocumentExportService
  ]
})
export class IndicatorModule {}
