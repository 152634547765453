import { NgModule } from '@angular/core';
import {RouterModule} from '@angular/router';
import { NotFound } from './notfound.component';

const routes = [
  { path: '', component: NotFound }
];

@NgModule({
  declarations: [NotFound],
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class NotFoundModule { }
