import { Component, Input, OnInit } from '@angular/core';
import { Util } from 'src/app/shared/util';
import {Link} from './link.model';

@Component({
  selector: 'app-link-list-item',
  templateUrl: './link-list-item.component.html',
  styleUrls: ['./link-list-item.component.css']
})
export class LinkListItemComponent {
  @Input() initialSize: number = 10; // how many items to show before "see more" must be clicked
  @Input() route: string;
  @Input() target: string = "_blank";
  isFullList: boolean;

  _allItems: Link[];
  get allItems(): Link[] {
    return this._allItems;
  }
  @Input() set allItems(value: Link[]) {
    this._allItems = value;
    this.populateItems()
  }

  filteredItems: Link[];

  constructor() {
  }

  onSeeMoreButtonClicked(event: Event) {
    event.preventDefault();
    this.isFullList = !this.isFullList;
    this.populateItems();
  }

  populateItems() {
    if (this.allItems) {
      if (this.isFullList) {
        this.filteredItems = Util.copy(this.allItems);
      } else {
        this.filteredItems = this.allItems.slice(0, this.initialSize);
      }
    }
  }

  isExternalLink(url) {
    let host = window.location.hostname;
    return host !== this.getLinkHost(url);
  }

  getLinkHost(url) {
    if (/^https?:\/\//.test(url)) { // Absolute URL.
      // The easy way to parse an URL, is to create <a> element.
      // @see: https://gist.github.com/jlong/2428561
      let parser = document.createElement('a');
      parser.href = url;

      return parser.hostname;
    }
    else { // Relative URL.
      return window.location.hostname;
    }
  }
}
