import {PALETTE, PALETTE2} from './indicatorViews/faceted-layout';

export class IndicatorColourBank {
    colour_bank = [];
    group_colours = {};

    constructor() {
        // this.colour_bank = JSON.parse(JSON.stringify(PALETTE));
    }

    replenish() {
        // Populate the colour bank with a copy of the palette
        // console.log('replenished');
      
        // this.colour_bank = JSON.parse(JSON.stringify(PALETTE));
        this.colour_bank = JSON.parse(JSON.stringify(PALETTE2));
    }

    assign(group) {
        // If there are no colours to draw on for this group
        if (
            this.group_colours[group]===undefined || 
            this.group_colours[group].length === 0
        ) {
            // console.log('colour_bank',this.colour_bank);

            // If we have used up all the colours
            if (this.colour_bank.length === 0) {
                this.replenish();
            }

            // Assign a block of colours to the group
            this.group_colours[group] = this.colour_bank.shift();

            // console.log('assigned',group,this.group_colours[group]);
        }
    }

    take_colour(group) {
        //debugger
        this.assign(group);

        // console.log('take',group);
        // console.log('this.colour_bank',this.colour_bank);
        // console.log('this.group_colours',this.group_colours);

        let colour = this.group_colours[group].shift();
        // console.log('take',group,colour);
        return `hsl(${colour[0]},${colour[1]}%,${colour[2]}%)`;
    }

    take_colour_data(group) : number[]{
        //debugger
        this.assign(group);

        // console.log('take',group);
        // console.log('this.colour_bank',this.colour_bank);
        // console.log('this.group_colours',this.group_colours);

        let colour = this.group_colours[group].shift();
        return colour;
        // console.log('take',group,colour);
        //return `hsl(${colour[0]},${colour[1]}%,${colour[2]}%)`;
    }

    format_as_hsl(data: number[]) : string {
        return `hsl(${data[0]},${data[1]}%,${data[2]}%)`;
    }

    format_as_hsla(data: number[], opacity :number) : string {
        //return `hsl(${data[0]},${data[1]}%,${data[2]}%,${opacity})`;
        return `hsla(${data[0]},${data[1]},${data[2]},${opacity})`;
    }
}