import {Component, Input, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {
  @Input() basic: boolean;
  isOpen: boolean = false;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  open() {
    this.isOpen = true;
    this.renderer.addClass(document.body, 'dialog');
  }

  close() {
    this.isOpen = false;
    this.renderer.removeClass(document.body, 'dialog');
  }

}
