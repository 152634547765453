// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  display: flex;
  flex-flow: column;
  height: 100%;
  /* background-color: red; */
}

a, a:visited {
  text-decoration: none;
  color: black;
}

a.chart-viewer--citation-link {
  text-decoration: underline;
  color: #002664;
}

#reference {
  width: 10px;
  height: 10px;
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/indicator/indicator.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".box {\r\n  display: flex;\r\n  flex-flow: column;\r\n  height: 100%;\r\n  /* background-color: red; */\r\n}\r\n\r\na, a:visited {\r\n  text-decoration: none;\r\n  color: black;\r\n}\r\n\r\na.chart-viewer--citation-link {\r\n  text-decoration: underline;\r\n  color: #002664;\r\n}\r\n\r\n#reference {\r\n  width: 10px;\r\n  height: 10px;\r\n  opacity: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
