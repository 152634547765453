import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { IndicatorState } from "../../indicator.state";
import { IndicatorTableService } from "../../indicator-table.service";

@Component({
  selector: "app-table-view",
  templateUrl: "./table-view.component.html",
  styleUrls: ["./table-view.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TableViewComponent implements OnInit {
  tablePageContent = "";

  @Input()
  state: IndicatorState;

  constructor(private indicatorTableService: IndicatorTableService) {}

  onStateUpdated(newState: IndicatorState) {
    this.state = newState;
    this.tablePageContent = this.indicatorTableService.generateHtmlTable(
      this.state
    );
  }

  ngOnInit() {}
}
