import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Util } from "../../shared/util";
import { SiteService } from "../../site.service";

@Component({
  selector: 'redirect',
  template: '<h1>Redirecting ... please wait</h1>'
})
export class RedirectComponent implements OnInit {

  private _origin: string = Util.GetCurrentUrlOrigin();

  constructor(private _activatedRoute: ActivatedRoute, private _router: Router, private _siteService: SiteService) {
  }

  async ngOnInit() {
    this
      ._activatedRoute
      .params
      .subscribe(async (params) => {
        const longPath = await this._siteService.getLongPath(params['shortUrlId']);
        if(longPath === "notfound" ){
          this._router.navigateByUrl(`${this._origin}/notfound`);
        }
        const redirectUrl = Util.FromBase64(longPath);
        this._router.navigateByUrl(redirectUrl);
    });
  }
}
