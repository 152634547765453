import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { RouterModule } from '@angular/router';
import {UILibModule} from '../uilib/uilib.module';


const routes = [
  { path: '', component: HomeComponent }
];


@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    UILibModule,
  ]
})
export class HomeModule { }
