import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  isLoading = new Subject<boolean>();
  urlsInProgress = new Array<string>();
  registerRequestUrl(url: string) {
    this.urlsInProgress.push(url);
    this.isLoading.next(this.loading());
  }
  requestCompleted(url: string) {
    this.urlsInProgress.pop();
    this.isLoading.next(this.loading());
  }
  loading() {
    return this.urlsInProgress.length > 0;
  }
}
