import { Component, OnInit } from "@angular/core";
import { ViewType } from "src/app/indicator/constraints";
import {
  IndicatorState,
  StatsIndicatorGroup,
} from "src/app/indicator/indicator.state";
import { Util } from "src/app/shared/util";
import { ChartViewerFilterComponent } from "../chart-viewer-filter.component";

@Component({
  selector: "app-group-container",
  templateUrl: "./group-container.component.html",
  styleUrls: ["./group-container.component.css"],
})
export class GroupContainerComponent implements OnInit {
  public groups: Array<string[]>;
  expandGroupsDropdownFirstTime = true;
  groupItems: Array<any>;
  selectedGroupItems: string[] = [];
  groupId: string;
  groupName: string;
  uniqueKey = 0;
  public parentRef: ChartViewerFilterComponent;
  groupMenuIsVisible: boolean;
  groupOptionsAreVisible: boolean;
  controlModeCheckboxes = true;
  statIndicatorGroups: StatsIndicatorGroup[];
  isMandatory: boolean = false;

  constructor() {}

  ngOnInit() {
    //debugger
    this.groupMenuIsVisible = false;
    this.groupOptionsAreVisible = false;
  }

  onRemoveGroupButtonClicked() {
    this.parentRef.onGroupRemoved(this.groupId);
  }

  onStateUpdated(state: IndicatorState) {
    this.statIndicatorGroups = state.Meta.groups;
    this.controlModeCheckboxes = state.View !== ViewType.Map;

    if (this.groupId === undefined) {
      this.groups = new Array<any>();
      state.AllowedGroups.forEach((element) => {
        this.groups.push([
          element,
          "by " + Util.getGroupLabel(element, this.statIndicatorGroups),
        ]);
      });
    } else {
      this.expandGroupsDropdownFirstTime = false;
      if (!this.groupItems || !Util.arraysEqual(this.groupItems , state.GroupOptions[this.groupId])) {
        this.groupItems = [];
        if(state.GroupOptions.hasOwnProperty(this.groupId)){
          state.GroupOptions[this.groupId].forEach((element) => {
            this.groupItems.push(element);
          });
        }
      }

      const filtered = state.Filter[this.groupId];
      if (filtered !== undefined) {
        this.selectedGroupItems = [];
        filtered.forEach((element) => {
          if (!this.selectedGroupItems.includes(element)) {
            this.selectedGroupItems.push(element);
          }
        });
      }
    }

    this.isMandatory = this.checkIfMandatory(this.groupId, state);
  }

  checkIfMandatory(groupId: string, state: IndicatorState) {
    if (!groupId) {
      return;
    }

    const presentations = state.Meta.locations.find(
      (location) => location.name === state.Location
    ).presentations;
    const groupsList: string[][] = presentations.map(
      (viewSet) => viewSet.groups
    );
    const groupsThatContainGroupId: string[][] = groupsList.filter((groups) =>
      groups.includes(groupId)
    );

    // if this groupId is in every group list then it is mandatory
    return groupsList.length === groupsThatContainGroupId.length;
  }

  onGroupToggleButtonClick() {
    this.groupOptionsAreVisible = !this.groupOptionsAreVisible;
  }

  onGroupButtonClick() {
    this.groupMenuIsVisible = !this.groupMenuIsVisible;
  }

  onSelectAllButtonClicked(event: Event) {
    event.preventDefault();
    this.selectedGroupItems = [];
    this.groupItems.forEach((element) => {
      this.selectedGroupItems.push(element);
    });
    this.parentRef.onGroupItemsChanged(this.groupId, this.selectedGroupItems);
  }

  onDeselectAllButtonClicked(event: Event) {
    event.preventDefault();
    this.selectedGroupItems = [];
    this.selectedGroupItems.push(this.groupItems[0]);
    this.parentRef.onGroupItemsChanged(this.groupId, this.selectedGroupItems);
  }

  onGroupOptionClick(id: any) {
    this.groupId = id;
    this.groupName = Util.getGroupLabel(id, this.statIndicatorGroups);
    this.expandGroupsDropdownFirstTime = false;
    this.parentRef.onGroupSelected(id);
  }

  checkBoxState(groupItem: string) {
    if (
      typeof this.selectedGroupItems !== "undefined" &&
      this.selectedGroupItems.indexOf(groupItem) !== -1
    ) {
      return "ui-checkboxradio-icon ui-corner-all ui-icon ui-icon-background ui-icon-check ui-state-checked ui-state-hover";
    } else {
      return "ui-checkboxradio-icon ui-corner-all ui-icon ui-icon-background 	ui-icon-blank";
    }
  }

  radioButtonState(groupItem: string) {
    if (
      typeof this.selectedGroupItems !== "undefined" &&
      this.selectedGroupItems.indexOf(groupItem) !== -1
    ) {
      return "ui-button ui-widget ui-checkboxradio-radio-label ui-checkboxradio-checked ui-state-active ui-checkboxradio-label ui-controlgroup-item";
    } else {
      return "ui-button ui-widget ui-checkboxradio-radio-label ui-checkboxradio-label ui-controlgroup-item";
    }
  }

  onGroupItemClicked(event: Event, groupItem: any) {
    event.preventDefault();

    if (!this.controlModeCheckboxes) {
      this.selectedGroupItems = [];
    }

    if (this.selectedGroupItems.length === 0) {
      this.selectedGroupItems = [groupItem];
    } else {
      const index = this.selectedGroupItems.indexOf(groupItem);
      if (index === -1) {
        this.selectedGroupItems.push(groupItem);
      } else {
        this.selectedGroupItems.splice(index, 1);
        if (this.selectedGroupItems.length === 0) {
          this.selectedGroupItems = [this.groupItems[0]];
        }
      }
    }
    this.parentRef.onGroupItemsChanged(this.groupId, this.selectedGroupItems);
  }
}
