import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbConfig } from '../app-breadcrumb/breadcrumb.config';
import { BreadcrumbService } from '../app-breadcrumb/breadcrumb.service';
import { StatsService } from '../stats.service';
import { MetadataService } from '../metadata.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.css']
})

export class TopicsComponent implements OnInit {
  topicsList: any[] = [];
  labels: string[];
  title: string;

  constructor(private statsService: StatsService,
    private breadcrumbService: BreadcrumbService,
    private metaTagService: Meta,
    private metadataService: MetadataService) {
      this.title = "Topics";
  }

  ngOnInit() {
    this.breadcrumbService.updateBreadCrumbs([
      BreadcrumbConfig.TOPICS
    ]);

    this.topicsList = [];
    this.statsService.loadTopics().subscribe(data => {
      this.topicsList = data;
      this.labels = this.topicsList.map(item => item.title);
      if (this.metadataService) {
        this.metadataService.updateMetadata({
          title: this.title,
          description: 'Explore NSW population health indicators by topic',
          keywords:this.labels
        });
      }
    });
  }
}
