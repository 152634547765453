import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from "./app.component";
import { provideClientHydration } from "@angular/platform-browser";
import { AppService } from "./app.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AppRoutes } from "./app.routes";
import { AppMainComponent } from "./app.main.component";
import {
  LocationStrategy,
  HashLocationStrategy,
  PathLocationStrategy,
  DatePipe,
  DecimalPipe,
} from "@angular/common";
import { AppBreadcrumbComponent } from "./app-breadcrumb/app-breadcrumb.component";
import { AppFooterComponent } from "./app-footer/app-footer.component";
import { APP_INITIALIZER } from "@angular/core";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { AppHeaderComponent } from "./app-header/app-header.component";
import { UILibModule } from "./uilib/uilib.module";
import { LoaderComponent } from "./components/loader/loader.component";
import { LoaderService } from "./services/loader.service";
import { LoaderInterceptor } from "./intercepters/loader.interceptor";
import { ToastrModule } from "ngx-toastr";
import { FormatService } from "./shared/format.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { AppInsightsService } from "./services/appinsights.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppConfig, configFactory } from "./app.config";
import { NgxSsrCacheModule } from '@ngx-ssr/cache';



@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    AppMainComponent,
    AppBreadcrumbComponent,
    AppFooterComponent,
    SafeHtmlPipe,
    AppHeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    // AppRoutingModule,
    AppRoutes,
    UILibModule,
    ToastrModule.forRoot(),
    NgxSsrCacheModule.configLruCache({ maxAge: 10 * 60_000, maxSize: 50 }),
  ],
  providers: [
    //{ //Turning this of as it contains more issues - to be fixed in another ticket.
    //  provide: UrlSerializer,
    //  useClass: LowerCaseUrlSerializer
    //},
    provideClientHydration(),
    AppService,

    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [AppConfig],
      multi: true,
    },
    AppInsightsService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe,
    DecimalPipe,
    FormatService,
    GoogleAnalyticsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
