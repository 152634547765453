import { Component, OnInit } from "@angular/core";
import { ContentService } from "../content.service";
import { WebPage } from "../webpage.model";
import { BreadcrumbService } from "../app-breadcrumb/breadcrumb.service";
import { ActivatedRoute } from "@angular/router";
import {OnDestroy } from '@angular/core';
import { MetadataService } from "../metadata.service";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.sass"],
})
export class PageComponent implements OnInit, OnDestroy {
  name: string;
  routeSubs: any;
  webPage: WebPage;
  pageContent = "";

  constructor(
    public contentService: ContentService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private metadataService: MetadataService
  ) {}

  ngOnInit() {
    this.breadcrumbService.updateBreadCrumbs([]);
    this.webPage = null;
    this.routeSubs = this.route.paramMap.subscribe((params) => {
      this.name = params.get("uri");
      if (this.metadataService) {
        this.metadataService.updateMetadata({
          title: this.name
        });
      }
      this.loadData(this.name);
    });
  }

  loadData(name: string) {
    this.contentService.getByName(name).subscribe((data: WebPage) => {
      if (data) {
        this.webPage = data;
        this.pageContent = this.webPage.content;
      }
    });
  }

  ngOnDestroy() {
    this.routeSubs.unsubscribe();
  }
}
