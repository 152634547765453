// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-selector {
  width: 100%;
}

.location-selector,
.location-selector nav {
  display: inline-block;
}

.location-selector nav {
  padding-top: 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid #E4E4E6;
}

.location-selector p {
  max-width: 55rem;
}

p.location-selector--description {
  margin-bottom: 48px;
}

.location-selector nav > button {
  position: relative;
  display: inline-block;
  border: none;
  width: 100%;
  padding: 0 16px;
  background: none;
  cursor: pointer;
  line-height: 2.5;
  border-top: 2px solid #E4E4E6;
  text-align: left;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #002664;
  z-index: 1;
}

@media (min-width: 992px) {
  .location-selector nav > button {
    width: -moz-fit-content;
    width: fit-content;
    border-top: none;
  }
  .location-selector nav > button:hover {
    border-bottom: 4px solid #cce7f0;
  }
}

.location-selector nav > button:hover {
  background-color: #cce7f0;
}

.location-selector nav > button.selected {
  border-bottom: 4px solid #47888E;
  font-weight: 600;
  color: #333333;
}

.link-list {
  margin-bottom: 0em;
}
`, "",{"version":3,"sources":["webpack://./src/app/locations/locations.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,6BAA6B;EAC7B,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;EACd,UAAU;AACZ;;AAEA;EACE;IACE,uBAAkB;IAAlB,kBAAkB;IAClB,gBAAgB;EAClB;EACA;IACE,gCAAgC;EAClC;AACF;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;EAChC,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".location-selector {\r\n  width: 100%;\r\n}\r\n\r\n.location-selector,\r\n.location-selector nav {\r\n  display: inline-block;\r\n}\r\n\r\n.location-selector nav {\r\n  padding-top: 1rem;\r\n  margin-bottom: 0.5rem;\r\n  border-bottom: 2px solid #E4E4E6;\r\n}\r\n\r\n.location-selector p {\r\n  max-width: 55rem;\r\n}\r\n\r\np.location-selector--description {\r\n  margin-bottom: 48px;\r\n}\r\n\r\n.location-selector nav > button {\r\n  position: relative;\r\n  display: inline-block;\r\n  border: none;\r\n  width: 100%;\r\n  padding: 0 16px;\r\n  background: none;\r\n  cursor: pointer;\r\n  line-height: 2.5;\r\n  border-top: 2px solid #E4E4E6;\r\n  text-align: left;\r\n  padding-top: 0.5rem;\r\n  padding-bottom: 0.5rem;\r\n  color: #002664;\r\n  z-index: 1;\r\n}\r\n\r\n@media (min-width: 992px) {\r\n  .location-selector nav > button {\r\n    width: fit-content;\r\n    border-top: none;\r\n  }\r\n  .location-selector nav > button:hover {\r\n    border-bottom: 4px solid #cce7f0;\r\n  }\r\n}\r\n\r\n.location-selector nav > button:hover {\r\n  background-color: #cce7f0;\r\n}\r\n\r\n.location-selector nav > button.selected {\r\n  border-bottom: 4px solid #47888E;\r\n  font-weight: 600;\r\n  color: #333333;\r\n}\r\n\r\n.link-list {\r\n  margin-bottom: 0em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
