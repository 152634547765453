import { Injectable } from "@angular/core";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

export interface PageMetadata {
  title: string;
  imageRelativeUrl: string;
  description: string;
  author: string;
  keywords: string[];
  type: string;
}

const defaultMetadata: PageMetadata = {
  title: "HealthStats NSW",
  imageRelativeUrl: "/assets/img/HealthStats_UVPs_animation.svg",
  description:
    "Population health data at your fingertips, ready to use statistics, flexible analysis tools and custom reports",
  author: "Centre for Epidemiology and Evidence, NSW Ministry of Health",
  keywords: [
    "HealthStats",
    "NSW",
    "health",
    "data",
    "statistics",
    "analysis",
    "flexible",
    "reports",
    "topics",
    "location",
    "publications",
    "resources",
    "Indicators",
  ],
  type: "statistical information about the health",
};

@Injectable({
  providedIn: "root",
})
export class MetadataService {
  private capitalizeFirstChar = (str) =>
    str.charAt(0).toUpperCase() + str.substring(1);
  constructor(private metaTagService: Meta, private titleService: Title) {}

  public updateMetadata(
    metadata: Partial<PageMetadata>,
    index: boolean = true
  ): void {
    metadata.title = this.capitalizeFirstChar(metadata.title);
    const pageMetadata: PageMetadata = { ...defaultMetadata, ...metadata };

    this.metaTagService.updateTag({
      name: "title",
      content: this.getTitle(pageMetadata.title),
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "og:title",
      content: this.getTitle(pageMetadata.title),
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "description",
      content: pageMetadata.description,
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "og:description",
      content: pageMetadata.description,
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "author",
      content: pageMetadata.author,
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "og:author",
      content: pageMetadata.author,
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "keywords",
      content: this.getKeywords(pageMetadata.keywords),
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "og:type",
      content: pageMetadata.type,
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "robots",
      content: index ? "index, follow" : "noindex",
    } as MetaDefinition);
    this.metaTagService.updateTag({
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    } as MetaDefinition);
    this.metaTagService.updateTag({
      "http-equiv": "Content-Type",
      content: "text/html; charset=utf-8",
    } as MetaDefinition);

    this.titleService.setTitle(this.getTitle(pageMetadata.title));
  }

  private getTitle(value: string | undefined): string {
    let result = "";
    if (value && value.length > 0) {
      result = `${value} - `;
    }
    return `${result}${defaultMetadata.title}`;
  }

  private getKeywords(value: string[]): string {
    if(value){
      return `${value.join(",")},${defaultMetadata.keywords.join(",")}`;
    }
    return `${defaultMetadata.keywords.join(",")}`;
  }
}
