export class ContinuumAccordion {
  title: string;
  type: string;
  description: string;
  topics: ContinuumTopic[];
}

export class ContinuumTopic {
  title: string;
  items_level_1: Level1Item[];
}

export class Level1Item {
  link: string;
  label: string;
  items_level_2?: Level2Item[];
}

export class Level2Item {
  link: string;
  label: string;
}

export class ContinuumGroup {
  type: string;
  uri: string;
  label: string;
  subGroups: ContinuumGroup[];
  indicators: ContinuumIndicator[];
  description?: string;
}

export class ContinuumIndicator {
  uri: string;
  label: string;
  name: string;
  subIndicators: ContinuumIndicator[];
}
