import { ViewType } from "src/app/indicator/constraints";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { IndicatorState } from "src/app/indicator/indicator.state";

const enum PeriodComponentMode {
  CheckBoxes = "CheckBoxes",
  RadioButtons = "RadioButtons",
}

@Component({
  selector: "app-period-container",
  templateUrl: "./period-container.component.html",
  styleUrls: ["./period-container.component.css"],
})
export class PeriodContainerComponent implements OnInit {
  @Output() selectedPeriodsChanged: EventEmitter<string[]> = new EventEmitter();

  periodItems: string[] = [];
  selectedPeriodItems: string[] = [];
  periodId: string;
  periodName: string;
  periodOptionsAreVisible: boolean = false;
  controlIsVisible: boolean;
  controlModeCheckboxes = true;

  constructor() {}

  ngOnInit() {
    this.periodOptionsAreVisible = false;
    this.controlIsVisible = false;
  }

  onStateUpdated(state: IndicatorState) {
    if (state.View === ViewType.Trend) {
      this.controlIsVisible = false;
    } else {
      this.controlIsVisible = true;
      this.periodOptionsAreVisible = true;

      if (state.View === ViewType.Map) {
        this.controlModeCheckboxes = false;
      } else {
        this.controlModeCheckboxes = true;
      }

      const oldPeriodItems = this.periodItems;
      this.periodItems = [];
      

      this.selectedPeriodItems = [];
      if (state.GroupOptions["Period"]) {
        state.GroupOptions["Period"].forEach((period) => {
          this.periodItems.push(period);
        });
        const periodFilter = state.Filter["Period"];
        if (periodFilter !== undefined) {
          this.selectedPeriodItems = [];
          periodFilter.forEach((period) => {
            if (!this.selectedPeriodItems.includes(period)) {
              this.selectedPeriodItems.push(period);
            }
          });
        }
      }
      if (oldPeriodItems.length > 0 && this.periodItems.length > 0 && !this.arrayEquals(oldPeriodItems, this.periodItems)){
        this.onDeselectAllButtonClicked(new Event("Click"));
      }
    }
  }

  arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }
  onGroupToggleButtonClick() {
    this.periodOptionsAreVisible = !this.periodOptionsAreVisible;
  }

  onSelectAllButtonClicked(event: Event) {
    event.preventDefault();
    this.selectedPeriodItems = [];
    this.periodItems.forEach((element) => {
      this.selectedPeriodItems.push(element);
    });
    this.selectedPeriodsChanged.emit(this.selectedPeriodItems);
  }

  onDeselectAllButtonClicked(event: Event) {
    event.preventDefault();
    this.selectedPeriodItems = [];
    this.selectedPeriodItems.push(this.periodItems[0]);
    this.selectedPeriodsChanged.emit(this.selectedPeriodItems);
  }

  onPeriodItemClicked(event: Event, periodItem: any) {
    event.preventDefault();

    if (!this.controlModeCheckboxes) {
      this.selectedPeriodItems = [];
    }

    if (this.selectedPeriodItems.length === 0) {
      this.selectedPeriodItems = [periodItem];
    } else {
      const index = this.selectedPeriodItems.indexOf(periodItem);
      if (index === -1) {
        this.selectedPeriodItems.push(periodItem);
      } else {
        this.selectedPeriodItems.splice(index, 1);
        if (this.selectedPeriodItems.length === 0) {
          this.selectedPeriodItems = [this.periodItems[0]];
        }
      }
    }
    this.selectedPeriodsChanged.emit(this.selectedPeriodItems);
  }

  checkBoxState(periodItem: string) {
    if (
      typeof this.selectedPeriodItems !== "undefined" &&
      this.selectedPeriodItems.indexOf(periodItem) !== -1
    ) {
      return "ui-checkboxradio-icon ui-corner-all ui-icon ui-icon-background ui-icon-check ui-state-checked ui-state-hover";
    } else {
      return "ui-checkboxradio-icon ui-corner-all ui-icon ui-icon-background 	ui-icon-blank";
    }
  }

  radioButtonState(periodItem: string) {
    if (
      typeof this.selectedPeriodItems !== "undefined" &&
      this.selectedPeriodItems.indexOf(periodItem) !== -1
    ) {
      return "ui-button ui-widget ui-checkboxradio-radio-label ui-checkboxradio-checked ui-state-active ui-checkboxradio-label ui-controlgroup-item";
    } else {
      return "ui-button ui-widget ui-checkboxradio-radio-label ui-checkboxradio-label ui-controlgroup-item";
    }
  }
}
