import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StatsService } from '../stats.service';
import { BreadcrumbService } from '../app-breadcrumb/breadcrumb.service';
import { BreadcrumbConfig } from '../app-breadcrumb/breadcrumb.config';
import { ActivatedRoute } from '@angular/router';
import { LocationSummary } from './location-summary/location-summary.model';
import { Util } from '../shared/util';
import { AppConfig } from "../app.config";
import { MetadataService } from '../metadata.service';

@Component({
  selector: 'location-overview',
  templateUrl: './location-overview.component.html',
  styleUrls: ['./location-overview.component.css']
})
export class LocationOverviewComponent implements OnInit {
  topicsList: any[] = [];
  labels: string[];
  location: LocationSummary;
  locationType: string;
  locationName: string;


  constructor(private statsService: StatsService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private metadataService: MetadataService) {
  }


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.locationType = params['locationType'];
      this.locationName = params['name'];
      this.statsService.loadLocationSummary(this.locationType, this.locationName).subscribe(l => {
        this.location = l;

        this.breadcrumbService.updateBreadCrumbs([
          BreadcrumbConfig.LOCATIONS,
          { name: this.location.label }
        ]);

        if (!!this.location && !!this.location.label) {
          if (this.metadataService) {
            this.metadataService.updateMetadata({
              title: this.location.label,
              description: this.location.overview,
              keywords: this.location.lga ? this.location.lga?.split(",") :this.location.label.split(",")
            });
          }
        }

        this.statsService
          .loadTopics(this.location.shortLabel, this.locationType)
          .subscribe(data => {
            this.topicsList = data;
            this.labels = this.topicsList.map(item => item.title);
          });
      });
    });
  }
}
