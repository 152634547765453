import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.css']
})
export class LinkListComponent implements OnInit {

  @Input() selectedTopicsList: any[];
  @Input() route: string;

  constructor() { }

  ngOnInit() {
  }

}
