import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { IndicatorState } from "src/app/indicator/indicator.state";

@Component({
  selector: "app-location-container",
  templateUrl: "./location-container.component.html",
  styleUrls: ["./location-container.component.css"],
})
export class LocationContainerComponent implements OnInit {
  @Output()
  locationSubdivisionChanged: EventEmitter<string> = new EventEmitter();
  @Output() selectedLocationsChanged: EventEmitter<
    string[]
  > = new EventEmitter();

  locationSubdivisions = [];
  title: string;
  locationId: string; // ??
  selectedLocationSubdivisionId: string;
  locations: string[] = [];
  selectedLocations: string[] = [];

  constructor() {
    this.selectedLocations = [];
  }

  ngOnInit() {}

  onStateUpdated(state: IndicatorState) {
    this.title = state.Meta.locations.find(
      (location) => location.name === state.Location
    ).label;
    this.selectedLocations = [];
    this.selectedLocationSubdivisionId = state.Location;
    this.selectLocationsFromFilter(state);
    this.locations = state.Locations;

    this.locationSubdivisions = [];
    state.Meta.locations.forEach((location) =>
      this.locationSubdivisions.push([location.name, location.label])
    );
  }

  selectLocationsFromFilter(state: IndicatorState) {
    const filtered = state.Filter[state.Location];
    if (typeof filtered !== "undefined") {
      this.selectedLocations = [];
      filtered.forEach((element) => {
        this.selectedLocations.push(element);
      });
    }
  }

  checkBoxState(location: string) {
    if (
      typeof this.selectedLocations !== "undefined" &&
      this.selectedLocations.indexOf(location) != -1
    ) {
      return "ui-checkboxradio-icon ui-corner-all ui-icon ui-icon-background ui-icon-check ui-state-checked ui-state-hover";
    } else {
      return "ui-checkboxradio-icon ui-corner-all ui-icon ui-icon-background 	ui-icon-blank";
    }
  }

  onLocationSubdivisionClicked(id: any) {
    this.title = id;
    this.locationSubdivisionChanged.emit(id);
  }

  onLocationClicked(event: Event, location: any) {
    event.preventDefault();
    if (typeof this.selectedLocations === "undefined") {
      this.selectedLocations = [location];
    } else {
      const index = this.selectedLocations.indexOf(location);
      if (index === -1) {
        this.selectedLocations.push(location);
      } else {
        this.selectedLocations.splice(index, 1);
        if (this.selectedLocations.length === 0) {
          this.selectedLocations = [this.locations[0]];
        }
      }
    }
    this.selectedLocationsChanged.emit(this.selectedLocations);
  }

  onSelectAllButtonClicked(event: Event) {
    event.preventDefault();
    this.selectedLocations = [];
    this.locations.forEach((element) => {
      this.selectedLocations.push(element);
    });
    this.selectedLocationsChanged.emit(this.selectedLocations);
  }

  onDeselectAllButtonClicked(event: Event) {
    event.preventDefault();
    this.selectedLocations = [this.locations[0]];
    this.selectedLocationsChanged.emit(this.selectedLocations);
  }
}
