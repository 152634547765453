import { StatsIndicatorGroup } from "../indicator/indicator.state";
import * as _ from "underscore";

export class Util {

  public static replaceAll(str: string, find: any, replacement: any) {
    return str !== undefined ? str.split(find).join(replacement) : str;
  }

  public static slugify(str: string): string {
    str = String(str).toLowerCase();
    str = str.replace(/\s/g, "-");
    str = str.replace(/[^a-zA-Z0-9\-]/g, "");
    str = str.trim();
    return str;
  }

  public static deslugify(str: string): string {
    let val = "";
    if (str) {
      if (
        str.toLocaleLowerCase() == "nsw" ||
        str.toLocaleLowerCase() == "lhd" ||
        str.toLocaleLowerCase() == "lga" ||
        str.toLocaleLowerCase() == "phn"
      ) {
        val = str.toUpperCase();
      } else {
        val = str.replace(/\-/g, " ");
        val = val.slice(0, 1).toUpperCase() + val.slice(1);
      }
    }
    return val;
  }

  public static getGroupLabel(
    name: string,
    groups: StatsIndicatorGroup[]
  ): string {
    const group = groups.find((group) => group.name == name);
    if (group) {
      return group.label;
    }
    return name;
  }

  public static copy(arr): any {
    return arr.slice(0);
  }

  // TODO need a better solution than that - Encoding of Special Characters
  public static copyAndEncode(arr): any {
    let ret: any[] = [];
    arr.forEach((element) => {
      //let elEncoded = encodeURI(element);
      if(element){
        let elEncoded = element.replaceAll(",", "%2C").replaceAll("+", "%2B");
        ret.push(elEncoded);
      }
    });
    return ret;
  }

  // TODO need a better solution than that - Encoding of Special Characters
  public static copyAndDecode(arr): any {
    let ret = [];
    arr.forEach((element) => {
      //let elEncoded = encodeURI(element);
      let elDecoded = element.replaceAll("%2C", ",").replaceAll("%2B", "+");
      ret.push(elDecoded);
    });
    return ret;
  }

  public static deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public static deduplicate(arr: any[]): any {
    arr = this.copy(arr);
    for (let i = 0; i < arr.length; ++i) {
      for (let j = i + 1; j < arr.length; ++j) {
        if (arr[i] === arr[j]) {
          arr.splice(j--, 1);
        }
      }
    }
    return arr;
  }

  public static equals(arr1: any, arr2: any): any {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  public static cartesian(arg: any): any {
    const r = [],
      max = arg.length - 1;
    function helper(arr, i) {
      for (let j = 0, l = arg[i].length; j < l; j++) {
        const a = arr.slice(0); // clone arr
        a.push(arg[i][j]);
        if (i == max) {
          r.push(a);
        } else {
          helper(a, i + 1);
        }
      }
    }
    helper([], 0);
    return r;
  }

  public static remove(array: any[], element: any): any {
    array = this.copy(array);
    while (array.indexOf(element) !== -1) {
      array.splice(array.indexOf(element), 1);
    }
    return array;
  }

  public static joinArray(groups: string[]): string {
    if (typeof groups !== "undefined") {
      groups.sort();
      return groups.join();
    } else {
      return "";
    }
  }

  public static arraysEqual(_arr1: string[], _arr2: string[]) {
    if (
      !Array.isArray(_arr1) ||
      !Array.isArray(_arr2) ||
      _arr1.length !== _arr2.length
    ) {
      return false;
    }
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  public static removeTags(html: string): string {
    if (html && html != '') {
      var div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    }

    return '';
  }

  public static checkIfInteger(data: string): boolean {
    if (_.isNumber(data)) {
      if (Number.isInteger(data)) {
        return true;
      }
    }
    return false;
  }

  public static wordCount(content: string, word: string): number {
    var regExp = new RegExp(word, 'g');
    var mathing = content.match(regExp);
    return mathing ? mathing.length : 0;
  }

  public static wrapContent(content, maxWidth): string {
    let newLineStr = "<br>";
    let result = '';
    while (content.length > maxWidth) {
      let found = false;
      for (let i = maxWidth - 1; i >= 0; i--) {
        if (Util.isWhiteSpace(content.charAt(i))) {
          result = result + [content.slice(0, i), newLineStr].join('');
          content = content.slice(i + 1);
          found = true;
          break;
        }
      }
      if (!found) {
        result += [content.slice(0, maxWidth), newLineStr].join('');
        content = content.slice(maxWidth);
      }
    }

    return result + content;
  }

  public static isWhiteSpace(word): boolean {
    var regExp = new RegExp(/^\s$/);
    return regExp.test(word.charAt(0));
  }

  public static createLocationBasedChartUrl(baseUrl, locationType, location): string {
    return `${baseUrl}&compare=${locationType}&location=${locationType}&filter=${locationType},${location}`;
  }

  public static convertToPlainText(html: string): string {
    if (!html) {
      return '';
    }

    return Util.removeTags(html.trim())
      .replace(new RegExp("\n", 'g'), ' ', )
      .replace(new RegExp("\r", 'g'), '')
      .replace(new RegExp("  ", 'g'), '')
      .replace(new RegExp("\"", 'g'), "'")
      .trim();
  }

  public static saveAs(blob: Blob, fileName: string) {
    const downloadLink = document.createElement("a");
    downloadLink.download = fileName;
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  public static GetCurrentUrlOrigin(): string {
    return window.location.origin;
  }

  public static GetHSMEOrigin(): string {
    return `${Util.GetCurrentUrlOrigin()}/`;
  }

  public static UpdatePageUrl(url: string) {
    location.replace(url);
  }

  public static GetCurrentUrlChildPath(): string {
    return window.location.href.replace(`${Util.GetCurrentUrlOrigin()}/`, '');
  }

  public static ToBase64(value: string): string {
    return btoa(value);
  }

  public static FromBase64(value: string): string {
    return atob(value);
  }

  public static getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
