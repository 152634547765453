import { Component, Input } from '@angular/core';
import { FeaturedCards } from './featuredcards.model';

@Component({
  selector: 'app-featured-cards',
  templateUrl: './featuredcards.component.html',
  styleUrls: ['./featuredcards.component.css']
})
export class FeaturedCardsComponent {
  @Input() featuredCards: FeaturedCards[];

  constructor() { }
}
