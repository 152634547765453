import { Component, OnInit } from '@angular/core';
import { BreadcrumbConfig } from '../app-breadcrumb/breadcrumb.config';
import { BreadcrumbService } from '../app-breadcrumb/breadcrumb.service';
import { Locations } from '../indicator/constraints';
import { Util } from '../shared/util';
import { StatsService } from '../stats.service';
import { MetadataService } from '../metadata.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {

  locationType: string;
  statsService: StatsService;
  locationsList: any[] = [];
  currentLocationData: any;
  alphabet: string[];
  firstLettersArray: string[] = [];
  labels: string[];
  locationTypes = Locations;
  title: string;

  constructor(statsService: StatsService,
    private breadcrumbService: BreadcrumbService,
    private metadataService: MetadataService) {
    this.statsService = statsService;
    this.locationType = Locations.LHD;
    this.title = "Locations";
  }

  ngOnInit() {
    this.breadcrumbService.updateBreadCrumbs([
      BreadcrumbConfig.LOCATIONS
    ]);
    if (this.metadataService) {
      this.metadataService.updateMetadata({
        title: this.title,
        description: "Explore NSW population health data by location",
        keywords: ["LHDs","LGAs","PHNs"]
      });
    }
    this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    this.locationsList = [];
    this.statsService.loadLocations().subscribe(data => {
      data.forEach(element => {
        element.items.forEach(item => {
          item.url = `/location-overview/${item.name}/${element.title.toUpperCase()}`;
          item.useUrlDirectly = true;
          item.locationType = element.title.toUpperCase();
        });
        this.locationsList.push(element)
      });
      this.prepareData();
    });
  }

  prepareData() {
    if (this.locationsList) {
      let curr = this.locationsList.find(x => x.title.toUpperCase() === this.locationType);
      if (curr) {
        this.currentLocationData = curr;
      }
      this.labels = this.currentLocationData.items.map(item => item.label);

      this.firstLettersArray = [];
      this.currentLocationData.items.forEach( listItem => {
        this.firstLettersArray.push(listItem.label[0].toUpperCase());
      })
      this.firstLettersArray = Util.deduplicate(this.firstLettersArray);
      this.firstLettersArray.sort();
    }
  }

  onLocationTypeSelected(locationType: string) {
    this.locationType = locationType;
    this.prepareData();
  }

  getItemsForLetter(letter: string) {
    let items : any[] = [];
    this.currentLocationData.items.forEach(element => {
      if (element.label[0].toLowerCase() === letter.toLowerCase()) {
        items.push(element);
      }
    });
    return items;
  }
}
