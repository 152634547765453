import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { IndicatorState } from "../indicator.state";
import { Accordion } from "src/app/uilib/accordion/accordion.model";
import { ContinuumGroup } from "src/app/uilib/continuum-accordion/continuum.model";
import { Observable, lastValueFrom } from "rxjs";
import { ContentService } from "src/app/content.service";
import { Util } from "src/app/shared/util";
import { map } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { StatsService } from "src/app/stats.service";
import { Title } from "@angular/platform-browser";
import { isPlatformBrowser } from "@angular/common";
import { AppConfig } from "src/app/app.config";

@Component({
  selector: "indicator-for-ssr",
  templateUrl: "./indicator-for-ssr.component.html",
  styleUrls: ["./indicator-for-ssr.component.css"],
})
export class IndicatorForSSRComponent implements OnInit, OnDestroy {
  citationData = {
    source:
      "Centre for Epidemiology and Evidence.  HealthStats NSW. Sydney:  NSW Ministry of Health",
    url: window.location.href,
    date: new Date(),
  };
  title: string;
  source: string;
  state: IndicatorState;
  accordions: Accordion[];
  indicatorName: string;
  continuumAccordions$: Observable<ContinuumGroup[]>;
  constructor(
    private statsService: StatsService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.state = new IndicatorState();
  }
  ngOnInit() {
    this.route.params.subscribe((params: any) => {
      const previousIndicatorName = this.indicatorName;
      this.indicatorName = params.indicatorName;
      if (isPlatformBrowser(this.platformId)) {
        location.replace(`/indicator?name=${this.indicatorName}`);
      }
      this.getMeta(this.indicatorName);
      if (!this.accordions || previousIndicatorName !== this.indicatorName) {
        this.extractSources();
      }
    });
  }

  ngOnDestroy() {
    this.titleService.setTitle(AppConfig.settings.constants.defaultPageTitle);
  }

  async getMeta(indicatorName: string) {
    const meta = await lastValueFrom(
      this.statsService.getIndicatorMeta(indicatorName)
    );
    if (meta) {
      this.state.Meta = meta;
      if (!!this.state && !!this.state.Meta && !!this.state.Meta.title) {
        this.titleService.setTitle(
          `${AppConfig.settings.constants.defaultPageTitle} - ${this.state.Meta.title}`
        );
      }
    }
  }

  extractSources() {
    this.contentService
      .getIndicatorText(this.indicatorName)
      .pipe(
        map((accordions) => {
          const sourceAccordion = accordions.find(
            (accordion) => accordion.title === "Sources (under visualisation)"
          );
          if (sourceAccordion) {
            accordions = Util.remove(accordions, sourceAccordion);
            this.source = "";
            sourceAccordion.children.forEach(
              (textSnippet) => (this.source += textSnippet.articleText)
            );
          }
          return accordions;
        })
      )
      .subscribe((accordions) => (this.accordions = accordions));
  }

  // This is temporary until API returns a flag to indicate if accordion should be expanded or not
  isCommentary(accordion: Accordion) {
    return (
      !!accordion &&
      !!accordion.title &&
      !!accordion.title.toLowerCase().includes("commentary")
    );
  }
}
