import { Injectable } from '@angular/core';
import { Util } from 'src/app/shared/util';
import { IndicatorState } from './indicator.state';
import { Locations } from "src/app/indicator/constraints";

@Injectable()
export class FacetsLib {

    public static get_facet_groups(indicatorState: IndicatorState) {
        const state = indicatorState;
        const groups = (state.Location === Locations.NSW) ? state.Groups : [state.Location, ...state.Groups];
        let facet_groups = groups.filter(value => !state.Compare.includes(value));
        return facet_groups;
    }

    public static get_facet_ids(indicatorState: IndicatorState) {
        const state = indicatorState;
        let facet_values = [];
        let facet_groups = FacetsLib.get_facet_groups(state);
        // console.log('facet_groups',facet_groups);

        // For each facet group the values as determined by the filter
        facet_groups.forEach(current => {
            if (state.Filter[current] !== undefined) {
                // console.log('current',current,state.filter[current] );
                facet_values.push(state.Filter[current])
            };
        });
        // console.log('facet_values',facet_values);

        let facet_ids = Util.equals(facet_values, []) ?
            ['all'] :
            Util.cartesian(facet_values).reduce((result, current) => {
                let value = Util.slugify(current.join('--'));
                result.push(value);
                return result;
            }, [])
            ;

        // console.log('facet_ids', facet_ids);

        return facet_ids
    }

    public static  get_facet_id(record, indicatorState: IndicatorState) {
        // console.log('record', record);
        let facet_groups = FacetsLib.get_facet_groups(indicatorState);

        let facet_id = facet_groups.reduce((result, current) => {
            result.push(record[current]);
            return result;
        }, []).join('--');

        facet_id = Util.slugify(facet_id);

        return facet_id;
    }

    public static  get_facet_title(record, indicatorState: IndicatorState) {

        let facet_groups = FacetsLib.get_facet_groups(indicatorState);
        let facet_groups_array = [];
        let facet_title = "";

        // TODO insert break after the first group
        facet_groups.forEach((g) => {
            facet_groups_array.push(record[g]);
        })

        if (facet_groups_array.length !== 0) {
            for(let i = 0; i < facet_groups_array.length; i++) {
                if (i == facet_groups_array.length - 1) {
                    facet_title += facet_groups_array[i];
                } else if (i == 0) {
                    facet_title += facet_groups_array[i] + ' - ' + '<br>';
                } else {
                    facet_title += facet_groups_array[i] + ' - ';
                }
            }
        } else {
            facet_title = FacetsLib.build_title(indicatorState);
        }


        // let facet_title = facet_groups.reduce((result, current) => {
        //     let text = record[current];
        //     result.push(text);
        //     return result;
        // }, []).join(' - ');

        // @todo This should be move to a proper title for the overall chart
        // if (facet_title==='') {
        //     facet_title = FacetsLib.build_title(indicatorState);
        // }

        return facet_title;
    }


    public static  replace_prefix(prefix,text){
        let split = text.split(' ').reverse();

        let index = split.indexOf(prefix);

        if (index !== -1) {
            split[index] = ',,';
        }

        let _text = split.reverse().join(' ');

        _text = _text.replace(' ,,',',');

        // console.log('replace_prefix',prefix,text,_text);

        return _text;
    }

    public static  build_title(indicatorState: IndicatorState) {
        let state = indicatorState;
        let title = state.Compare.reduce((accumulator, current) => {
            // console.log('accumulator, current',accumulator, current);

            // If first, and therefore the primary group
            if (accumulator.title==='') {
                // If no filter for this group, therfore single value
                if (state.Filter[current] === undefined) {
                    accumulator.title = `${Util.deslugify(current)}`;
                }
                // If single value in filter for group
                else if (state.Filter[current].length === 1) {
                    accumulator.title = state.Filter[current][0];
                }
                // If multi value in filter for group
                else {
                    Object.assign(accumulator, {
                        title: `by ${Util.deslugify(current)}`,
                        by: true}
                    )
                }
            }
            // Is non primary group
            else {
                // console.log('not primary')

                // If is nsw (special case as has no filter), or has filter with single value
                if (current === Locations.NSW || (state.Filter[current] !== undefined && state.Filter[current].length === 1)) {
                    // console.log('single value')

                    if (accumulator.for) {
                        Object.assign(accumulator, {
                            title: `${FacetsLib.replace_prefix('and',accumulator.title)} and `,
                            and: true}
                        );
                    } else {
                        Object.assign(accumulator, {
                            title: `${accumulator.title} for `,
                            for: true, by: false, and: false}
                        );
                    }

                    accumulator.title = current === Locations.NSW?
                        `${accumulator.title}NSW`:
                        `${accumulator.title}${state.Filter[current][0]}`
                    ;
                }
                // If no filter for the group, therf
                else if (state.Filter[current] === undefined) {
                    // console.log('no filter')

                    // If the title has a 'for' in it
                    if (accumulator.for) {
                        Object.assign(accumulator, {
                            title: `${accumulator.title} and ${Util.deslugify(current)}`,
                            and: true}
                        );
                    }

                    else {
                        Object.assign(accumulator, {
                            title: `${accumulator.title} for ${Util.deslugify(current)}`,
                            for: true, by: false, and: false}
                        );
                    }
                }
                else {
                    // console.log('multi value')

                    if (accumulator.by) {
                        Object.assign(accumulator, {
                            title: `${FacetsLib.replace_prefix('and',accumulator.title)} and `,
                            and: true}
                        )
                    } else {
                        Object.assign(accumulator, {
                            title: `${accumulator.title} by `,
                            for: false, by: true, and: false}
                        )
                    }
                    accumulator.title = `${accumulator.title}${Util.deslugify(current)}`;
                }
            }

            return accumulator;
        }, {
            title: '',
            by: false,
            for: false,
            and: false,
        }
        ).title;

        return title;
    }
}
