export type hsl = [number, number, number];

export const ColourPalette: hsl[] = [
  [288.43373493975906, 97.64705882352942, 16.666666666666664],
  [287.1428571428571, 95.45454545454547, 17.254901960784313],
  [286.9879518072289, 91.20879120879121, 17.84313725490196],
  [285.7142857142857, 89.36170212765958, 18.431372549019606],
  [285.54216867469876, 85.56701030927834, 19.01960784313726],
  [284.2857142857143, 84, 19.607843137254903],
  [283.3734939759036, 80.58252427184465, 20.19607843137255],
  [282.65060240963857, 79.04761904761905, 20.58823529411765],
  [281.92771084337346, 76.1467889908257, 21.372549019607842],
  [281.2048192771084, 74.77477477477477, 21.764705882352942],
  [279.7590361445783, 72.17391304347827, 22.54901960784314],
  [279.03614457831327, 70.94017094017094, 22.941176470588236],
  [278.04878048780483, 68.33333333333333, 23.52941176470588],
  [277.5903614457831, 67.47967479674797, 24.11764705882353],
  [276.5853658536585, 65.07936507936508, 24.705882352941178],
  [275.8536585365854, 64.0625, 25.098039215686274],
  [275.1219512195122, 63.07692307692309, 25.49019607843137],
  [273.6585365853658, 61.194029850746254, 26.27450980392157],
  [272.9268292682927, 60.29411764705882, 26.666666666666668],
  [272.1951219512195, 59.42028985507246, 27.058823529411768],
  [271.4634146341463, 58.57142857142858, 27.450980392156865],
  [270.3703703703703, 56.64335664335663, 28.03921568627451],
  [269.6296296296297, 55.86206896551724, 28.431372549019606],
  [268.5365853658537, 55.405405405405396, 29.01960784313726],
  [267.40740740740745, 53.64238410596026, 29.607843137254903],
  [266.6666666666667, 52.94117647058824, 30],
  [265.9259259259259, 52.258064516129025, 30.3921568627451],
  [265.1851851851852, 51.59235668789809, 30.784313725490197],
  [264, 50, 31.372549019607842],
  [263.25, 49.38271604938272, 31.76470588235294],
  [261.75, 48.78048780487804, 32.15686274509804],
  [260.7692307692308, 46.98795180722892, 32.549019607843135],
  [260, 46.42857142857143, 32.94117647058823],
  [259.2307692307692, 45.88235294117647, 33.33333333333333],
  [258.46153846153845, 45.34883720930232, 33.72549019607843],
  [256.9230769230769, 44.827586206896555, 34.11764705882353],
  [255.78947368421055, 43.18181818181819, 34.509803921568626],
  [255, 42.69662921348315, 34.90196078431372],
  [254.2105263157895, 42.22222222222223, 35.29411764705882],
  [252.6315789473684, 41.75824175824175, 35.68627450980392],
  [251.35135135135135, 40.21739130434782, 36.07843137254902],
  [250.54054054054052, 39.784946236559136, 36.470588235294116],
  [248.9189189189189, 39.361702127659576, 36.86274509803921],
  [248.21917808219177, 38.62433862433863, 37.05882352941176],
  [247.39726027397262, 38.219895287958124, 37.450980392156865],
  [245.07042253521126, 36.787564766839374, 37.84313725490197],
  [244.2253521126761, 36.41025641025641, 38.235294117647065],
  [242.57142857142856, 35.714285714285715, 38.43137254901961],
  [241.71428571428572, 35.35353535353536, 38.82352941176471],
  [240.8695652173913, 34.673366834170864, 39.01960784313726],
  [239.14285714285714, 35, 39.21568627450981],
  [237.42857142857144, 35, 39.21568627450981],
  [235.83333333333334, 36, 39.21568627450981],
  [235, 36, 39.21568627450981],
  [233.42465753424656, 36.68341708542713, 39.01960784313726],
  [232.7027027027027, 36.99999999999999, 39.21568627450981],
  [231.20000000000002, 37.688442211055275, 39.01960784313726],
  [230.39999999999998, 37.688442211055275, 39.01960784313726],
  [228.94736842105263, 37.99999999999999, 39.21568627450981],
  [227.53246753246754, 38.693467336683405, 39.01960784313726],
  [226.75324675324674, 38.693467336683405, 39.01960784313726],
  [225.3846153846154, 39.39393939393939, 38.8235294117647],
  [224.81012658227849, 39.698492462311556, 39.01960784313725],
  [223.5, 40.4040404040404, 38.8235294117647],
  [222.75, 40.4040404040404, 38.8235294117647],
  [221.48148148148147, 41.11675126903553, 38.627450980392155],
  [220.97560975609758, 41.414141414141426, 38.82352941176471],
  [219.75903614457832, 42.131979695431475, 38.62745098039216],
  [219.03614457831327, 42.131979695431475, 38.62745098039216],
  [217.14285714285714, 42.85714285714286, 38.43137254901961],
  [216.42857142857144, 42.85714285714286, 38.43137254901961],
  [215.29411764705884, 43.58974358974359, 38.235294117647065],
  [214.88372093023256, 43.87755102040816, 38.43137254901961],
  [213.79310344827584, 44.61538461538461, 38.235294117647065],
  [213.1034482758621, 44.61538461538461, 38.235294117647065],
  [212.04545454545456, 45.360824742268036, 38.03921568627452],
  [211.36363636363635, 45.360824742268036, 38.03921568627452],
  [210.3370786516854, 46.11398963730571, 37.84313725490196],
  [209.66292134831463, 46.11398963730571, 37.84313725490196],
  [208.66666666666669, 46.87500000000001, 37.64705882352941],
  [208, 46.87500000000001, 37.64705882352941],
  [207.39130434782606, 47.91666666666667, 37.64705882352941],
  [206.7391304347826, 47.91666666666667, 37.64705882352941],
  [205.80645161290323, 48.691099476439796, 37.450980392156865],
  [205.16129032258064, 48.691099476439796, 37.450980392156865],
  [204.51612903225808, 48.691099476439796, 37.450980392156865],
  [203.61702127659575, 49.47368421052632, 37.254901960784316],
  [202.9787234042553, 49.47368421052632, 37.254901960784316],
  [202.10526315789474, 50.264550264550266, 37.05882352941177],
  [201.47368421052633, 50.264550264550266, 37.05882352941177],
  [200.625, 51.06382978723404, 36.86274509803922],
  [200, 51.06382978723404, 36.86274509803922],
  [199.37500000000003, 51.06382978723404, 36.86274509803922],
  [198.55670103092785, 51.87165775401069, 36.66666666666667],
  [197.93814432989691, 51.87165775401069, 36.66666666666667],
  [197.75510204081633, 52.68817204301075, 36.47058823529412],
  [197.14285714285714, 52.68817204301075, 36.47058823529412],
  [196.53061224489795, 52.68817204301075, 36.47058823529412],
  [195.75757575757575, 53.51351351351351, 36.274509803921575],
  [195.15151515151516, 53.51351351351351, 36.274509803921575],
  [194.4, 54.34782608695653, 36.07843137254902],
  [193.8, 54.34782608695653, 36.07843137254902],
  [193.20000000000002, 54.34782608695653, 36.07843137254902],
  [192.4752475247525, 55.1912568306011, 35.88235294117647],
  [191.8811881188119, 55.1912568306011, 35.88235294117647],
  [191.2871287128713, 55.1912568306011, 35.88235294117647],
  [190.58823529411765, 56.043956043956044, 35.68627450980392],
  [190.00000000000003, 56.043956043956044, 35.68627450980392],
  [189.32038834951456, 56.9060773480663, 35.490196078431374],
  [188.7378640776699, 56.9060773480663, 35.490196078431374],
  [188.15533980582524, 56.9060773480663, 35.490196078431374],
  [187.5, 57.77777777777777, 35.294117647058826],
  [186.92307692307693, 57.77777777777777, 35.294117647058826],
  [186.92307692307693, 57.77777777777777, 35.294117647058826],
  [186.28571428571428, 58.65921787709496, 35.09803921568628],
  [185.7142857142857, 58.65921787709496, 35.09803921568628],
  [185.1428571428571, 58.65921787709496, 35.09803921568628],
  [184.52830188679246, 59.55056179775281, 34.90196078431372],
  [183.96226415094338, 59.55056179775281, 34.90196078431372],
  [183.36448598130843, 60.451977401129945, 34.705882352941174],
  [182.80373831775702, 60.451977401129945, 34.705882352941174],
  [181.69811320754718, 60.22727272727273, 34.509803921568626],
  [181.12149532710282, 61.14285714285715, 34.31372549019608],
  [180.5607476635514, 61.14285714285715, 34.31372549019608],
  [180, 61.14285714285715, 34.31372549019608],
  [179.4495412844037, 62.28571428571429, 34.31372549019608],
  [178.9090909090909, 62.5, 34.509803921568626],
  [178.37837837837836, 62.71186440677966, 34.705882352941174],
  [177.32142857142858, 62.92134831460674, 34.90196078431372],
  [176.84210526315792, 64.04494382022472, 34.90196078431372],
  [176.84210526315792, 64.04494382022472, 34.90196078431372],
  [176.34782608695653, 64.24581005586592, 35.09803921568627],
  [175.8974358974359, 65.36312849162012, 35.09803921568628],
  [174.9152542372881, 65.55555555555556, 35.294117647058826],
  [174.45378151260505, 65.74585635359117, 35.490196078431374],
  [174, 65.93406593406593, 35.68627450980392],
  [173.55371900826444, 66.12021857923497, 35.88235294117647],
  [172.62295081967213, 66.30434782608695, 36.07843137254902],
  [172.19512195121953, 66.48648648648648, 36.27450980392157],
  [171.84, 67.56756756756755, 36.27450980392157],
  [170.95238095238093, 67.74193548387096, 36.47058823529412],
  [170.5511811023622, 67.91443850267379, 36.66666666666667],
  [170.0787401574803, 67.1957671957672, 37.05882352941177],
  [169.21875, 67.36842105263158, 37.254901960784316],
  [168.8372093023256, 67.5392670157068, 37.450980392156865],
  [168.46153846153845, 67.70833333333333, 37.64705882352941],
  [168, 67.70833333333333, 37.64705882352941],
  [167.63358778625954, 67.87564766839378, 37.84313725490196],
  [166.7175572519084, 67.17948717948717, 38.23529411764706],
  [166.36363636363635, 67.3469387755102, 38.43137254901961],
  [165.45454545454547, 66.66666666666667, 38.82352941176471],
  [165.11278195488723, 66.83417085427136, 39.01960784313726],
  [164.66165413533838, 66.16915422885573, 39.411764705882355],
  [163.88059701492537, 66.33663366336634, 39.6078431372549],
  [162.98507462686567, 65.68627450980391, 40],
  [162.53731343283584, 65.04854368932037, 40.3921568627451],
  [161.6417910447761, 64.42307692307692, 40.78431372549019],
  [161.33333333333331, 64.59330143540669, 40.98039215686275],
  [160.44444444444446, 63.98104265402843, 41.372549019607845],
  [160.29850746268656, 63.20754716981132, 41.568627450980394],
  [159.40298507462688, 62.616822429906534, 41.96078431372549],
  [158.50746268656715, 62.03703703703704, 42.35294117647059],
  [158.05970149253733, 61.46788990825689, 42.745098039215684],
  [156.99248120300751, 60.18099547511311, 43.333333333333336],
  [156.09022556390977, 59.64125560538116, 43.72549019607843],
  [155.18796992481205, 59.1111111111111, 44.11764705882353],
  [154.73684210526315, 58.590308370044056, 44.50980392156863],
  [153.63636363636363, 57.391304347826086, 45.09803921568628],
  [152.72727272727272, 56.896551724137936, 45.490196078431374],
  [151.84615384615384, 55.55555555555555, 45.88235294117647],
  [151.3846153846154, 55.08474576271186, 46.27450980392157],
  [150.2325581395349, 53.97489539748954, 46.86274509803921],
  [149.30232558139534, 53.52697095435685, 47.25490196078431],
  [148.125, 52.459016393442624, 47.843137254901954],
  [147.1875, 52.03252032520326, 48.23529411764705],
  [145.98425196850394, 51.00401606425703, 48.82352941176471],
  [144.96, 49.800796812749006, 49.21568627450981],
  [144, 49.40711462450593, 49.6078431372549],
  [142.74193548387098, 48.818897637795274, 50.19607843137255],
  [141.46341463414635, 49.003984063745015, 50.78431372549019],
  [140.1639344262295, 49.19354838709677, 51.37254901960784],
  [138.84297520661158, 49.38775510204081, 51.96078431372548],
  [137.6470588235294, 48.971193415637856, 52.352941176470594],
  [136.27118644067798, 49.166666666666664, 52.94117647058824],
  [134.87179487179486, 49.36708860759493, 53.529411764705884],
  [133.44827586206895, 49.572649572649574, 54.117647058823536],
  [131.99999999999997, 49.78354978354978, 54.70588235294118],
  [130.61946902654867, 49.34497816593886, 55.09803921568628],
  [129.10714285714286, 49.5575221238938, 55.68627450980392],
  [127.02702702702703, 49.775784753363226, 56.27450980392157],
  [125.45454545454545, 49.99999999999999, 56.86274509803921],
  [123.88888888888889, 49.54128440366972, 57.25490196078431],
  [122.24299065420561, 49.767441860465105, 57.843137254901954],
  [120, 49.999999999999986, 58.4313725490196],
  [117.77777777777779, 50.943396226415075, 58.4313725490196],
  [116.1467889908257, 51.17370892018778, 58.23529411764705],
  [114.10714285714285, 52.33644859813086, 58.03921568627452],
  [112.63157894736842, 53.27102803738318, 58.03921568627452],
  [110.6086956521739, 53.48837209302326, 57.84313725490197],
  [108.8135593220339, 54.629629629629626, 57.647058823529406],
  [107.5, 55.55555555555556, 57.647058823529406],
  [105.73770491803279, 56.481481481481474, 57.647058823529406],
  [104.03225806451613, 56.88073394495412, 57.25490196078431],
  [102.85714285714285, 57.79816513761467, 57.25490196078431],
  [100.78125, 58.18181818181818, 56.86274509803921],
  [99.69230769230768, 59.09090909090908, 56.86274509803921],
  [97.89473684210526, 60.18099547511311, 56.666666666666664],
  [96.71641791044776, 60.360360360360346, 56.470588235294116],
  [95.03649635036496, 61.434977578475355, 56.27450980392157],
  [93.85714285714286, 62.500000000000014, 56.07843137254902],
  [92.3404255319149, 62.66666666666668, 55.88235294117647],
  [91.25000000000001, 63.71681415929204, 55.68627450980392],
  [89.79310344827586, 63.87665198237885, 55.490196078431374],
  [88.78378378378379, 64.91228070175438, 55.294117647058826],
  [87.2, 65.21739130434784, 54.90196078431373],
  [86.44736842105263, 66.08695652173914, 54.90196078431373],
  [84.93506493506494, 66.37931034482759, 54.509803921568626],
  [83.69426751592357, 67.38197424892705, 54.31372549019608],
  [82.78481012658229, 67.52136752136754, 54.11764705882353],
  [81.61490683229813, 68.51063829787233, 53.92156862745098],
  [80.61349693251533, 68.77637130801688, 53.529411764705884],
  [79.63636363636363, 69.62025316455696, 53.529411764705884],
  [78.32335329341316, 69.8744769874477, 53.13725490196079],
  [77.64705882352942, 70.83333333333334, 52.94117647058824],
  [76.3953488372093, 71.07438016528927, 52.54901960784314],
  [75.51724137931035, 71.900826446281, 52.54901960784314],
  [74.6590909090909, 72.1311475409836, 52.156862745098046],
  [73.74301675977652, 73.06122448979592, 51.9607843137255],
  [72.59668508287294, 73.27935222672065, 51.5686274509804],
  [71.86813186813188, 73.38709677419355, 51.37254901960784],
  [71.02702702702702, 74.29718875502009, 51.17647058823529],
  [70, 74.4, 50.98039215686274],
  [69.25531914893617, 74.60317460317461, 50.588235294117645],
  [68.48167539267016, 75.49407114624506, 50.3921568627451],
  [67.5, 75.59055118110236, 50.19607843137255],
  [67.11340206185567, 76.37795275590551, 50.19607843137255],
  [66.12244897959184, 77.16535433070865, 49.80392156862745],
  [65.17766497461929, 77.86561264822134, 49.6078431372549],
  [64.82412060301507, 78.65612648221345, 49.607843137254896],
  [63.9, 79.36507936507937, 49.41176470588235],
  [62.98507462686567, 80.07968127490041, 49.2156862745098],
  [62.67326732673268, 80.15873015873017, 49.41176470588235],
  [61.773399014778306, 80.87649402390437, 49.2156862745098],
  [61.18226600985221, 80.87649402390437, 49.2156862745098],
  [60.588235294117645, 80.95238095238095, 49.411764705882355],
  [59.705882352941174, 80.31496062992126, 49.80392156862745],
  [59.12621359223301, 81.1023622047244, 50.19607843137255],
  [58.557692307692314, 83.19999999999999, 50.98039215686274],
  [57.99043062200957, 84.61538461538463, 51.5686274509804],
  [57.15639810426541, 86.83127572016461, 52.352941176470594],
  [56.60377358490566, 88.33333333333333, 52.94117647058824],
  [56.074766355140184, 90.67796610169493, 53.72549019607843],
  [55.51401869158879, 92.24137931034484, 54.50980392156863],
  [54.97674418604652, 93.88646288209608, 55.09803921568628],
  [54.44444444444444, 96.42857142857142, 56.07843137254902],
  [53.888888888888886, 98.18181818181819, 56.86274509803921],
];