import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SearchApiService } from '../searchapi.service';
import { UILibModule } from '../uilib/uilib.module';
import { SearchComponent } from './search.component';

const routes = [
  { path: '', component: SearchComponent }
];

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    UILibModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    SearchApiService
  ]
})
export class SearchModule { }
