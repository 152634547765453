import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Accordion} from './accordion.model';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css', './trend-table.css'],
  encapsulation: ViewEncapsulation.None, // set to none so that css is applied to innerHtml contents
})
export class AccordionComponent implements OnInit {
  @Input() mainHeader: string;
  @Input() description: string;
  @Input() bodyIsToggled: boolean;
  @Input() data: Accordion; // if no data is provided then use mainHeader and description inputs

  constructor() { }

  ngOnInit()  {
  }

  onToggleButtonClick(event: any){
    this.bodyIsToggled = !this.bodyIsToggled;
  }
}
