import { isPlatformBrowser } from "@angular/common";
import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { AppConfig } from "../app.config";

declare let gtag: Function;

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.loadScripts();
  }

  measurementID = AppConfig.settings.measurementID;
  isScriptLoaded = false;

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (isPlatformBrowser(this.platformId) && this.isScriptLoaded) {
      gtag("event", eventName, {
        event_category: eventCategory,
        event_label: eventLabel,
        event_action: eventAction,
        value: eventValue,
      });
    }
  }

  public pageEmitter(url: string): void {
    if (isPlatformBrowser(this.platformId) && this.isScriptLoaded) {
      gtag("config", this.measurementID, { page_path: url });
    }
  }

  loadScripts(): void {
    // Only load the scripts if there is a measurement id provided in the config and if its not already loaded.
    if (!this.measurementID || this.isScriptLoaded) return;

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.measurementID}`;
    script.async = true;
    document.head.appendChild(script);

    const configScript = document.createElement('script');
    configScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${this.measurementID}');
    `;

    document.head.appendChild(configScript);
    this.isScriptLoaded = true;
  }
}
