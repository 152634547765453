// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-viewer--canvas {
  flex: 1 1 auto;
  padding: 16px;
  overflow-y: scroll;
  /* background-color:burlywood; */
}

.footer-chart-legend {
  flex: 0 1 auto;
  /* background-color: chartreuse; */
  width:100%;
}`, "",{"version":3,"sources":["webpack://./src/app/indicator/indicatorViews/trend-view/trend-view.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,kBAAkB;EAClB,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,kCAAkC;EAClC,UAAU;AACZ","sourcesContent":[".chart-viewer--canvas {\r\n  flex: 1 1 auto;\r\n  padding: 16px;\r\n  overflow-y: scroll;\r\n  /* background-color:burlywood; */\r\n}\r\n\r\n.footer-chart-legend {\r\n  flex: 0 1 auto;\r\n  /* background-color: chartreuse; */\r\n  width:100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
