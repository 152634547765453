import {Directive, ElementRef, Input} from '@angular/core';

/**
 * This directive takes in a url as a parameter and will set target as _blank if it is an external link or if it is a local resource file.
 * For internal links it will also append '#' to the start since HashLocationStrategy is used for routing
 */
@Directive({
  selector: '[customHref]'
})
export class CustomHrefDirective {

  @Input() set customHref(url: string) {
    if (this.isResourceOrExternal(url)) {
      this.el.nativeElement.href = url;
      this.el.nativeElement.target = "_blank";
    } else if(url.startsWith('/')){
      this.el.nativeElement.href = url;
    } else {
      this.el.nativeElement.href = `/${url}`;
    }
  }

  constructor(private el: ElementRef) { }

  isResourceOrExternal(url): boolean {
    return this.isResourceFile(url) || this.isExternalLink(url);
  }

  isResourceFile(url): boolean {
    return (/^\/assets\/resources/.test(url));
  }

  isExternalLink(url) {
    let host = window.location.hostname;
    return host !== this.getLinkHost(url);
  }

  getLinkHost(url) {
    if (/^https?:\/\//.test(url)) { // Absolute URL.
      // The easy way to parse an URL, is to create <a> element.
      // @see: https://gist.github.com/jlong/2428561
      let parser = document.createElement('a');
      parser.href = url;

      return parser.hostname;
    }
    else { // Relative URL.
      return window.location.hostname;
    }
  }

}
