import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lite-accordion',
  templateUrl: './lite-accordion.component.html',
  styleUrls: ['./lite-accordion.component.css']
})
export class LiteAccordionComponent implements OnInit {
    @Input() mainHeader: string;
    @Input() bodyIsToggled: boolean;

  constructor(){
  }

  ngOnInit()  {
  }

  onToggleButtonClick(event: any){
    this.bodyIsToggled = !this.bodyIsToggled;
  }

}
