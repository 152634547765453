import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { AppConfig } from '../../app.config';
import { Paginator} from './paginator.model';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent {
  @Output() onPagination = new EventEmitter<number>();

  public paginator: Paginator;
  public pageSize: number;

  constructor() {
    this.reset();
  }

  public reset() {
    this.paginator = new Paginator();
    this.pageSize = AppConfig.settings.searchSettings.pageSize;
  }

  public Refresh(totalRecords: number) {

    this.paginator.totalPages = Math.ceil(totalRecords / this.pageSize);
    let first: number = 1;
    const last: number = this.paginator.totalPages;

    if (this.paginator.totalPages == 1) {
      this.paginator.pages = ['1'];
    }

    if (this.paginator.currentPage == this.paginator.totalPages) {
      return;
    }

    this.paginator.pages = [];
    if (this.paginator.currentPage > AppConfig.settings.searchSettings.firstSetOfPages) {
      first = this.paginator.currentPage - AppConfig.settings.searchSettings.firstSetOfPages + 1;
    }
    const calculatedPages = first + (this.paginator.totalPages <= AppConfig.settings.searchSettings.firstSetOfPages
      ? this.paginator.totalPages
      : AppConfig.settings.searchSettings.firstSetOfPages);
    for (let page = first; page < calculatedPages; page++) {
      this.paginator.pages.push(page.toString());
    }

    if (this.paginator.totalPages >= AppConfig.settings.searchSettings.firstSetOfPages) {
      this.paginator.pages.push('...');
      this.paginator.pages.push(last.toString());
    }
  }

  public paginate(pageNo) {
    if (this.onPagination && pageNo != '...') {
        const page = parseInt(pageNo, 10);
        if (page != this.paginator.currentPage) {
          this.paginator.currentPage = page;
          const pageOffset = this.pageSize * (this.paginator.currentPage - 1);
          this.onPagination.emit(pageOffset);
        }
      }
  }
}
