import { Injectable } from "@angular/core";
import { IndicatorState, StatsIndicatorMeasure } from "./indicator.state";
import { Util } from "../shared/util";
import { TransformService } from "./indicatorViews/transform.service";
import * as _ from "underscore";
import { DecimalPipe } from "@angular/common";
import { Locations } from "./constraints";
import {MeasureService} from "../services/measure.service";

@Injectable({
  providedIn: "root",
})
export class IndicatorTableService {
  measureService : MeasureService;
  constructor(
    private transformService: TransformService,
    private decimalPipe: DecimalPipe
  ) {
    this.measureService = new MeasureService();
  }

  generateHtmlTable(
    state: IndicatorState,
    populateAllCells: boolean = false
  ): string {
    const selectedMeasures: string[] = state.Measure;
    var filteredMeasures = this.measureService.get_filtered_measures(state);
    const allMeasures = filteredMeasures.map((m) => m.name);
    const showCi = true; // always show confidence intervals in table

    let filter_data = state.FilteredData;
    if (filter_data === undefined) {
      return;
    }

    this.transformService.onStateChange(state);
    let facet_groups = this.transformService.getFacetGroups();
    let facet_ids = this.transformService.getFacetIds();

    let html = "";

    // Get the groups that are not included in the 'compare'
    let not_compared = state.Groups.filter((group) => {
      return !state.Compare.includes(group);
    });

    // Create an array that contains the group ordering, non compared followed by the compared
    const order = not_compared.concat(state.Compare);

    // Sort data based on compare
    filter_data.sort((a: any, b: any) => {
      for (var i = 0; i < order.length; i++) {
        const groupName = order[i];
        if (groupName === "Period") {
          if (a.Period !== b.Period) {
            return b.Period - a.Period;
          }
        } else {
          if (a[groupName + "Position"] !== b[groupName + "Position"]) {
            return a[groupName + "Position"] - b[groupName + "Position"];
          }
        }
      }
    });

    // Remove groups/locations position
    filter_data.forEach((data) => {
      order.forEach((groupName) => delete data[groupName + "Position"]);
      if (state.Location !== Locations.NSW) {
        delete data[state.Location + "Position"];
      }
    });

    // Sort the fields of each record according to the 'compare' property values
    filter_data.forEach((record, index) => {
      // Create an object with the fields in the correct order
      const ordered = order.reduce((accumulator, field) => {
        accumulator[field] = record[field];
        return accumulator;
      }, {});

      // Order the measures values as per the 'measures' and add confidence intervals if exist for selected measures
      const measures = selectedMeasures.reduce((result, current) => {
        result[current] = record[current];
        let confidenceInterval = state.ConfidenceIntervals[current];
        if (!!confidenceInterval) {
          result[confidenceInterval.lower.name] =
            record[confidenceInterval.lower.name];
          result[confidenceInterval.upper.name] =
            record[confidenceInterval.upper.name];
        }
        return result;
      }, {});

      // remove measures from records so that measures are added last
      Object.keys(measures).forEach((key) => {
        delete record[key];
      });

      // Merge any other non ordered fields back into the record, measures are added last
      Object.assign(ordered, record, measures);

      // Replace the item with the new object
      filter_data[index] = ordered;
    });

    // Start a table
    html += "<table>";

    // Get the first row, used to setup the table header row
    const first = filter_data[0];
    const columns = Object.keys(first);

    const columns_to_right_justify: string[] = filteredMeasures.map(
      (m) => m.name
    );

    // For each facet
    facet_ids.forEach((facet) => {
      // Start a header row
      html += `<thead><tr>`;

      // For each property in the first row
      columns.forEach((key) => {
        // If the property is anything other than a measure,
        // or if is a selected measure
        // or if measure is confidence interval and parent measure selected
        if (
          !allMeasures.includes(key) ||
          selectedMeasures.includes(key) ||
          (showCi &&
            this.isCiMeasure(key, filteredMeasures) &&
            this.isParentMeasureSelected(key, state))
        ) {
          let _class = columns_to_right_justify.includes(key)
            ? ' class="right"'
            : "";

          const measure = filteredMeasures.find((item) => item.name === key);
          let label = measure !== undefined ? measure.label : key;

          if (key !== "") {
            html += `<th${_class}>${this.pretty(label)}</th>`;
          }
        }
      });

      // Close the header row
      html += `</tr></thead>`;

      // Start the table body
      html += `<tbody>`;

      // Used to record the state of the previous property as we loop,
      // this is used to alternate cell style when a value changes.
      const previous = {};

      // create columnType map to indicate field type of a column
      let columnType: Map<string, "integer" | "decimal" | "others"> = new Map();
      for (const column of columns) {
        let integers = 0;
        let decimals = 0;
        let others = 0;
        for (const record of filter_data) {
          let value = record[column];
          if (_.isNumber(value)) {
            if (Number.isInteger(value)) {
              integers++;
            } else {
              decimals++;
            }
          } else {
            others++;
          }
        }
        if (integers > 0) {
          columnType.set(column, "integer");
        }
        if (decimals > 0) {
          columnType.set(column, "decimal");
        }
        if (others > 0) {
          columnType.set(column, "others");
        }
      }

      // For each record
      filter_data.forEach((record) => {
        // Generate the id for the facet from the record
        const id = (facet_groups.reduce(
          (accumulator: string[], field, index) => {
            accumulator.push(Util.slugify(record[field]));
            return accumulator;
          },
          []
        ) as string[]).join("--");

        // If the records facet id matches the current facet loop, or the facet is 'all'
        if (id === facet || facet === "all") {
          // Add a row
          html += `<tr>`;

          // For each property in the record
          Object.keys(record).forEach((key) => {
            // If populateAllCells flag is true
            // or if this property value is not in the previous record,
            // or if the property is a measure
            if (
              populateAllCells ||
              previous[key] === undefined ||
              previous[key] !== record[key] ||
              allMeasures.includes(key)
            ) {
              previous[`${key}-odd`] = !previous[`${key}-odd`];

              if (
                !allMeasures.includes(key) ||
                selectedMeasures.includes(key) ||
                (showCi &&
                  this.isCiMeasure(key, filteredMeasures) &&
                  this.isParentMeasureSelected(key, state))
              ) {
                const classes = [];
                columns_to_right_justify.includes(key)
                  ? classes.push("right")
                  : null;
                previous[`${key}-odd`] ? null : classes.push("alt");

                let class_attribute =
                  classes.length > 0 ? ` class="${classes.join(" ")}"` : "";

                // TODO: there is an issue of empty key, needs to be fixed
                if (key !== "") {
                  if (columnType.get(key) === "integer") {
                    const val = this.decimalPipe.transform(
                      record[key],
                      "1.0-0"
                    );
                    html += `<td${class_attribute}>${val}</td>`;
                  } else if (columnType.get(key) === "decimal") {
                    const val = this.decimalPipe.transform(
                      record[key],
                      "1.1-1"
                    );
                    html += `<td${class_attribute}>${val}</td>`;
                  } else {
                    // string
                    html += `<td${class_attribute}>${record[key]}</td>`;
                  }
                }
              }

              previous[key] = record[key];
            } else {
              const classes = [];
              previous[`${key}-odd`] ? null : classes.push("alt");

              let class_attribute =
                classes.length > 0 ? ` class="${classes.join(" ")}"` : "";
              html += `<td${class_attribute}></td>`;
            }
          });

          // Close the row
          html += `</tr>`;
        }
      });

      // Add a shim row
      html += `<tr class="blank-row"><td colspan="0"></td></tr></tbody>`;
    });

    // Close the table
    html += `</table>`;

    return html;
  }

  pretty(text: string) {
    if (text === "rate-ci-lower") {
      return "C.I. Lower";
    }
    if (text === "rate-ci-upper") {
      return "C.I. Upper";
    }

    return Util.deslugify(text);
  }

  isCiMeasure(name: string, filteredMeasures: StatsIndicatorMeasure[]) {
    let measure = filteredMeasures.find((m) => m.name === name);
    return !!measure && measure.ci;
  }

  isParentMeasureSelected(ciName: string, state: IndicatorState) {
    const selectedMeasures = state.Measure;
    for (const measure of selectedMeasures) {
      const confidenceInterval = state.ConfidenceIntervals[measure];
      if (
        !!confidenceInterval &&
        (confidenceInterval.upper.name === ciName ||
          confidenceInterval.lower.name === ciName)
      ) {
        return true;
      }
    }
    return false;
  }
}
