// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordian--button {
    position: absolute;
    display: inline-block;
    right: 0;
    width: 48px;
    height: 48px;
    bottom: 3px;
    /* background-color: lightseagreen; */
    cursor: pointer;

    line-height: 48px;
    text-align: center;

    font-family: 'health-stats-icons' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    transform: rotate(180deg);
}

.accordian--button:before {
    content: "\\e901";
    font-size: 32px;
}

p {
  max-width: 100%;
}

.section-title, .sub-section-title, .sub-sub-section-title {
  padding-top: 0;
}

.section-body p {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
}

.sub-section-body p, .sub-sub-section-body p {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0.3rem;
}

.section-body, .sub-section-body, .sub-sub-section-body {
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/uilib/accordion/accordion.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,WAAW;IACX,qCAAqC;IACrC,eAAe;;IAEf,iBAAiB;IACjB,kBAAkB;;IAElB,4CAA4C;IAC5C,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;IACpB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".accordian--button {\r\n    position: absolute;\r\n    display: inline-block;\r\n    right: 0;\r\n    width: 48px;\r\n    height: 48px;\r\n    bottom: 3px;\r\n    /* background-color: lightseagreen; */\r\n    cursor: pointer;\r\n\r\n    line-height: 48px;\r\n    text-align: center;\r\n\r\n    font-family: 'health-stats-icons' !important;\r\n    font-style: normal;\r\n    font-weight: normal;\r\n    font-variant: normal;\r\n    text-transform: none;\r\n    transform: rotate(180deg);\r\n}\r\n\r\n.accordian--button:before {\r\n    content: \"\\e901\";\r\n    font-size: 32px;\r\n}\r\n\r\np {\r\n  max-width: 100%;\r\n}\r\n\r\n.section-title, .sub-section-title, .sub-sub-section-title {\r\n  padding-top: 0;\r\n}\r\n\r\n.section-body p {\r\n  padding-top: 0;\r\n  margin-top: 0;\r\n  padding-bottom: 0;\r\n}\r\n\r\n.sub-section-body p, .sub-sub-section-body p {\r\n  padding-top: 0;\r\n  margin-top: 0;\r\n  padding-bottom: 0.3rem;\r\n}\r\n\r\n.section-body, .sub-section-body, .sub-sub-section-body {\r\n  padding-bottom: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
