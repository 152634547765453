// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-list--title,
.link-list--subtitle {
    margin-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/app/uilib/list-set/list-set.component.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;AACrB","sourcesContent":[".link-list--title,\r\n.link-list--subtitle {\r\n    margin-left: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
