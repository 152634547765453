import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.css']
})
export class LegendComponent implements OnInit {
  @Input() bodyIsToggled: boolean;
  legendHeight: string;
  legendGroups: any;

  constructor() { 
    //this.setLegendHeight();
  }

  // TODO this is not working well
  setLegendHeight() {
    if (this.bodyIsToggled) {
      this.legendHeight = '48px';
    } else {
      this.legendHeight = '140px';
    }
  }

  ngOnInit() {
  }

  onToggleButtonClick(event: any){
    this.bodyIsToggled = !this.bodyIsToggled;
    //this.setLegendHeight();
    if (this.bodyIsToggled) {

    } else {

    }
  }


  onStateUpdated(data: any) {

    // Declare variable to be used to populate the template for this component
    let groups = [];
    let group: any;
    // Get the traces from the first facet
    //let data = options.faceted_plot_data[0].traces;
    //debugger

    data.forEach(record => {
        if (record.name !== 'Confidence') {
            // If there is no entry for this group
            if (groups.find( current => current.title === record.legendgroup ) === undefined ) {
              groups.push({
                    title: record.legendgroup,
                    items: [],
                });
            }

            // Get the current group
            group = groups.find( current => current.title === record.legendgroup );
            // If there is no entry for this item
            if (group.items.find( current => current.name === record.text[0] ) === undefined ) {
                group.items.push({
                    name: record.text[0][0],
                    colour: record.marker.color,
                });
            }
        }
    });

    // Find group with no title
    group = groups.find( current => current.title === '-' );

    // Remove the group title from the untitled group
    if (group !== undefined) {
        delete group.title;
    }
    // If there is only a single item in each group
    if (groups[0].items.length === 1) {
      groups.forEach( grp => {
            if (grp.title!==undefined) {
                // Make the legend item name equal to the legend group name
                grp.items[0].name = grp.title;
            }

            // Remove the group title
            delete grp.title;
        });
    }

    // If their is only 1 group
    if (groups.length === 1) {
        // Remove the title, this will remove the grouping title in the UI
        delete groups[0].title;
    }
    this.legendGroups = groups;
  }
}
