import {Component, Input, OnInit} from '@angular/core';
import {HorizontalCard} from './horizontal-card.model';

@Component({
  selector: 'horizontal-card',
  templateUrl: './horizontal-card.component.html',
  styleUrls: ['./horizontal-card.component.css']
})
export class HorizontalCardComponent implements OnInit {
  @Input() card: HorizontalCard;
  
  constructor() { }

  ngOnInit() {
  }

}
