import { Injectable } from '@angular/core';
import { Util } from 'src/app/shared/util';
import { IndicatorState } from '../indicator.state';
import { Locations } from "src/app/indicator/constraints";

@Injectable({
  providedIn: 'root',
})
export class TransformService {
  state: IndicatorState;

  constructor() {
  }

  onStateChange(state: IndicatorState) {
    this.state = state;
  }

  // Return the groups/dimensions that will be used as facets in the chart, ie groups that are not in compare
  getFacetGroups() {
    const state = this.state;
    const groups =
      state.Location === Locations.NSW
        ? state.Groups
        : [state.Location, ...state.Groups];

    let facet_groups = groups.filter(
      (value, index) => !state.Compare.includes(value)
    );

    return facet_groups;
  }

  getFacetIds() {
    const state = this.state;
    let facet_values = [];
    let facet_groups = this.getFacetGroups();

    // For each facet group the values as determined by the filter
    facet_groups.forEach((value: any, index) => {
      if (this.state.Filter[value] !== undefined) {
        facet_values.push(this.state.Filter[value]);
      }
    });

    let facet_ids = Util.equals(facet_values, [])
      ? ['all']
      : Util.cartesian(facet_values).reduce((result, current) => {
          const value = Util.slugify(current.join('--'));
          result.push(value);
          return result;
        }, []);

    return facet_ids;
  }

  getFacetId(record) {
    // console.log('record', record);
    let facet_groups = this.getFacetGroups();
    let facet_id = (facet_groups.reduce((result: string[], current, index) => {
      result.push(record[current[index]]);
      return result;
    }, []) as string[]).join('--');

    facet_id = Util.slugify(facet_id);
    return facet_id;
  }

  getFacetTitle(record) {
    let facet_groups = this.getFacetGroups();
    let facet_title = (facet_groups.reduce(
      (result: string[], current, index) => {
        let text = record[current[index]];
        result.push(text);
        return result;
      },
      []
    ) as string[]).join(' - ');

    // @todo This should be move to a proper title for the overall chart
    if (facet_title === '') {
      facet_title = this.buildTitle();
    }

    return facet_title;
  }

  replacePrefix(prefix, text) {
    const split = text.split(' ').reverse();
    const index = split.indexOf(prefix);
    if (index !== -1) {
      split[index] = ',,';
    }

    return split.reverse().join(' ').replace(' ,,', ',');
  }

  buildTitle() {
    const state = this.state;
    let title = this.state.Compare.reduce(
      (accumulator, current) => {
        // console.log('accumulator, current',accumulator, current);

        // If first, and therefore the primary group
        if (accumulator.title === '') {
          // If no filter for this group, therfore single value
          if (this.state.Filter[current] === undefined) {
            accumulator.title = `${Util.deslugify(current)}`;
          } else if (this.state.Filter[current].length === 1) {
            accumulator.title = this.state.Filter[current][0];
          } else {
            Object.assign(accumulator, {
              title: `by ${Util.deslugify(current)}`,
              by: true,
            });
          }
        } else {

          // If is nsw (special case as has no filter), or has filter with single value
          if (
            current === Locations.NSW ||
            (this.state.Filter[current] !== undefined &&
              this.state.Filter[current].length === 1)
          ) {
            // console.log('single value')

            if (accumulator.for) {
              Object.assign(accumulator, {
                title: `${this.replacePrefix('and', accumulator.title)} and `,
                and: true,
              });
            } else {
              Object.assign(accumulator, {
                title: `${accumulator.title} for `,
                for: true,
                by: false,
                and: false,
              });
            }

            accumulator.title =
              current === Locations.NSW
                ? `${accumulator.title}NSW`
                : `${accumulator.title}${this.state.Filter[current][0]}`;
          } else if (this.state.Filter[current] === undefined) {
            // console.log('no filter')

            // If the title has a 'for' in it
            if (accumulator.for) {
              Object.assign(accumulator, {
                title: `${accumulator.title} and ${Util.deslugify(current)}`,
                and: true,
              });
            } else {
              Object.assign(accumulator, {
                title: `${accumulator.title} for ${Util.deslugify(current)}`,
                for: true,
                by: false,
                and: false,
              });
            }
          } else {
            // console.log('multi value')

            if (accumulator.by) {
              Object.assign(accumulator, {
                title: `${this.replacePrefix('and', accumulator.title)} and `,
                and: true,
              });
            } else {
              Object.assign(accumulator, {
                title: `${accumulator.title} by `,
                for: false,
                by: true,
                and: false,
              });
            }
            accumulator.title = `${accumulator.title}${Util.deslugify(
              current
            )}`;
          }
        }

        return accumulator;
      },
      {
        title: '',
        by: false,
        for: false,
        and: false,
      }
    ).title;

    return title;
  }
}
