export enum Locations {
  NSW = "NSW",
  LHD = "LHD",
  LGA = "LGA",
  PHN = "PHN",
  SA2 = "SA2",
  SA3 = "SA3",
  HOS = "HOS",
  OTH = "OTH"
}

export const LocationsArray: string[] = [
  Locations.NSW,
  Locations.PHN,
  Locations.LHD,
  Locations.LGA,
  Locations.HOS,
  Locations.SA2,
  Locations.SA3,
  Locations.OTH,
];

export const LocationsArrayExceptNSW: string[] = [
  Locations.PHN,
  Locations.LHD,
  Locations.LGA,
  Locations.HOS,
  Locations.SA2,
  Locations.SA3,
  Locations.OTH,
];

export enum ViewType {
  Trend = "Trend",
  BarHorizontal = "BarHorizontal",
  Map = "Map",
  Table = "Table",
}

export const ViewTypesArray: string[] = [
  ViewType.Trend,
  ViewType.BarHorizontal,
  ViewType.Map,
  ViewType.Table,
];

export enum VisualisationDownloadFormat {
  CSV = "csv",
  PNG = "png",
  SVG = "svg"
}

export enum ReportDownloadFormat {
  NA = "none",
  PDF = "pdf",
  DOC = "doc"
}
