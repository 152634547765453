import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { AppConfig } from "../app.config";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class AppInsightsService {
  private routerSubscription: Subscription;
  private appInsights: ApplicationInsights;

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: object) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: AppConfig.settings.instrumentationKey,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
      },
    });
    if (isPlatformBrowser(this.platformId)) {
      this.appInsights.loadAppInsights();
    }
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.logRouteEvent(event);
      });
  }

  logRouteEvent(routeEvent: NavigationEnd) {
    this.logPageView(routeEvent.url, routeEvent.urlAfterRedirects);
  }

  logPageView(name?: string, uri?: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.appInsights.trackPageView({ name, uri });
    }
  }
}
