import {Component, Input, OnInit} from '@angular/core';
import {LocationSummary} from './location-summary.model';

@Component({
  selector: 'location-summary',
  templateUrl: './location-summary.component.html',
  styleUrls: ['./location-summary.component.css']
})
export class LocationSummaryComponent implements OnInit {
  @Input() location: LocationSummary;
  @Input() locationType: string;

  constructor() { }

  ngOnInit() {
  }

}
