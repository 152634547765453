import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HsResource} from '../healthstatsplus/healthstatsplus-resource';
import {BreadcrumbService} from '../app-breadcrumb/breadcrumb.service';
import {BreadcrumbConfig} from '../app-breadcrumb/breadcrumb.config';
import {map, switchMap, tap} from 'rxjs/operators';
import {StatsService} from '../stats.service';
import {RelatedResource} from '../shared/Models/related-resource.model';
import { MetadataService } from '../metadata.service';

@Component({
  selector: 'publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.css']
})
export class PublicationComponent implements OnInit {
  resourceType: string;
  pageTitle: string;
  pageDescription: string;
  resources: RelatedResource[];

  constructor(private route: ActivatedRoute,
              private breadcrumbService: BreadcrumbService,
              private statsService: StatsService,
              private metadataService: MetadataService) { }

  ngOnInit() {
    this.route.params.pipe(
      map(params => params['resourceType']),
      tap(resourceType => {
        this.resourceType = resourceType;
        this.pageDescription = HsResource[this.resourceType].pageDescription;
        this.pageTitle = HsResource[this.resourceType].title;

        this.breadcrumbService.updateBreadCrumbs([
          BreadcrumbConfig.HEALTHSTATS_PLUS,
          { name: this.pageTitle }
        ]);
        if (this.metadataService) {
          this.metadataService.updateMetadata({
            title: this.pageTitle,
            description: this.pageDescription
          });
        }
      }),
      switchMap(resourceType => this.statsService.loadPublicationResource(resourceType))
    ).subscribe(resources => this.resources = resources);
  }
}
