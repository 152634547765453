import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ContinuumGroup} from './continuum.model';

@Component({
  selector: 'continuum-accordion',
  templateUrl: './continuum-accordion.component.html',
  styleUrls: ['./continuum-accordion.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContinuumAccordionComponent implements OnInit {
  // Display accordion with a more compact style
  @Input() compactUI: boolean = false;
  @Input() data: ContinuumGroup;
  isExpanded: boolean = false;
  isData: boolean;

  constructor() { }

  ngOnInit() {
    this.isData = this.data.subGroups && this.data.subGroups.length > 0 || false;
  }

  toggleAccordion() {
      this.isExpanded = !this.isExpanded;
  }

}
