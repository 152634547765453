import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlossaryComponent } from './glossary.component';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';

const routes = [
  { path: '', component: GlossaryComponent }
];

@NgModule({
  declarations: [GlossaryComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
  ]
})
export class GlossaryModule { }
