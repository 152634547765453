import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Util } from 'src/app/shared/util';

@Component({
  selector: 'app-list-set',
  templateUrl: './list-set.component.html',
  styleUrls: ['./list-set.component.css']
})
export class ListSetComponent implements OnInit, OnChanges {
  @Input() topicsList: any[];
  @Input() route: string;

  alphabet: string[];
  firstLettersArray: string[] = [];

  constructor() { }

  ngOnInit() {
    this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.topicsList) {
      this.firstLettersArray = [];
      this.topicsList.forEach( listItem => {
        this.firstLettersArray.push(listItem.title[0].toUpperCase());
      })
      this.firstLettersArray = Util.deduplicate(this.firstLettersArray);
      this.firstLettersArray.sort();
    }
  }

  getActiveLetters(topics: any[]): string[] {
    let activeLetters: string[] = topics.map(topic => topic.title[0].toUpperCase());
    activeLetters = Util.deduplicate(activeLetters);
    return activeLetters.sort();
  }

  getTopicsForLetter(letter: string) {
    let topics = [];
    this.topicsList.forEach( listItem => {
      if (listItem.title[0].toLowerCase() === letter.toLowerCase() ) {
        topics.push(listItem);
      }
    })
    return topics;
  }
}
