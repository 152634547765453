// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-buttons button {
  font-size: .875rem;
}
.group-buttons {
    display: flex;
    justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/app/uilib/chart-viewer-filter/group-container/group-container.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".group-buttons button {\r\n  font-size: .875rem;\r\n}\r\n.group-buttons {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
