import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Locations } from 'src/app/indicator/constraints';

@Component({
  selector: 'app-lite-button-menu',
  templateUrl: './lite-button-menu.component.html',
  styleUrls: ['./lite-button-menu.component.css'],
})
export class LiteButtonMenuComponent implements OnInit {
  @Input() buttonTitle: string;
  @Input() zIndex: string;
  @Input() items: any;
  @Input() expendedFirstTime: boolean;
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  Locations: Locations;
  expended = false;

  ngOnInit() {}

  onToggleButtonClick(event: any) {
    this.expended = !this.expended;
    this.expendedFirstTime = false;
  }

  onItemClicked(id: any) {
    this.itemSelected.emit(id);
    this.expended = !this.expended;
    this.expendedFirstTime = false;
  }
}
