import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.css']
})
export class SelectButtonComponent implements OnInit {
  @Input() options: string[];
  @Output() buttonClick: EventEmitter<string> = new EventEmitter<string>();
  isOpen: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  optionClicked(option: string) {
    this.isOpen = false;
    this.buttonClick.emit(option);
  }

}
