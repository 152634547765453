// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media (min-width:992px) {
  .nsw-container,
  .nsw-header__container,
  .nsw-navigation__list {
    max-width: 1400px;
    padding-left: var(--xl-margin);
    padding-right: var(--xl-margin);
  }

  .nsw-navigation__list {
    left: -16px;
  }
}


.nsw-header__logo {
  width: 189px;
  height: auto;
}

@media (min-width:576px) {
  .nsw-header__logo {
    /* width: 10.5rem; */
    width: 243px;
    /* height: 3.4375rem */
  }
}

form input.nsw-search__input
{
  height: 4.5rem;
}

@media (min-width:991px) {
  .nsw-header__logo {
    /* width: 10.5rem; */
    width: 319px;
    /* height: 3.4375rem */

  }

  form input.nsw-search__input
  {
    height: 48px;
  }
}



.nsw-search__input:focus {
  outline: none;
  outline-offset: 0;
}

.nsw-search__input:focus-visible {
  outline: 3px solid #0085b3;
  outline-offset: 0;
}

`, "",{"version":3,"sources":["webpack://./src/app/app-header/app-header.component.css"],"names":[],"mappings":";AACA;EACE;;;IAGE,iBAAiB;IACjB,8BAA8B;IAC9B,+BAA+B;EACjC;;EAEA;IACE,WAAW;EACb;AACF;;;AAGA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE;IACE,oBAAoB;IACpB,YAAY;IACZ,sBAAsB;EACxB;AACF;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE;IACE,oBAAoB;IACpB,YAAY;IACZ,sBAAsB;;EAExB;;EAEA;;IAEE,YAAY;EACd;AACF;;;;AAIA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB","sourcesContent":["\r\n@media (min-width:992px) {\r\n  .nsw-container,\r\n  .nsw-header__container,\r\n  .nsw-navigation__list {\r\n    max-width: 1400px;\r\n    padding-left: var(--xl-margin);\r\n    padding-right: var(--xl-margin);\r\n  }\r\n\r\n  .nsw-navigation__list {\r\n    left: -16px;\r\n  }\r\n}\r\n\r\n\r\n.nsw-header__logo {\r\n  width: 189px;\r\n  height: auto;\r\n}\r\n\r\n@media (min-width:576px) {\r\n  .nsw-header__logo {\r\n    /* width: 10.5rem; */\r\n    width: 243px;\r\n    /* height: 3.4375rem */\r\n  }\r\n}\r\n\r\nform input.nsw-search__input\r\n{\r\n  height: 4.5rem;\r\n}\r\n\r\n@media (min-width:991px) {\r\n  .nsw-header__logo {\r\n    /* width: 10.5rem; */\r\n    width: 319px;\r\n    /* height: 3.4375rem */\r\n\r\n  }\r\n\r\n  form input.nsw-search__input\r\n  {\r\n    height: 48px;\r\n  }\r\n}\r\n\r\n\r\n\r\n.nsw-search__input:focus {\r\n  outline: none;\r\n  outline-offset: 0;\r\n}\r\n\r\n.nsw-search__input:focus-visible {\r\n  outline: 3px solid #0085b3;\r\n  outline-offset: 0;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
