import { Injectable } from "@angular/core";
import { IAppConfig } from "./app.config.model";

declare const WebConfig: IAppConfig;

export const configFactory = (config: AppConfig): (() => void) => {
  return () => config.load();
};

export const platformInitFactory = () => {
  window["WebConfig"];
};

@Injectable({
  providedIn: "root",
})
export class AppConfig {

  private config: IConfig = {
    localDateFormat: "dd/MM/yyyy",
    localDateTimeFormat: "dd/MM/yyyy HH:mm:ss",

    defaultPageSize: 15,
    pageSizeOptions: [15, 25, 50, 100],
    pageLinkSizeOption: 5,

    defaultAddNewLabel: "New",
    defaultCreateDraftLabel: "Create New Version",
    defaultCopyLabel: "Copy",
    defaultReviseLabel: "Modify",
    defaultRejectLabel: "Reject",
    defaultDeleteLabel: "Delete",
    defaultCloseLabel: "Close",
    defaultCancelLabel: "Cancel",
    defaultArchiveLabel: "Archive",
    defaultBackToListLabel: "Back to list",
    defaultApproveLabel: "Approve",
    defaultSaveLabel: "Save",
    defaultSubmitLabel: "Submit",
    defaultResubmitLabel: "Re-submit",
    defaultDownloadLabel: "Download",

    defaultReactivateLabel: "Reactivate",
    defaultDeactivateLabel: "Deactivate",

    defaultBlockUIStartMessage: "Processing...",
  };

  constructor() {}

  private static _config: IAppConfig;

  static get settings(): IAppConfig {
    return this._config;
  }

  private _createConfig(config: any): IAppConfig {
    const _config = <IAppConfig>config;
    AppConfig._config = _config;
    return _config;
  }

  load(): void {
    if (WebConfig?.isServed) {
      this._createConfig(WebConfig);
    }
  }

  getConfig(): IConfig {
    return this.config;
  }
}

export interface IConfig {
  localDateFormat: string;
  localDateTimeFormat: string;

  defaultPageSize: number;
  pageSizeOptions: any[];
  pageLinkSizeOption: number;

  defaultAddNewLabel: string;
  defaultCreateDraftLabel: string;
  defaultCopyLabel: string;
  defaultReviseLabel: string;
  defaultRejectLabel: string;
  defaultDeleteLabel: string;
  defaultCloseLabel: string;
  defaultCancelLabel: string;
  defaultArchiveLabel: string;
  defaultBackToListLabel: string;
  defaultApproveLabel: string;
  defaultSaveLabel: string;
  defaultSubmitLabel: string;
  defaultResubmitLabel: string;
  defaultDownloadLabel: string;

  defaultReactivateLabel: string;
  defaultDeactivateLabel: string;

  defaultBlockUIStartMessage: string;
}
