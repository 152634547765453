import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "./app.config";
import { SearchCriteria } from "./search/search.model";
import { SearchResult } from "./uilib/search-result-page/searchresultpage.model";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SearchApiService {
  private _baseApiUrl: string = AppConfig.settings.server.apiSearch;

  constructor(public httpClient: HttpClient) {}

  public async search(searchCriteria: SearchCriteria): Promise<SearchResult> {
    return await lastValueFrom(this.httpClient
      .get<SearchResult>(`${this._baseApiUrl}/search?phrase=${searchCriteria.searchTerm}&pageSize=${searchCriteria.pageSize}&pageOffset=${searchCriteria.pageOffset}`));
  }
}

