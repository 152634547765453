import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UILibModule } from '../uilib/uilib.module';
import { TopicsComponent } from './topics.component';

const routes = [
  { path: '', component: TopicsComponent }
];

@NgModule({
  declarations: [
    TopicsComponent
  ],
  imports: [
    CommonModule,
    UILibModule,
    RouterModule.forChild(routes)
  ]
})
export class TopicsModule { }
