// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-card {
  margin-bottom: 24px;
}

.horizontal-card .nsw-card__content {
  border-top: none;
}

@media (min-width:576px) {
  .horizontal-card.nsw-card--media {
    max-width: 100%;
    flex-direction: row;
  }
  .horizontal-card .nsw-card__content, .horizontal-card .nsw-card__image-area {
    width: 50%
  }
  .horizontal-card .nsw-card__image-area {
    max-width: min(50%, 375px);
  }
  .horizontal-card .nsw-card__image-area img {
    position: relative;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .horizontal-card .nsw-card__title, .horizontal-card .nsw-card__copy, .horizontal-card .nsw-card__icon {
    padding: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/uilib/horizontal-card/horizontal-card.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;IACf,mBAAmB;EACrB;EACA;IACE;EACF;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,kBAAkB;IAClB,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;EAClC;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".horizontal-card {\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.horizontal-card .nsw-card__content {\r\n  border-top: none;\r\n}\r\n\r\n@media (min-width:576px) {\r\n  .horizontal-card.nsw-card--media {\r\n    max-width: 100%;\r\n    flex-direction: row;\r\n  }\r\n  .horizontal-card .nsw-card__content, .horizontal-card .nsw-card__image-area {\r\n    width: 50%\r\n  }\r\n  .horizontal-card .nsw-card__image-area {\r\n    max-width: min(50%, 375px);\r\n  }\r\n  .horizontal-card .nsw-card__image-area img {\r\n    position: relative;\r\n    height: 100%;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n  }\r\n  .horizontal-card .nsw-card__title, .horizontal-card .nsw-card__copy, .horizontal-card .nsw-card__icon {\r\n    padding: 0;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
