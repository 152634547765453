import {Inject, OnChanges, SimpleChanges} from '@angular/core';
import { HostListener } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Util } from 'src/app/shared/util';

@Component({
  selector: 'app-alpha-selector',
  templateUrl: './alpha-selector.component.html',
  styleUrls: ['./alpha-selector.component.css']
})
export class AlphaSelectorComponent implements OnInit, OnChanges {
  @Input() dataList: string[] = [];

  alphabet: string[];
  firstLettersArray: string[];
  windowScrolled: boolean;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      }
     else if (this.windowScrolled && window.scrollY || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.windowScrolled = false;
      }
  }

  scrollToTop() {
    (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
    })();
}

  ngOnInit() {
    this.alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataList) {
      this.firstLettersArray = this.getActiveLetters(this.dataList);
    }
  }

  getActiveLetters(data: string[]): string[] {
    let activeLetters: string[] = data.map(string => string[0].toUpperCase());
    return Util.deduplicate(activeLetters);
  }

  checkIfPresented(letter: string): boolean {
    if (this.firstLettersArray) {
      if (this.firstLettersArray.indexOf(letter) != -1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
