export enum PageType {
  Topic,
  Indicator,
  Location,
  Method,
  Glossary,
  WebPage
}

export class ResultItem {
  public name: string;
  public title: string;
  public subTitle: string;
  public text: string;
  public link: string;
  public type: PageType;
  public locationType: string;
  public index: number;
}

export class SearchResult {
  public items: ResultItem[];
  totalRecords: number;
}

export class LocationTopicCardResponse {
  public title: string;
  public textPreview: string;
  public link: string;
}
