import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import ResizeObserver from "resize-observer-polyfill";
import { Util } from "src/app/shared/util";
import { IndicatorColourBank } from "../../colour-bank";
import { FacetsLib } from "../../facets-lib";
import { IndicatorState } from "../../indicator.state";
import { LegendComponent } from "../../legend/legend.component";
import { Chart } from "../chartData";
import { VisualisationDownloadFormat, Locations } from "src/app/indicator/constraints";
import { PlotlyServiceExt } from "../../../services/plotly-service-extension";
import { PlotlyGraphView } from "../plotly-graph-view";
import { DocumentExportService } from "../../../services/documentexport.service";

const enum ChartDrawingStageEnum {
  Created,
  Initialised,
  StateUpdated,
  DrawLegend,
  DrawingFirstSetOfChartsStarted,
  DrawingFirstSetOfChartsFinished,
  DrawingSecondSetOfChartsStarted,
  DrawingSecondSetOfChartsFinished,
  CanvasSizeChanged,
}

const YAXIS_HEADROOM = 1.05; // factor
const Y_AXIS_OFFSET = 64; // px
const CHARTVIEW_DISPLAY_PADDING = 16; // px
const MIN_HEIGHT = 200; // Minimum height of chart
const HEIGHT_GROUP_FACTOR = 0.2; // Additional factor to add to min height when data contains more than one trace

@Component({
  selector: "app-snapshot-view",
  templateUrl: "./snapshot-view.component.html",
  styleUrls: ["./snapshot-view.component.css"],
})
export class SnapshotViewComponent
  extends PlotlyGraphView
  implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild("canvas", {static: true}) canvas: ElementRef;
  @ViewChild(LegendComponent, {static: true}) legend: LegendComponent;

  state: IndicatorState;
  facetedPlotData: any;
  allCharts: Chart[];
  firstChartsSet: Chart[];
  secondChartsSet: Chart[];
  config: any;
  elementRef: ElementRef;
  elementSize: DOMRect;
  facetLayoutStyle: string;
  canvasWidth: number;
  canvasHeight: number;
  observer: ResizeObserver;
  chartDrawingStage: ChartDrawingStageEnum = ChartDrawingStageEnum.Created;
  y_axis_margin: number = 0;
  facet_count: number;
  numberOfColumns: number;
  chartHeight: number;
  showSecondDataSet: boolean = false;
  showLegend: boolean = true;

  constructor(element: ElementRef, private cdRef: ChangeDetectorRef, documentExportService: DocumentExportService, plotlyServiceExt: PlotlyServiceExt) {
    super(documentExportService, plotlyServiceExt);
    this.elementRef = element;
    //debugger
  }

  public exportAs(visualisationDownloadFormat: VisualisationDownloadFormat, source: string, fileName: string): any {
    if (visualisationDownloadFormat == VisualisationDownloadFormat.PNG) {
      super.exportAsPNG(fileName)
    }
    else {
      this.allCharts.forEach(chart => {
        const title = super.CreateChartTitle(this.state.Meta.title, this.state.ChartTitle, chart);
        super.exportAsSVG(chart.Id, title, !!this.facetedPlotData[0] ? this.facetedPlotData[0].traces : null, source, `${fileName}-${chart.Id}`);
      });
    }
  }

  /**
   * this will bind resize observer to the target element
   * https://github.com/que-etc/resize-observer-polyfill
   */
  elementObserver() {
    var ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.memoriseCanvasSize(
          this.canvas.nativeElement.clientWidth,
          this.canvas.nativeElement.clientHeight
        );
      }
    });
    ro.observe(this.canvas.nativeElement);
  }

  ngOnInit() {
    //debugger
    this.memoriseCanvasSize(
      this.canvas.nativeElement.clientWidth,
      this.canvas.nativeElement.clientHeight
    );

    this.facetLayoutStyle = "100%";
    this.chartDrawingStage = ChartDrawingStageEnum.Initialised;
    this.elementObserver();
  }

  onPlotted(e: any) {
    this.memorise_y_axis_margin();
    this.drawSecondSetOfCharts();
  }

  ngAfterViewChecked() {
    this.memorise_y_axis_margin();
  }

  memoriseCanvasSize(width: number, height: number) {
    let canvasSizeChanged = false;
    if (width !== undefined) {
      width = Math.floor(width);
      if (width !== 0 && width !== this.canvasWidth) {
        this.canvasWidth = width;
        canvasSizeChanged = true;
      }
    }
    if (height !== undefined) {
      height = Math.floor(height);
      if (height != 0 && height !== this.canvasHeight) {
        this.canvasHeight = height;
        canvasSizeChanged = true;
      }
    }

    if (canvasSizeChanged) {
      this.onCanvasSizeChange();
    }
  }

  onCanvasSizeChange() {
    if (this.chartDrawingStage !== ChartDrawingStageEnum.Created) {
      this.chartDrawingStage = ChartDrawingStageEnum.CanvasSizeChanged;
      this.drawSecondSetOfCharts();
    }
  }

  memorise_y_axis_margin() {
    let labelWidth = Math.ceil(this.findYLabelsWidth());
    if (labelWidth !== 0) {
      if (labelWidth !== this.y_axis_margin) {
        this.y_axis_margin = labelWidth;
        this.on_y_axis_margin_change();
      }
    }
  }

  on_y_axis_margin_change() {}

  ngOnDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.canvas.nativeElement);
    }
  }

  findYLabelsWidth(): number {
    let elements = this.elementRef.nativeElement.querySelectorAll(
      "g.yaxislayer-above"
    );
    let first = elements[0];
    let rectWidth = 0;
    if (first) {
      rectWidth = first.getBoundingClientRect().width;
    }
    return rectWidth;
  }

  get_grid_width(): number {
    return this.canvasWidth - CHARTVIEW_DISPLAY_PADDING * 2;
  }

  get_grid_heght(): number {
    return this.canvasHeight - CHARTVIEW_DISPLAY_PADDING * 2;
  }

  onStateUpdated(newState: IndicatorState) {
    this.showSecondDataSet = false;
    this.chartDrawingStage = ChartDrawingStageEnum.StateUpdated;

    this.state = newState;
    this.drawLegend();
    this.drawFirstSetOfCharts();
  }

  drawLegend() {
    this.buildFacetedPlotData();

    if (this.facetedPlotData !== undefined) {
      this.showLegend =
        Array.isArray(this.facetedPlotData) &&
        this.facetedPlotData.length > 0 &&
        !!this.facetedPlotData[0] &&
        !!this.facetedPlotData[0].traces &&
        this.facetedPlotData[0].traces.length > 1;

      // update legend
      if (this.legend && !!this.facetedPlotData[0]) {
        this.legend.onStateUpdated(this.facetedPlotData[0].traces);
      }

      this.chartDrawingStage = ChartDrawingStageEnum.DrawLegend;
    }
  }

  // Pre Plot : The first facet is rendered in order to determine the width of the y-axis labels,
  // this value can then be used to resize the columns appropriately.
  drawFirstSetOfCharts() {
    //this.buildFacetedPlotData();
    if (this.facetedPlotData !== undefined) {
      this.chartDrawingStage =
        ChartDrawingStageEnum.DrawingFirstSetOfChartsStarted;
      // all below will not change
      this.allCharts = [];
      this.facet_count = this.facetedPlotData.length;
      this.config = this.get_config();

      // Get the num ber of columns in the grid
      this.numberOfColumns = this.get_columns(this.facet_count);

      // The number of groups (bars) in the plot, represents the number of labels on the y-axis
      const group_count = !!this.facetedPlotData[0] &&
      !!this.facetedPlotData[0].traces &&
      !!this.facetedPlotData[0].traces[0] &&
      !! this.facetedPlotData[0].traces[0].y ?
      this.facetedPlotData[0].traces[0].y.length : 0;

      // The number of traces in the plot, represents the number of 'grouped' (in the charting sense) bars per label on the y-axis
      const trace_count = !!this.facetedPlotData[0] &&
      !!this.facetedPlotData[0].traces ?
      this.facetedPlotData[0].traces.length : 0;

      // Number of labels on the y-axis increased by the grouping factor
      const factored_count =
        group_count * ((trace_count - 1) * HEIGHT_GROUP_FACTOR + 1);

      // Min Height for a facet chart, is the base min height with the addition
      // of a component for the count of y-axis label greater than 7
      const minHeight = MIN_HEIGHT + Math.max(0, factored_count - 7) * 15;

      const rows = Math.ceil(this.facet_count / this.numberOfColumns);
      const total_inter_row_gap = (rows - 1) * 2 * this.FACET_GAP;

      // The greater of the min height, or the canvas height (less margins)
      // divided by the number of rows.
      this.chartHeight = Math.max(
        minHeight,
        (this.get_grid_heght() - total_inter_row_gap) / rows
      );

      // Get the layouts, primary layout is used for plots at the start of each row, secondary layout is for subsequent plots
      // this call depends on y_axis_margin
      let [layout_primary, layout_secondary] = this.get_plot_layouts();
      const layout = Util.deepCopy(layout_primary);
      layout.margin.l = 50;
      // this.set_grid_sizes(facet_count, calculate_column_widths);
      this.facetLayoutStyle = this.set_grid_sizes(this.facet_count);

      const chart = new Chart(
        layout,
        !!this.facetedPlotData[0] ? this.facetedPlotData[0].traces : null,
        this.chartHeight
      );
      chart.Id = `tred-view-chart-first`;
      this.allCharts.push(chart);

      // draw
      const tmpChart: Chart[] = [];
      this.firstChartsSet = [];
      for (let data of this.allCharts) {
        tmpChart.push(data);
      }
      this.firstChartsSet = tmpChart;
      this.chartDrawingStage =
        ChartDrawingStageEnum.DrawingFirstSetOfChartsFinished;
    }
  }

  drawSecondSetOfCharts() {
    this.showSecondDataSet = true;
    //this.buildFacetedPlotData();
    if (this.facetedPlotData !== undefined) {
      this.chartDrawingStage =
        ChartDrawingStageEnum.DrawingSecondSetOfChartsStarted;

      this.allCharts = [];
      this.facet_count = this.facetedPlotData.length;
      this.config = this.get_config();

      // Get the num ber of columns in the grid
      this.numberOfColumns = this.get_columns(this.facet_count);

      // The number of groups (bars) in the plot, represents the number of labels on the y-axis
      const group_count = !!this.facetedPlotData[0] &&
      !!this.facetedPlotData[0].traces &&
      !!this.facetedPlotData[0].traces[0] &&
      !!this.facetedPlotData[0].traces[0].y ?
      this.facetedPlotData[0].traces[0].y.length : 0;

      // The number of traces in the plot, represents the number of 'grouped' (in the charting sense) bars per label on the y-axis
      const trace_count = !!this.facetedPlotData[0] &&
      !!this.facetedPlotData[0].traces
      ? this.facetedPlotData[0].traces.length : 0;

      // Number of labels on the y-axis increased by the grouping factor
      const factored_count =
        group_count * ((trace_count - 1) * HEIGHT_GROUP_FACTOR + 1);

      // Min Height for a facet chart, is the base min height with the addition
      // of a component for the count of y-axis label greater than 7
      const minHeight = MIN_HEIGHT + Math.max(0, factored_count - 7) * 15;

      const rows = Math.ceil(this.facet_count / this.numberOfColumns);
      const total_inter_row_gap = (rows - 1) * 2 * this.FACET_GAP;

      // The greater of the min height, or the canvas height (less margins)
      // divided by the number of rows.
      this.chartHeight = Math.max(
        minHeight,
        (this.get_grid_heght() - total_inter_row_gap) / rows
      );

      // Get the layouts, primary layout is used for plots at the start of each row, secondary layout is for subsequent plots
      // this call depends on y_axis_margin
      const [layout_primary, layout_secondary] = this.get_plot_layouts();
      this.facetLayoutStyle = this.set_grid_sizes(this.facet_count);
      this.allCharts = [];

      this.facetedPlotData.forEach((current, index) => {
        const layout =
          index % this.numberOfColumns === 0
            ? Util.deepCopy(layout_primary)
            : Util.deepCopy(layout_secondary);
        if (this.facetedPlotData.length > 1) {
          //layout.title = this.facetedPlotData[index].facet_title;
          layout.title = {
            // text: 'Paper-referenced <br> title',
            // xanchor: 'left', // or 'auto', which matches 'left' in this case
            // yanchor: 'bottom',
            // x: 0,
            // y: 1,
            // xref: 'paper',
            // yref: 'paper'
            //xref: 'container',
            //yref: 'container'
            //text: 'Paper-referenced <br>title',
            text: !!this.facetedPlotData[index] ? this.facetedPlotData[index].facet_title : '',
            //text: this.makeTwoLines(this.facetedPlotData[index].facet_title),
            //text: 'This is ksjfljslfjj lkj ljl<br>asdfasdfasdf',
            xanchor: "auto", // or 'auto', which matches 'left' in this case
            yanchor: "bottom",
            x: 0,
            // y: 0.98,
            //xref: 'container',
            //yref: 'container'
            xref: "paper",
            yref: "paper",
            font: {
              size: 15,
              //color: 'red'
            },
          };
        }
        const chart = new Chart(
          layout,
          !!this.facetedPlotData[index] ? this.facetedPlotData[index].traces : null,
          this.chartHeight
        );
        chart.Id = `snapshot-view-chart-${index + 1}`;
        this.allCharts.push(chart);
      });

      // draw
      const tmpChart: Chart[] = [];
      for (let data of this.allCharts) {
        tmpChart.push(data);
      }

      this.secondChartsSet = tmpChart;

      this.cdRef.detectChanges();
      this.chartDrawingStage =
        ChartDrawingStageEnum.DrawingSecondSetOfChartsFinished;
    }
  }

  calculate_column_widths(columns) {
    // let avaliable_space = this.get_avaliable_width(columns);
    // return [
    //     Math.round(avaliable_space / columns + Y_AXIS_OFFSET - Y_AXIS_OFFSET / columns),
    //     Math.round(avaliable_space / columns - Y_AXIS_OFFSET / columns)
    // ];

    let canvasWidthMinusGaps = this.get_avaliable_width(columns);
    let minus1chartLabels = canvasWidthMinusGaps - this.y_axis_margin; // ? and Y_AXIS_OFFSET??
    return [
      Math.round(minus1chartLabels / columns + this.y_axis_margin),
      Math.round(minus1chartLabels / columns),
    ];
  }

  get_config() {
    return {
      responsive: true,
      modeBarButtonsToRemove: [
        "toImage",
        "pan2d",
        "select2d",
        "lasso2d",
        "zoomIn2d",
        "zoomOut2d",
        "autoScale2d",
        "hoverClosestCartesian",
        "hoverCompareCartesian",
      ],
      displaylogo: false,
    };
  }

  get_plot_layouts() {
    const state = this.state;
    const max = this.max_xaxis_value(this.facetedPlotData) * YAXIS_HEADROOM;

    const layout1 = {
      margin: { l: 4, r: 8, t: 48, b: 48, pad: 4 },
      xaxis: {
        fixedrange: true,
        autorange: false,
        range: [0, max],
        title: {},
      },
      yaxis: {
        type: "category",
        fixedrange: true,
        automargin: false,
      },
      title: {},
      barmode: "group",
      autoscale: true,
      hovermode: "closest",
    };

    if (state.Meta !== undefined && state.Meta.measures !== undefined) {
      var filteredMeasures = this.get_filtered_measures();
      layout1.xaxis.title = {
        text: filteredMeasures.find(
          (element) => element.name === this.state.Measure[0]
        ).description,
      };
    }

    let layout_primary = JSON.parse(JSON.stringify(layout1));

    const layout2 = {
      margin: { l: 4, r: 8, t: 48, b: 48, pad: 4 },
      xaxis: {
        autorange: false,
        fixedrange: true,
        range: [0, max],
        title: {},
      },
      yaxis: {
        type: "category",
        automargin: false,
        fixedrange: true,
      },
      title: {},
      barmode: "group",
      autoscale: true,
      hovermode: "closest",
    };

    if (state.Meta !== undefined && state.Meta.measures !== undefined) {
      var filteredMeasures = this.get_filtered_measures();
      layout2.xaxis.title = {
        text: filteredMeasures.find(
          (element) => element.name === this.state.Measure[0]
        ).description,
      };
    }
    let layout_secondary = JSON.parse(JSON.stringify(layout2));
    layout_secondary.yaxis.showticklabels = false;
    layout_primary.margin.l = layout_primary.margin.l + this.y_axis_margin;
    return [layout_primary, layout_secondary];
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  get_filtered_measures() {
    var measures = Util.deepCopy(this.state.Meta.measures);
    measures.forEach(m => {
      if (!!m.groups && m.groups.indexOf("Period") === -1) {
        m.groups = m.groups + ",Period";
      }
      else if (!m.groups) {
        m.groups = "Period";
      }
    });

    var groups = Util.deepCopy(this.state.Groups);
    if (!!this.state.Location && !!this.state.Location.length && this.state.Location !== "NSW" && !!groups && groups.indexOf(this.state.Location) === -1) {
      groups.push(this.state.Location);
    }

    var sortedGroupsAppliedOnTheUI = this.sort_groups_alphabetically(groups);
    var groupMeasures = measures.filter(m => !!groups && this.sort_groups_alphabetically(this.split_measure_groups_safely(m.groups)) === sortedGroupsAppliedOnTheUI);
    var filteredMeasures = !!groupMeasures && !!groupMeasures.length ? groupMeasures : measures;
    return filteredMeasures;
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  sort_groups_alphabetically(groups) {
    if (!!groups) {
      return groups.sort((a, b) => a.localeCompare(b)).join();
    }
    return "";
  }

  //TODO: Move to a shared service and reduce similar duplicate code in five different places
  split_measure_groups_safely(measureGroups) {
    if (!!measureGroups) {
      if (measureGroups.indexOf(",") !== -1) {
        return measureGroups.split(",");
      }
      else {
        return [measureGroups];
      }
    }
    return [];
  }

  max_xaxis_value(faceted_plot_data) {
    let max = 0;
    const confidenceInterval = this.state.ConfidenceIntervals[
      this.state.Measure[0]
    ];
    if (this.state.Confidence && !!confidenceInterval) {
      faceted_plot_data.forEach((facet) => {
        facet.traces.forEach((trace) => {
          for (let i = 0; i < trace.x.length; i++) {
            let err = 0;
            if (trace.error_x.array[i]) {
              err = trace.error_x.array[i];
            }
            if (trace.x[i] + err > max) {
              max = trace.x[i] + err;
            }
          }
        });
      });

      return max;
    } else {
      return faceted_plot_data.reduce((accumulator, facet) => {
        return Math.max(
          accumulator,
          facet.traces.reduce((accumulator, trace) => {
            return Math.max(accumulator, ...trace.x);
          }, 0)
        );
      }, 0);
    }
  }

  buildFacetedPlotData() {
    let plot_data = [];
    let faceted_plot_data = [];
    let categories = [];

    // let facet_groups = FacetsLib.get_facet_groups(this.state);
    // let facet_ids = FacetsLib.get_facet_ids(this.state);
    let colour_bank = new IndicatorColourBank();

    // Init variable used to track which colours are assigned to which traces
    let trace_colours = {};
    // For each row of data
    this.state.FilteredData.forEach((record) => {
      let category =
        this.state.Compare[0] === Locations.NSW &&
        this.state.Compare.length === 1
          ? "NSW"
          : this.state.Compare[0] === Locations.NSW &&
            this.state.Compare.length > 1
          ? record[this.state.Compare[1]]
          : // record[state.compare[0]] :
            record[this.state.Compare[0]];

      if (category === undefined) {
        category = "NSW";
      }

      // If a new primary category add to the category list (category axis)
      if (!categories.includes(category)) {
        categories.push(category);
      }

      let facet_id = FacetsLib.get_facet_id(record, this.state);
      let facet_title = FacetsLib.get_facet_title(record, this.state);

      //let facet_index = facet_ids.indexOf(facet_id) + 1;

      let trace_id = this.get_trace_id(record);

      // @todo move this function to the right spot
      function build_name(record, group_list) {
        const initial = facet_id === "" ? [] : [Util.deslugify(facet_id)];
        return group_list
          .reduce((result, group) => {
            if (record[group] !== undefined) {
              result.push(record[group]);
            }
            return result;
          }, initial)
          .join(" - ");
      }
      let trace_name = "NSW"; // When is 'NSW' and <= 2 compare (ie 0 | 1 group)

      // If 'NSW' and there > 2 compare (ie 2+ groups)
      if (
        this.state.Compare[0] === Locations.NSW &&
        this.state.Compare.length > 2
      ) {
        trace_name = build_name(record, [...this.state.Compare].slice(2));
      }
      // Else If not 'NSW'
      else if (this.state.Compare[0] !== Locations.NSW) {
        trace_name = build_name(record, [...this.state.Compare].slice(1));
      }

      // Facet Handling

      let facet = faceted_plot_data.find(
        (element) => element.facet_id === facet_id
      );

      if (facet === undefined) {
        facet = {
          facet_id,
          facet_title,
          traces: [],
        };
        faceted_plot_data.push(facet);
      }

      // If a trace has not already been started with this name
      if (!(plot_data.filter((t) => t.id === trace_id).length > 0)) {
        var filteredMeasures = this.get_filtered_measures();
        let measure = filteredMeasures.find(
          (x) => x.name == this.state.Measure[0]
        );
        let xValue = record[this.state.Measure[0]];
        let decimalPoints =  Util.checkIfInteger(xValue) === true ? '0': '1';

        let hoverTemplate =
          "<b>%{text[0]}</b><br>" + measure.description + ": %{text[3]:." + decimalPoints + "f}<br>";

        const confidenceInterval = this.state.ConfidenceIntervals[measure.name];
        let lowerCiValue, upperCiValue;
        if (this.state.Confidence && !!confidenceInterval) {
          lowerCiValue = record[confidenceInterval.lower.name];
          upperCiValue = record[confidenceInterval.upper.name];

          const lowerCiDecimalPoints =  Util.checkIfInteger(lowerCiValue) === true ? '0': '1';
          const upperCiDecimalPoints =  Util.checkIfInteger(upperCiValue) === true ? '0': '1';

          hoverTemplate += `${confidenceInterval.lower.description}: %{text[1]:.` + lowerCiDecimalPoints + `f}`;
          hoverTemplate += `<br>`;
          hoverTemplate += `${confidenceInterval.upper.description}: %{text[2]:.` + upperCiDecimalPoints + `f}`;
        }

        let trc;
        if (this.state.Confidence && !!confidenceInterval) {
          trc = {
            y: categories,
            x: [],
            hovertemplate: hoverTemplate,
            //hoverinfo: 'x',
            text: [],
            // xaxis: `x${facet_index}`,
            // yaxis: `y${facet_index}`,
            error_x: {
              type: "data",
              symmetric: false,
              array: [],
              arrayminus: [],
            },
            type: "bar",
            orientation: "h",
            id: trace_id,
            showlegend: false,
            facet_id: facet_id,
          };
        } else {
          trc = {
            y: categories,
            x: [],
            hovertemplate: hoverTemplate,
            //hoverinfo: 'x',
            text: [],
            type: "bar",
            orientation: "h",
            id: trace_id,
            showlegend: false,
            facet_id: facet_id,
          };
        }
        plot_data.push(trc);
        facet.traces.push(trc);
      }

      // Get the trace that this should be added to
      const trace = plot_data.filter((t) => t.id === trace_id)[0];

      // Add the data variable to the trace (measured variable axis)
      let xValue = record[this.state.Measure[0]];
      trace.x.push(xValue);

      // add confidence interval if required
      const confidenceInterval = this.state.ConfidenceIntervals[
        this.state.Measure[0]
      ];
      let lowerCiValue, upperCiValue;
      if (this.state.Confidence && !!confidenceInterval) {
        lowerCiValue = record[confidenceInterval.lower.name];
        upperCiValue = record[confidenceInterval.upper.name];

        if (record[confidenceInterval.upper.name]) {
          trace.error_x.array.push(upperCiValue - xValue);
        }
        if (record[confidenceInterval.lower.name]) {
          trace.error_x.arrayminus.push(xValue - lowerCiValue);
        }
      }

      let legend_group = this.get_legend_group(record);
      let legend_name = this.get_legend_name(record);
      let legend_id = [legend_group, legend_name].join("--");

      let colour = trace_colours[legend_id];

      if (colour === undefined) {
        colour = colour_bank.take_colour(legend_group);
        trace_colours[legend_id] = colour;
      }
      trace.name = "";
      //trace.name = legend_name;
      trace.legendgroup = legend_group;
      trace.marker = { color: colour };
      trace.text.push([legend_name, lowerCiValue, upperCiValue, xValue]);
    });

    this.facetedPlotData = faceted_plot_data;
  }

  get_trace_id(record) {
    const state = this.state;
    const compare = this.state.Compare;

    let primary_compare =
      this.state.Compare.length >= 1 ? this.state.Compare[0] : undefined;

    let groups =
      this.state.Location !== Locations.NSW ? [this.state.Location] : [];

    groups = groups.concat(this.state.Groups);

    let trace_id = groups
      .reduce((result, group) => {
        if (group !== primary_compare) {
          result.push(record[group]);
        }
        return result;
      }, [])
      .join("--");

    trace_id = Util.slugify(trace_id);

    return trace_id;
  }

  get_legend_name(record) {
    const compare = this.state.Compare;

    const exclude = [this.state.Compare[0]];

    if (this.state.Compare.length > 2) {
      exclude.push(this.state.Compare[1]);
    }

    let legend_name = compare
      .reduce((accumulator, current) => {
        if (!exclude.includes(current)) {
          accumulator.push(record[current]);
        }
        return accumulator;
      }, [])
      .join(" - ");

    if (legend_name === "") {
      legend_name = "NSW";
    }

    return legend_name;
  }

  get_legend_group(record) {
    const compare = this.state.Compare;

    // let primary_compare = compare.slice(0).shift();

    let legend_group = compare.length > 2 ? record[compare[1]] : "-";
    return legend_group;
  }
}
