// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search--results {
  min-height: 500px;
}
.result-item {
  position: relative;
}

.search--results .result-item {
  margin-bottom: 24px;
}

.search--results h2,
.search--results p {
  margin: 0;
  padding: 0;
}

.search--results h2 {
  margin-bottom: 4px;
}

.search--results .nsw-card__tag {
  margin-bottom: 10px;
  font-size: 16px;
}

.search--results .nsw-card__copy {
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/app/uilib/search-result-page/searchresultpage.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".search--results {\r\n  min-height: 500px;\r\n}\r\n.result-item {\r\n  position: relative;\r\n}\r\n\r\n.search--results .result-item {\r\n  margin-bottom: 24px;\r\n}\r\n\r\n.search--results h2,\r\n.search--results p {\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.search--results h2 {\r\n  margin-bottom: 4px;\r\n}\r\n\r\n.search--results .nsw-card__tag {\r\n  margin-bottom: 10px;\r\n  font-size: 16px;\r\n}\r\n\r\n.search--results .nsw-card__copy {\r\n  font-size: 14px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
