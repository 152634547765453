// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-main > section.home-page {
  padding: 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

}

.card-container {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: calc(2 * var(--xs-gutter));
  margin-bottom: 2rem;
}

.card__content {
  background-color: #f4f4f7;
}

@media (min-width: 576px) {
  .card-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: calc(2 * var(--sm-gutter));
  } 
}

@media (min-width: 768px) {
  .card-container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: calc(2 * var(--md-gutter));
  }
}

@media (min-width: 992px) {
  .card-container {
    gap: calc(2 * var(--lg-gutter));
  }
}

@media (min-width: 1200px) {
  .card-container {
    gap: calc(2 * var(--xl-gutter));
  }
}

.nsw-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.carousel-container {
  min-height: 450px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/home/home.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,gDAAgD;;AAElD;;AAEA;EACE,aAAa;EACb,gDAAgD;EAChD,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,gDAAgD;IAChD,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,gDAAgD;IAChD,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["app-main > section.home-page {\r\n  padding: 0;\r\n}\r\n\r\n.grid-container {\r\n  display: grid;\r\n  grid-template-columns: repeat(3, minmax(0, 1fr));\r\n\r\n}\r\n\r\n.card-container {\r\n  display: grid;\r\n  grid-template-columns: repeat(1, minmax(0, 1fr));\r\n  gap: calc(2 * var(--xs-gutter));\r\n  margin-bottom: 2rem;\r\n}\r\n\r\n.card__content {\r\n  background-color: #f4f4f7;\r\n}\r\n\r\n@media (min-width: 576px) {\r\n  .card-container {\r\n    grid-template-columns: repeat(2, minmax(0, 1fr));\r\n    gap: calc(2 * var(--sm-gutter));\r\n  } \r\n}\r\n\r\n@media (min-width: 768px) {\r\n  .card-container {\r\n    grid-template-columns: repeat(3, minmax(0, 1fr));\r\n    gap: calc(2 * var(--md-gutter));\r\n  }\r\n}\r\n\r\n@media (min-width: 992px) {\r\n  .card-container {\r\n    gap: calc(2 * var(--lg-gutter));\r\n  }\r\n}\r\n\r\n@media (min-width: 1200px) {\r\n  .card-container {\r\n    gap: calc(2 * var(--xl-gutter));\r\n  }\r\n}\r\n\r\n.nsw-container {\r\n  padding-left: 0 !important;\r\n  padding-right: 0 !important;\r\n}\r\n\r\n.carousel-container {\r\n  min-height: 450px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
