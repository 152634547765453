import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button-menu',
  templateUrl: './radio-button-menu.component.html',
  styleUrls: ['./radio-button-menu.component.css'],
})
export class RadioButtonMenuComponent implements OnInit {
  @Input() titleLabel: string;

  get Items(): string[] {
    return this.items;
  }
  @Input() set Items(value: string[]) {
    this.items = value;
    this.setTitle();
  }

  get SelectedItemId(): string {
    return this.selectedItemId;
  }
  @Input() set SelectedItemId(value: string) {
    this.selectedItemId = value;
    this.setTitle();
  }

  @Output() itemSelected: EventEmitter<any> = new EventEmitter();
  public items: string[];
  public title: string;
  public selectedItemId: string;
  menuIsVisible: boolean;

  constructor() {
    this.menuIsVisible = false;
  }

  ngOnInit() {
  }

  onToggleButtonClick(event: any) {
    this.menuIsVisible = !this.menuIsVisible;
  }

  onItemClicked(e: Event, id: any) {
    e.preventDefault();
    this.selectedItemId = id;
    this.setTitle();
    this.itemSelected.emit(id);
    this.menuIsVisible = false;
  }

  setTitle() {
    if (this.selectedItemId && this.items) {
      const selectedItem = this.items.find((x) => x[0] === this.selectedItemId);
      if (selectedItem) {
        this.title = selectedItem[1];
      }
    }
  }
}
