import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { IndicatorState } from "src/app/indicator/indicator.state";
import { Util } from "src/app/shared/util";
import { GroupContainerComponent } from "./group-container/group-container.component";
import { LocationContainerComponent } from "./location-container/location-container.component";
import { PeriodContainerComponent } from "./period-container/period-container.component";

@Component({
  selector: "app-chart-viewer-filter",
  templateUrl: "./chart-viewer-filter.component.html",
  styleUrls: ["./chart-viewer-filter.component.css"],
})
export class ChartViewerFilterComponent implements OnInit {
  @ViewChild("viewContainerRef", { read: ViewContainerRef, static: true })
  VCR: ViewContainerRef;

  @ViewChild(LocationContainerComponent, { static: true })
  private locationComponent: LocationContainerComponent;

  @ViewChild(PeriodContainerComponent, { static: true })
  private periodComponent: PeriodContainerComponent;

  child_unique_key = 0;
  componentsReferences = Array<ComponentRef<GroupContainerComponent>>();
  @Output() locationSubdivisionChanged: EventEmitter<string> =
    new EventEmitter();
  @Output() selectedLocationsChanged: EventEmitter<string[]> =
    new EventEmitter();

  @Output() groupSelected: EventEmitter<string> = new EventEmitter();
  @Output() groupRemoved: EventEmitter<string> = new EventEmitter();
  @Output() groupItemsChanged: EventEmitter<{
    groupId: string;
    groupItems: string[];
  }> = new EventEmitter();
  @Output() periodItemsChanged: EventEmitter<{ periodItems: string[] }> =
    new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onStateUpdated(state: IndicatorState) {
    this.locationComponent.onStateUpdated(state);
    this.periodComponent.onStateUpdated(state);

    // check if some group dropdowns must be removed
    this.componentsReferences.forEach((componentRef) => {
      if (!state.Groups.includes(componentRef.instance.groupId)) {
        const key = componentRef.instance.uniqueKey;
        for (let vcrIndex = 0; vcrIndex < this.VCR.length; vcrIndex++) {
          if (this.VCR.get(vcrIndex) == componentRef.hostView) {
            // removing component from container
            this.VCR.remove(vcrIndex);
          }
        }
        // removing component from the list
        this.componentsReferences = this.componentsReferences.filter(
          (x) => x.instance.uniqueKey !== key
        );
      }
    });

    // check if some expanded group dropdowns must be added
    if (state.Groups) {
      state.Groups.forEach((group) => {
        if (group !== "Period") {
          let groupComponent = this.componentsReferences.find(
            (g) => g.instance.groupId === group
          );
          if (!groupComponent) {
            groupComponent = this.addNewGroupComponent();
            groupComponent.instance.groupId = group;
            groupComponent.instance.groupName = Util.getGroupLabel(
              group,
              state.Meta.groups
            );
          }
        }
      });
    }

    // more groups can be added
    if (state.AllowedGroups.length !== 0) {
      this.addNewGroupComponent();
    }

    this.componentsReferences.forEach((component) => {
      component.instance.onStateUpdated(state);
    });
  }

  addNewGroupComponent(): ComponentRef<GroupContainerComponent> {
    const childComponentRef = this.VCR.createComponent(GroupContainerComponent);
    const childComponent = childComponentRef.instance;

    childComponent.uniqueKey = ++this.child_unique_key;
    childComponent.parentRef = this;

    // add reference for newly created component
    this.componentsReferences.push(childComponentRef);
    return childComponentRef;
  }

  onlocationSubdivisionChanged(locationSubdivision: string) {
    this.locationSubdivisionChanged.emit(locationSubdivision);
  }

  onSelectedLocationsChanged(locations: string[]) {
    this.selectedLocationsChanged.emit(locations);
  }

  onGroupSelected(groupId: string) {
    this.groupSelected.emit(groupId);
  }

  onGroupRemoved(groupId: string) {
    this.groupRemoved.emit(groupId);
  }

  onGroupItemsChanged(groupId: string, groupItems: string[]) {
    this.groupItemsChanged.emit({ groupId, groupItems });
  }

  onPeriodItemsChanged(periodItems) {
    this.periodItemsChanged.emit(periodItems);
  }
}
